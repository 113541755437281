import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { formatToStandardDate, Numbers } from '@buddy-technology/buddy_helpers';
import { AccordionSection, Accordion, Button } from '@/lib/ReactRainbow';

const pathPrefix = '/products/builder/';

function Summary({ productDetails, productID = '' }) {
	const {
		organizer,
		event,
		eventType,
		eventStartDate,
		eventEndDate,
		venue,
		country,
		city,
		state,
		attendance,
		registrationStartDate,
		registrationEndDate,
		avgTicketsPerBooking,
		maxTicketsPerBooking,
		ageDemographics,
		refundPolicy,
		ticketPricing,
		underwriting,
	} = productDetails;

	// This obj allows for easy grabbing of which sections have issues, and facilitate the render below.
	// Age, Pricing and refunds are excluded, since those are either optional or can't have issues here.
	const SECTION_FIELDS = {
		EVENT_DETAILS: {
			id: 'event-details',
			fields: [
				{ name: 'Name', value: event },
				{ name: 'Type', value: eventType },
				{
					name: 'Event Dates',
					value: `${formatToStandardDate(eventStartDate)}-${formatToStandardDate(eventEndDate)}`,
				},
				{ name: 'Venue', value: venue, optional: true },
				{ name: 'Country', value: country },
				{ name: 'City', value: city },
				{ name: 'State', value: state },
			],
		},
		ORGANIZER_DETAILS: {
			id: 'organizer-details',
			fields: [
				{ name: 'Organizer Details', value: organizer?.name },
				{ name: 'Organizer Organization', value: organizer?.organization, optional: true },
				{ name: 'Organizer Country', value: organizer?.country },
				{ name: 'Organizer City', value: organizer?.city },
				{ name: 'Organizer State', value: organizer?.state },
				{ name: 'Organizer Email', value: organizer?.email },
				{ name: 'Organizer Phone', value: organizer?.phone },
			],
		},
		REGISTRATION: {
			id: 'registration',
			fields: [
				{ name: 'Estimated Attendance', value: `${attendance[0]}-${attendance[1]}` },
				{ name: 'Avg. # of tickets per booking', value: avgTicketsPerBooking, optional: true },
				{ name: 'Max. # of tickets per booking', value: maxTicketsPerBooking, optional: true },
				{
					name: 'Registration Window',
					value: `${formatToStandardDate(registrationStartDate)}-${formatToStandardDate(registrationEndDate)}`,
				},
			],
		},
	};

	const getSectionsWithErrors = () => {
		const sections = Object.values(SECTION_FIELDS);
		const sectionsWithErrors = sections
			.filter((section) => section.fields.find((el) => !el.value && !el.optional))
			.map((section) => (section.id));
		return sectionsWithErrors;
	};

	const SectionLabel = ({ title, id }) => {
		const hasErrors = getSectionsWithErrors().includes(id);

		return (
			<div className="flex items-center">
				{hasErrors
					? <FontAwesomeIcon icon={faExclamationTriangle} className="color-primary mr-2" />
					: null}
				<h4
					className={`buddy-h4 ${hasErrors ? '' : 'color-black'}`}
					style={{ marginBottom: 0 }}
				>
					{title}
				</h4>
				<Button
					onClick={() => navigate(`${pathPrefix}${id}${productID}`)}
					variant="base"
				>
					<FontAwesomeIcon icon={faPen} className="color-blue" />
				</Button>
			</div>
		);
	};

	// most values are required, so better to use optional flag vs. required flag.
	const InfoItem = ({ keyName, value, optional }) => {
		const displayValue =
			(!value?.length && !optional && (
				<span className="color-primary font-weight-bold">
            Missing
					<FontAwesomeIcon icon={faExclamationCircle} className="ml-1" />
				</span>
			)) ||
			(!value?.length && 'N/A') ||
			value;
		return (
			// overriding react rainbow's font size.
			<p style={{ fontSize: 16 }}>
				<strong>
					{keyName}
      :
				</strong>
				{' '}
				{displayValue}
			</p>
		);
	};

	return (
		<Accordion
			className="rainbow-accordion-container"
			multiple
			activeSectionNames={getSectionsWithErrors()}
		>
			<AccordionSection
				name="event-details"
				label={<SectionLabel id="event-details" title="Event Details" />}
			>
				<div className="md:px-5 lg:px-8">
					{SECTION_FIELDS.EVENT_DETAILS.fields.map((el) => (
						<InfoItem keyName={el.name} value={el.value} optional={el.optional} />
					))}
				</div>
			</AccordionSection>
			<AccordionSection
				name="organizer-details"
				label={(
					<SectionLabel
						id="organizer-details"
						title="Organizer Details"
					/>
				)}
			>
				<div className="px-3 md:px-5 lg:px-8">
					{SECTION_FIELDS.ORGANIZER_DETAILS.fields.map((el) => (
						<InfoItem keyName={el.name} value={el.value} optional={el.optional} />
					))}
				</div>
			</AccordionSection>
			<AccordionSection
				name="registration"
				label={(
					<SectionLabel
						id="registration"
						title="Registration Information"
					/>
				)}
			>
				<div className="md:px-5 lg:px-8">
					{SECTION_FIELDS.ORGANIZER_DETAILS.fields.map((el) => (
						<InfoItem keyName={el.name} value={el.value} optional={el.optional} />
					))}
				</div>
			</AccordionSection>
			<AccordionSection
				name="age-data"
				label={<SectionLabel id="age-data" title="Age Demographics" />}
			>
				<div className="md:px-5 lg:px-8">
					{ageDemographics?.length
						? (
							<>
								{ageDemographics.map((el) => (
									<InfoItem
										keyName={`${el.ages[0]}-${el.ages[1]}`}
										value={`${el.percentage}%`}
										optional
									/>
								))}
							</>
						)
						: (<p>No age information provided.</p>)}
				</div>
			</AccordionSection>
			<AccordionSection
				name="refund-policy"
				label={<SectionLabel id="refund-policy" title="Refund Policy" />}
			>
				<div className="md:px-5 lg:px-8">
					<InfoItem
						keyName="Percentage refunded"
						value={refundPolicy?.percentage}
						optional
					/>
					<InfoItem
						keyName="Refund policy"
						value={refundPolicy?.text}
						optional
					/>
					<InfoItem
						keyName="Refund Policy Upload"
						value={refundPolicy?.file ? refundPolicy.file[0].name : ''}
						optional
					/>
				</div>
			</AccordionSection>
			<AccordionSection
				name="ticket-pricing"
				label={<SectionLabel id="ticket-pricing" title="Ticket Price" />}
			>
				<div className="md:px-5 lg:px-8">
					{ticketPricing?.length ? (
						<>
							{ticketPricing.map((el) => (
								<InfoItem
									keyName={`${Numbers.toUSD(el.priceRange[0])}-${Numbers.toUSD(
										el.priceRange[1],
									)}`}
									value={`${el.percentage}%`}
									optional
								/>
							))}
						</>
					) : (
						<p>No age information provided.</p>
					)}
				</div>
			</AccordionSection>
			<AccordionSection
				name="ticket-pricing"
				label={<SectionLabel id="underwriting" title="Underwriting Information" />}
			>
				<div className="md:px-5 lg:px-8">
					{underwriting ? (
						<>
							<InfoItem
								keyName="Loss ratio"
								value={`${underwriting?.lossRatio}%`}
								optional
							/>
							<InfoItem
								keyName="Per-Ticket cap"
								value={Numbers.toUSD(underwriting?.perTicketCap)}
								optional
							/>
							<InfoItem
								keyName="Max loss for event"
								value={underwriting?.maxLossForEvent ? Numbers.toUSD(underwriting?.maxLossForEvent) : '?'}
								optional
							/>
						</>
					) : (
						<p>Underwriting information missing.</p>
					)}
				</div>
			</AccordionSection>
		</Accordion>
	);
}

export default Summary;
