import { COUNTRIES_ARRAY } from '@/utils';
import { Select } from '@/lib/ReactRainbow';

function CountrySelect({
	id = null,
	label,
	labelAlignment,
	className,
	name,
	value,
	onChange,
	disabled = false,
	readOnly = false,
}) {
	const countriesArray = COUNTRIES_ARRAY.map((country) => ({
		label: country,
		value: country,
	}));
	return (
		<Select
			id={id}
			label={label || 'Country'}
			labelAlignment={labelAlignment || 'left'}
			className={`w-full md:w-1/2 lg:w-1/4 ${className}`}
			name={name || 'country'}
			options={countriesArray}
			value={value}
			onChange={onChange}
			disabled={disabled || readOnly}
		/>
	);
}

export default CountrySelect;
