const ACCIDENT = {
	incidentRateDenominator: 1000,
	rates: [
		{
			label: 'Individual',
			rates: {
				'Annual (365 days)': 1.1851784803252,
				'Monthly (31 days)': 0.107746490462946,
				'Weekly (7 days)': 0.0395141780359758,
				'Day (24 hours)': 0.0189589059131379,
			},
		},
		{
			label: 'Couple',
			rates: {
				'Annual (365 days)': 2.19260076033112,
				'Monthly (31 days)': 0.199331830225631,
				'Weekly (7 days)': 0.0730872405904909,
				'Day (24 hours)': 0.0350871418461893,
			},
		},
		{
			label: 'Family',
			rates: {
				'Annual (365 days)': 2.9629708868884,
				'Monthly (31 days)': 0.269357997465563,
				'Weekly (7 days)': 0.0987607590145319,
				'Day (24 hours)': 0.04741372216645,
			},
		},
	],
	calculate: [
		{
			$addFields: {
				rates: {
					$let: {
						vars: {
							sum: {
								$sum: {
									$map: {
										input: {
											$reduce: {
												input: {
													$objectToArray: '$underwriting.inputs.benefitGroups',
												},
												initialValue: [],
												in: {
													$concatArrays: [
														'$$value',
														'$$this.v.benefits',
													],
												},
											},
										},
										as: 'benefit',
										in: {
											$divide: [
												{
													$multiply: [
														{
															$divide: [
																{
																	$cond: [
																		{
																			$or: [
																				'$$benefit.isRequired',
																				'$$benefit.isEnabled',
																			],
																		},
																		'$$benefit.value',
																		0,
																	],
																},
																'$$ROOT.underwriting.incidentRateDenominator',
															],
														},
														'$$benefit.incidentRate',
													],
												},
												'$$ROOT.underwriting.inputs.lossRatio.value',
											],
										},
									},
								},
							},
						},
						in: {
							$map: {
								input: '$underwriting.rates',
								as: 'ratesElement',
								in: {
									label: '$$ratesElement.label',
									rates: {
										$arrayToObject: {
											$map: {
												input: {
													$objectToArray: '$$ratesElement.rates',
												},
												as: 'newRates',
												in: {
													k: '$$newRates.k',
													v: {
														$multiply: [
															'$$newRates.v',
															'$$sum',
														],
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	],
	inputs: {
		lossRatio: {
			label: 'Loss Ratio',
			value: 0.5,
			range: [
				0.1,
				0.95,
			],
			increment: 0.05,
			format: {
				type: 'percentage',
				decimalPlaces: 0,
			},
			isRequired: true,
		},
		policyMaxPayoutPerInsured: {
			label: 'Max Payout Per Insured',
			value: 50000,
			range: [
				50000,
				200000,
			],
			increment: 100,
			format: {
				type: 'usd',
				decimalPlaces: 0,
			},
			isRequired: true,
		},
		maxBenefitPayoutPerCoveredAccident: {
			label: 'Max Benefit Payout Per Covered Accident',
			value: 25000,
			range: [
				25000,
				100000,
			],
			increment: 100,
			format: {
				type: 'usd',
				decimalPlaces: 0,
			},
			isRequired: true,
		},
		benefitGroups: {
			'Loss of': {
				benefits: [
					{
						label: 'Life',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.37879,
						isRequired: true, // must be present no matter what
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Both Hands or Both Feet',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.00031,
						isRequired: false,
						isEnabled: true, // if it's not required, can be turned off completely
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},

					},
					{
						label: 'Sight of Both Eyes',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.00407,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Speech and Hearing in both Ears',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.00031,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'One Hand or One Foot',
						value: 25000,
						range: [
							12500,
							50000,
						],
						increment: 250,
						incidentRate: 0.00062,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Sight in One Eye',
						value: 25000,
						range: [
							12500,
							50000,
						],
						increment: 250,
						incidentRate: 0.00481,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Speech',
						value: 25000,
						range: [
							12500,
							50000,
						],
						increment: 250,
						incidentRate: 0.00211,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hearing in Both Ears',
						value: 25000,
						range: [
							12500,
							50000,
						],
						increment: 100,
						incidentRate: 0.00444,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hearing in One Ear',
						value: 12500,
						range: [
							6000,
							25000,
						],
						increment: 250,
						incidentRate: 0.01152,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Thumb and Index Finger of the Same Hand',
						value: 12500,
						range: [
							6000,
							25000,
						],
						increment: 250,
						incidentRate: 0.00062,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			Paralysis: {
				benefits: [
					{
						label: 'Quadriplegia',
						info: 'The complete and irreversible paralysis of both upper and both lower limbs.',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.19637923742751,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hemiplegia',
						info: 'The complete and irreversible paralysis of the upper and lower limbs of the same side of the body.',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.019637923742751,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Paraplegia',
						info: 'The complete and irreversible paralysis of both lower limbs.',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 250,
						incidentRate: 0.019637923742751,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Uniplegia',
						info: 'The complete and irreversible paralysis of one limb."Limb" means entire arm or entire leg.',
						value: 12500,
						range: [
							12500,
							50000,
						],
						increment: 250,
						incidentRate: 0.37879,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Brain Injury': {
				benefits: [
					{
						label: 'Severe Traumatic Brain Injury',
						value: 25000,
						range: [
							25000,
							100000,
						],
						increment: 1000,
						incidentRate: 1.17798251145142,
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Moderate Traumatic Brain Injury',
						value: 12500,
						range: [
							12500,
							50000,
						],
						increment: 500,
						incidentRate: 2.94495627862854,
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			Ambulance: {
				lossPeriod: {
					label: 'Loss Period',
					value: 24,
					options: [
						24,
						48,
						72,
					],
					suffix: ' hours',
					isRequired: true,
				},
				benefits: [
					{
						label: 'Air Ambulance',
						value: 5000,
						range: [
							2500,
							10000,
						],
						increment: 100,
						incidentRate: [
							0.60817,
							0.06817,
							0.00817,
						],
						incidentRateLookup: '[lossPeriod]',
						isRequired: true,
						// must be present no matter what
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Ground Ambulance',
						value: 250,
						range: [
							100,
							500,
						],
						increment: 25,
						incidentRate: [
							19.71325,
							9.71325,
							0.71325,
						],
						incidentRateLookup: '[lossPeriod]',
						isRequired: true,
						// must be present no matter what
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Emergency Care Treatment': {
				lossPeriod: {
					label: 'Loss Period',
					value: 48,
					options: [
						24,
						48,
					],
					suffix: ' hours',
					isRequired: true,
				},
				benefits: [
					{
						label: 'Hospital Emergency Room',
						value: 500,
						range: [
							500,
							2000,
						],
						increment: 25,
						incidentRate: [
							105.75924,
							116.33516,
						],
						incidentRateLookup: '[lossPeriod]',
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Urgent Care',
						value: 250,
						range: [
							100,
							1000,
						],
						increment: 10,
						incidentRate: [
							33.43810,
							41.79763,
						],
						incidentRateLookup: '[lossPeriod]',
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: "Dr's Office",
						value: 100,
						range: [
							50,
							200,
						],
						increment: 10,
						incidentRate: [
							69.59867,
							104.39800,
						],
						incidentRateLookup: '[lossPeriod]',
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Hospital Stay': {
				lossPeriod: {
					label: 'Loss Period',
					value: 30,
					options: [
						30,
						60,
						90,
					],
					suffix: ' days',
					isRequired: true,
				},
				maximumDaysOfConfinement: {
					label: 'Maximum Number of Days of Confinement per Covered Accident',
					value: 10,
					range: [
						5,
						20,
					],
					increment: 1,
					suffix: ' days',
					isRequired: true,
				},
				benefits: [
					{
						label: 'Hospital Stay Per Day',
						value: 500,
						range: [
							500,
							2000,
						],
						increment: 25,
						incidentRate: [
							[
								34.65965,
								33.65965,
								32.65965,
								31.65965,
								30.65965,
								29.65965,
								28.65965,
								21.65965,
								19.65965,
								17.65965,
								13.55965,
								10.25965,
								9.95965,
								6.25965,
								3.61965,
								0.97965,
							],
							[
								44.65965,
								43.65965,
								42.65965,
								41.65965,
								40.65965,
								39.65965,
								38.65965,
								34.65965,
								30.65965,
								26.65965,
								22.65965,
								18.65965,
								14.65965,
								10.65965,
								6.65965,
								2.65965,
							],
							[
								54.65965,
								53.65965,
								52.65965,
								51.65965,
								50.65965,
								49.65965,
								48.65965,
								47.65965,
								46.65965,
								45.65965,
								44.65965,
								43.65965,
								42.65965,
								41.65965,
								40.65965,
								39.65965,
							],
						],
						incidentRateLookup: '[lossPeriod][maximumDaysOfConfinement]',
						isRequired: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Fractures - Open Reduction': {
				info: 'Open Reduction means the realignment of a fracture or dislocation by surgical means.',
				benefits: [
					{
						label: 'Ankle',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.27375,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Foot (except toes)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.17028,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Forearm (radius and/or ulna)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.40730,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Wrist',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.02912,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hand (except fingers)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.17693,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Sternum',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00211,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Collarbone (clavicle)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.01889,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Shoulder Blade (scapula)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.02050,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Upper Arm between Elbow and Shoulder (humerus)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.14165,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Coccyx',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00096,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hip',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00287,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Leg',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.07776,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Pelvis',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00785,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Rib',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.02071,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Skull fracture – depressed (except bones of face and nose)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00281,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Vertebrae',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00647,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Face or Nose (except mandible or maxilla)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.26575,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Lower Jaw, Mandible (except alveolar process)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.17108,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Skull fracture – non-depressed (except bones of face and nose)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.12991,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Upper Jaw, Maxilla (except alveolar process)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.03931,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Dislocations - Open Reduction': {
				info: 'Open Reduction means the realignment of a fracture or dislocation by surgical means.',
				benefits: [
					{
						label: 'Ankle - Bone or Bones of the Foot (other than toes)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.01991,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Bone or Bones of the Hand (other than fingers)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00196,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Elbow',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00776,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Wrist',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00776,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Neck',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00196,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Collarbone',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00027,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hip',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00027,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Knee',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00050,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Shoulder (glenohumeral)',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00027,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Lower Jaw',
						value: 5000,
						range: [
							1000,
							10000,
						],
						increment: 250,
						incidentRate: 0.00268,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Fractures - Closed Reduction': {
				info: 'Closed Reduction means the realignment of a fracture or dislocation by other than surgical means.',
				benefits: [
					{
						label: 'Ankle',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 7.66067,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Foot (except toes)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 5.65154,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Forearm (radius and/or ulna)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 14.79443,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Wrist',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.99372,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hand (except fingers)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 5.52454,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Sternum',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.44639,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Collarbone (clavicle)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 3.70804,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Shoulder Blade (scapula)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.69716,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Upper Arm between Elbow and Shoulder (humerus)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 7.13371,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Coccyx',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.92258,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hip',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 3.23816,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Leg',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 9.23659,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Pelvis',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 3.05037,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Rib',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 7.89557,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Skull fracture – depressed (except bones of face and nose)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.148393724924167,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Vertebrae',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 7.21431731096333,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Face or Nose (except mandible or maxilla)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 7.26247143654173,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Lower Jaw, Mandible (except alveolar process)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.16573275328921,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Skull fracture – non-depressed (except bones of face and nose)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 2.24652100317185,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Upper Jaw, Maxilla (except alveolar process)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.11018534604199,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
			'Dislocations - Closed Reduction': {
				info: 'Closed Reduction means the realignment of a fracture or dislocation by other than surgical means.',
				benefits: [
					{
						label: 'Ankle - Bone or Bones of the Foot (other than toes)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.29856731395379,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Bone or Bones of the Hand (other than fingers)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.0201457146002229,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Elbow',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 2.44406635993132,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Wrist',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.128580512409578,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Neck',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.139748670697663,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Collarbone',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.25134226086705,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Hip',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.247616259066818,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Knee',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.25582125955001,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Shoulder (glenohumeral)',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 1.32770038688576,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
					{
						label: 'Lower Jaw',
						value: 500,
						range: [
							100,
							5000,
						],
						increment: 50,
						incidentRate: 0.150232265868265,
						isRequired: false,
						isEnabled: true,
						format: {
							type: 'usd',
							decimalPlaces: 0,
						},
					},
				],
			},
		},
	},
	rateSheet: [
		{
			$replaceRoot: {
				newRoot: '$underwriting.inputs.benefitGroups',
			},
		},
		{
			$addFields: {
				'Annual Claim Cost': {

				},
			},
		},
		{
			$addFields: {
				rates: {
					$let: {
						vars: {
							sum: {
								$sum: {
									$map: {
										input: {
											$reduce: {
												input: {
													$objectToArray: '$underwriting.inputs.benefitGroups',
												},
												initialValue: [],
												in: {
													$concatArrays: [
														'$$value',
														'$$this.v.benefits',
													],
												},
											},
										},
										as: 'benefit',
										in: {
											$divide: [
												{
													$multiply: [
														{
															$divide: [
																{
																	$cond: [
																		{
																			$or: [
																				'$$benefit.isRequired',
																				'$$benefit.isEnabled',
																			],
																		},
																		'$$benefit.value',
																		0,
																	],
																},
																'$$ROOT.underwriting.incidentRateDenominator',
															],
														},
														'$$benefit.incidentRate',
													],
												},
												'$$ROOT.underwriting.inputs.lossRatio.value',
											],
										},
									},
								},
							},
						},
						in: {
							$map: {
								input: '$underwriting.rates',
								as: 'ratesElement',
								in: {
									label: '$$ratesElement.label',
									rates: {
										$arrayToObject: {
											$map: {
												input: {
													$objectToArray: '$$ratesElement.rates',
												},
												as: 'newRates',
												in: {
													k: '$$newRates.k',
													v: {
														$multiply: [
															'$$newRates.v',
															'$$sum',
														],
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	],
};

export default ACCIDENT;
