import { createContext, useContext } from 'react';

// eslint-disable-next-line import/prefer-default-export
const CustomerContext = createContext({
	customerData: null,
	cognitoGroups: [],
});

const useCustomerData = () => {
	const context = useContext(CustomerContext);
	if (!context) {
		throw new Error('useCustomerData must be used within a CustomerProvider');
	}
	const { customerData, cognitoGroups } = context;
	return { customerData, cognitoGroups };
};

CustomerContext.displayName = 'CustomerContext';

export {
	CustomerContext,
	useCustomerData,
};
