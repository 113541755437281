import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRedoAlt, faChevronRight, faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
	Card, Table, ButtonIcon, ButtonGroup,
} from '@/lib/ReactRainbow';

function AsyncTable({
	isLoading,
	data,
	children,
	onRefresh,
	keyField,
	title,
	className,
	onGetNextDataSet,
	canGetMoreData,
	pageSize = data?.length,
}) {
	const [currentPage, setCurrentPage] = useState(0);
	const numberOfPages = Math.ceil(data?.length / pageSize);
	const isOnLastPage = currentPage + 1 === numberOfPages;

	const handleGetNextDataSet = async () => {
		try {
			if (isOnLastPage) {
				await onGetNextDataSet();
			}
			setCurrentPage((currentPageNumber) => (currentPageNumber + 1));
		} catch (error) {
			// the parent should already handling the error before it gets here.
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const displayMin = (pageSize * (currentPage)) + 1;
	const displayMax = (pageSize * (currentPage + 1));

	const header = (
		<div className="d-flex">
			{title}
			{title ? ' ' : ''}
			{isLoading
				? null
				: (
					<small>
						{`(showing ${displayMin}-${data?.length >= displayMax
							? displayMax : data?.length} of ${canGetMoreData ? 'many'
							: data?.length})`}
					</small>
				)}
		</div>
	);

	const displayData = data?.slice((pageSize * (currentPage)), (pageSize * (currentPage + 1)));

	return (
		<Card
			title={header}
			className={className}
			actions={(
				<ButtonIcon
					assistiveText="refresh data button"
					title="Refresh data"
					type="button"
					icon={<FontAwesomeIcon icon={faRedoAlt} className={isLoading ? 'animate-spin' : ''} />}
					variant="border"
					size="medium"
					disabled={isLoading}
					className=""
					tooltip="Refresh"
					onClick={onRefresh}
				/>
			)}
			footer={(
				<>
					<ButtonGroup>
						<ButtonIcon
							icon={<FontAwesomeIcon icon={faChevronLeft} />}
							className=""
							variant="outline-brand"
							onClick={() => setCurrentPage(currentPage - 1)}
							disabled={currentPage <= 0}
						/>
						<ButtonIcon
							icon={<FontAwesomeIcon icon={faChevronRight} />}
							className=""
							variant="outline-brand"
							onClick={handleGetNextDataSet}
							disabled={(!canGetMoreData && isOnLastPage)}
						/>
					</ButtonGroup>
				</>
			)}
		>
			<Table
				keyField={keyField}
				isLoading={isLoading}
				data={displayData}
			>
				{children}
			</Table>
		</Card>
	);
}

export default AsyncTable;
