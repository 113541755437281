import Amplify from 'aws-amplify';
import { Router } from '@reach/router';
import { AppProvider } from '@/providers';
import { Login, ForgotPassword, ChangePassword } from './pages';
import Main from './Main';
import config from './amplify.config';
import './public_styles/tailwind.css';
import './public_styles/App.css';

Amplify.configure(config);

function App() {
	return (
		<AppProvider>
			<div className="flex flex-col items-center">
				<Router className="flex flex-col w-full">
					<Login path="/login" />
					<ChangePassword path="/change-password" />
					<ForgotPassword path="/forgot-password" />
					<Main path="/*" />
				</Router>
			</div>
		</AppProvider>
	);
}

export default App;
