import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { IF } from '@/components/utils';
import ErrorBoundary from '@/components/ErrorBoundary';
import {
	Modal, Button, Spinner,
} from '@/lib/ReactRainbow';

const SubmitRequestModal = ({
	isOpen,
	onRequestClose,
	onCancel = onRequestClose, // in case you need different cancel functionality vs close.
	onOpened,
	onSubmit,
	isLoading,
	children,
	errorMsg,
	size = 'large',
	title,
	isViewLoading,
	submitLabel = 'Submit',
	disableSubmit,
	noFooter,
}) => {
	// this is here to protect against accidentally trying to render an object.
	let error = errorMsg?.message || errorMsg?.error || errorMsg;
	if (error && typeof error !== 'string') {
		// eslint-disable-next-line no-console
		console.log(errorMsg);
		error = 'An unknown error occured.';
	}
	return (
		<Modal
			isOpen={!!isOpen}
			size={size}
			title={title}
			onRequestClose={onRequestClose}
			onOpened={onOpened}
			footer={
				noFooter ? null
					: (
						<div className="flex flex-row justify-between">
							<Button
								label="Cancel"
								variant="base"
								onClick={onCancel}
							/>
							<Button
								label={submitLabel}
								variant="success"
								onClick={onSubmit}
								isLoading={isLoading}
								disabled={disableSubmit}
							/>
						</div>
					)
			}
		>
			<ErrorBoundary>
				{isViewLoading ? (
					<div className="h-96 relative">
						<Spinner variant="brand" />
					</div>
				)
					: (
						<div className="flex flex-col w-full mb-4 lg:px-5">
							{children}
							<IF condition={errorMsg}>
								<div className="text-buddy-red text-sm text-center mt-3">
									<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
									{error}
								</div>
							</IF>
						</div>
					)}
			</ErrorBoundary>
		</Modal>
	);
};

export default SubmitRequestModal;
