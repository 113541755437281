import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faChevronDown,
	faSpinner,
	faFilePdf,
	faFileCode,
	faFile,
	faFileCsv,
	faFileMedical,
	faFileImage,
} from '@fortawesome/free-solid-svg-icons';

import {
	ButtonMenu,
	MenuItem,
} from '@/lib/ReactRainbow';

const getIconFromFileType = (type) => {
	const icons = {
		default: <FontAwesomeIcon icon={faFile} />,
		pdf: <FontAwesomeIcon icon={faFilePdf} />,
		csv: <FontAwesomeIcon icon={faFileCsv} />,
		markdown: <FontAwesomeIcon icon={faFileCode} />,
		medical: <FontAwesomeIcon icon={faFileMedical} />,
		img: <FontAwesomeIcon icon={faFileImage} />,
	};

	// lazy way of shortcutting multiple options to the same reference
	Object.assign(icons, {
		md: icons.markdown,
		med: icons.medical,
		jpg: icons.img,
		png: icons.img,
	});

	return icons[type.toLowerCase()] || icons.default;
};

const STATUS = {
	NORMAL: 'NORMAL',
	LOADING: 'LOADING',
};

const loadingIcon = <FontAwesomeIcon icon={faSpinner} className="ml-0.5 fa-spin" />;

function DownloadButton({
	menuOptions = [],
	size = 'x-small',
	label = 'Download |',
	icon = <FontAwesomeIcon icon={faChevronDown} />,
	onMenuSelect = async () => {},
}) {
	const [status, setStatus] = useState(STATUS.NORMAL);

	const handleSelection = async (selection) => {
		setStatus(STATUS.LOADING);
		await onMenuSelect(selection);
		setStatus(STATUS.NORMAL);
	};

	return (
		<ButtonMenu
			menuSize={size}
			icon={status === STATUS.NORMAL ? icon : loadingIcon}
			label={label}
			iconPosition="right"
			disabled={status === STATUS.LOADING}
		>
			{menuOptions.map((option) => (
				<MenuItem key={`option-${option.value}`} icon={getIconFromFileType(option.fileType)} onClick={() => handleSelection(option.value)} label={option.label} />
			))}
		</ButtonMenu>
	);
}

export default DownloadButton;
