import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const {
	REACT_APP_partner_api_url: partnerAPIURL,
	REACT_APP_partner_api_version: partnerAPIVersion,
} = process.env;

function Success({ productDetails }) {
	const { id = 'TEST' } = productDetails;
	return (
		<>
			<h3 className="text-center">Woohoo!</h3>
			<p className="text-left">
      	This information has been sent to our carrier's underwriter for approval.
				We're stoked to get this off the ground and we'll be in touch shortly.
				In the meantime, they can integrate this
				{' '}
				<strong>right now</strong>
				{' '}
      in their staging or development environment.
			</p>
			<p className="text-left mt-2">
		In addition, an email has been sent to the organizer with integration instructions and the new offering ID
				{' '}
				<strong>
		(
					{id}
		)
				</strong>
		, which you can see below.
			</p>
			<pre
				className="bg-gray-800 min-w-100 md:min-w-min mr-auto rounded-lg p-2 my-5"
			>
				<span className="text-pink-400">const</span>
				{' '}
				<span className="text-green-400">offering</span>
				{' '}
				<span className="text-white">=</span>
				{' '}
				<span className="text-blue-400">
			"
					{id}
			"
				</span>
				<span className="text-white">;</span>
			</pre>
			<p className="text-left">
			Want to see it in action?
				{' '}
				<a target="_blank" rel="noreferrer" href={`${partnerAPIURL}/v${partnerAPIVersion}/quote?partnerID=p-buddytest&offering=${id}&ticketPrice=600&state=AZ`}>Click here.</a>
			</p>
			<FontAwesomeIcon
				icon={faThumbsUp}
				className="mt-8 color-blue block mx-auto"
				size="6x"
			/>
		</>
	);
}

export default Success;
