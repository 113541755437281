import React, {
	useEffect, useState, useRef,
} from 'react';

// lodash
import cloneDeep from 'lodash/cloneDeep';
import kebabCase from 'lodash/kebabCase';
import set from 'lodash/set';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';

import { diff } from 'deep-object-diff';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPen,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

import { useApiContext, useAppContext, usePartnerData } from '@/providers';
import { PARTNER_REPORTS, DEMO_BUDDIES } from '@/utils';
import { IF } from '@/components/utils';
import {
	Button,
	Card,
	ButtonIcon,
	Input,

} from '@/lib/ReactRainbow';

import {
	Label,
	StateSelect,
	CountrySelect,
	SectionHeader,
} from '@/components/UI';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const UI_STATE = {
	READ_ONLY: 'readonly',
	EDIT: 'edit',
	LOADING: 'loading',
};

const fromReact = (data) => {
	const returnData = cloneDeep(data);
	returnData.partnerReports = returnData?.partnerReports?.map((report) => report.value) || [];
	returnData.buddies = returnData?.buddies?.map((buddy) => buddy.value) || [];
	return returnData;
};

const toReact = (data) => {
	if (!data || !Object.keys(data).length) {
		return {};
	}
	const returnData = cloneDeep(data);
	returnData.partnerReports = PARTNER_REPORTS.filter((report) => returnData?.partnerReports?.includes(report.id))
		.map((report) => ({
			icon: null,
			label: report.title,
			name: `option-${report.id}`,
			value: report.id,
		}));

	returnData.buddies = DEMO_BUDDIES.filter((buddy) => returnData?.buddies?.includes(buddy.id))
		.map((buddy) => ({
			icon: null,
			label: buddy.title,
			name: `option-${buddy.id}`,
			value: buddy.id,
		}));

	return returnData;
};

function GeneralInfo({ onUpdate = () => {} }) {
	// CONTEXTS
	const { displayError } = useAppContext();
	const { partnerAPI } = useApiContext();
	const partnerData = toReact(usePartnerData().partnerData); // didn't destructure, because needed to alter

	// STATES
	const [uiState, setUIState] = useState(UI_STATE.READ_ONLY);
	const [tempState, setTempState] = useState(null);
	const [errorMsg, setErrorMsg] = useState(null);
	const [hasUpdated, setHasUpdated] = useState(false);

	// REFS
	const updatePartnerRef = useRef();

	// EFFECTS
	useEffect(() => {
		// if we jump to edit mode, make a quick copy of what's up
		if (uiState === UI_STATE.EDIT) {
			setErrorMsg(null); // 1
			setHasUpdated(false);

			const partnerCopy = cloneDeep(partnerData);
			setTempState(partnerCopy);
		} else if (uiState === UI_STATE.READ_ONLY) {
			setTempState(null);
		}
	}, [uiState]);

	const handleChange = ({ target: { name, value } }) => {
		const newInfo = cloneDeep(tempState);
		set(newInfo, name, value);
		setTempState(newInfo);
	};

	// Check for errors
	const checkForErrors = () => {
		const requiredProps = [
			{
				id: 'name',
				label: 'Name',
			},
			{
				id: 'slug',
				label: 'Slug Site',
			}];
		const emptyFields = requiredProps.filter((prop) => !tempState[prop.id]?.length);
		if (emptyFields.length) {
			const errorMessage = 'This field is required.';
			return errorMessage;
		}
		return null;
	};

	const reCheckErrors = () => {
		if (hasUpdated) {
			setErrorMsg(checkForErrors());
		}
	};

	const {
		name = '',
		slug = '',
		info,
		address,
		website = '',
	} = (uiState === UI_STATE.READ_ONLY ? partnerData : tempState) || {};

	const {
		country = 'United States',
		city = '',
		state = '',
		street = '',
		zip = '',
	} = address || info || {};

	const handleInfoUpdate = async () => {
		setUIState(UI_STATE.LOADING);
		setHasUpdated(true);

		const errorFound = checkForErrors();
		if (errorFound) {
			setErrorMsg(errorFound);
			setUIState(UI_STATE.EDIT);
			return;
		}

		try {
			if (updatePartnerRef.current) {
				updatePartnerRef.current.abort();
			}

			const dataToUse = fromReact(tempState);

			// check what was updated
			const diffData = diff(fromReact(partnerData), dataToUse);

			const dataAllowedToBeSent = ['name', 'slug', 'address', 'hubspot', 'slack', 'partnerReports', 'buddies', 'isAPIIntegration', 'offerings', 'website'];
			const intersectionData = intersection(Object.keys(diffData), dataAllowedToBeSent);

			// if there isn't any update, kill this
			if (!intersectionData?.length) {
				setUIState(UI_STATE.READ_ONLY);
				return;
			}

			// otherwise, grab the data we want to send AND that is updated
			const cleanedPartnerData = pick(dataToUse, intersectionData);

			// make sure the http(s) protocol is on the website url
			if (cleanedPartnerData?.website?.length && cleanedPartnerData.website.search(/^http[s]?:\/\//) === -1) {
				cleanedPartnerData.website = `http://${cleanedPartnerData.website}`;
			}

			const updatePartner = await partnerAPI.update(partnerData.id, cleanedPartnerData);
			updatePartnerRef.current = updatePartner;

			const partnerFromServer = await updatePartner.send();
			onUpdate(partnerFromServer);
			setUIState(UI_STATE.READ_ONLY);
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setUIState(UI_STATE.EDIT);
				displayError(error);
				updatePartnerRef.current = null;
			}
		}
	};

	const infoRow = 'flex flex-column sm:flex-row w-full justify-between flex-wrap mb-5';
	const infoField = uiState === UI_STATE.READ_ONLY ? 'w-full sm:w-6/12 px-7' : 'w-full sm:w-6/12 px-3';

	return (
		<div className="mt-4">
			<SectionHeader
				title="General Info"
				className="ml-4 border-b-2"
				size="md"
				actions={(
					<>
						<IF condition={uiState === UI_STATE.READ_ONLY}>
							<ButtonIcon
								assistiveText="refresh data button"
								title="Refresh the data"
								type="button"
								icon={<FontAwesomeIcon icon={faPen} />}
								variant="base"
								size="medium"
								onClick={() => setUIState(UI_STATE.EDIT)}
							/>
						</IF>
						<IF condition={uiState !== UI_STATE.READ_ONLY}>
							<div className="flex flex-row mb-2 pt-0.5">
								<Button
									label="Cancel"
									variant="destructive"
									size="small"
									className="mr-2"
									onClick={() => setUIState(UI_STATE.READ_ONLY)}
									disabled={uiState === UI_STATE.LOADING}
								/>
								<Button
									label="Save"
									variant="success"
									size="small"
									onClick={handleInfoUpdate}
									disabled={uiState === UI_STATE.LOADING}
								/>
							</div>
						</IF>
					</>
				)}
			/>
			<div className="w-full pl-4 mt-4">
				<Card>
					<div className="flex flex-col justify-between w-full mt-4">
						<div className={infoRow}>
							<Input
								label={<Label className="m-0" label="Name" isRequired={uiState !== UI_STATE.READ_ONLY} />}
								labelAlignment="left"
								className={infoField}
								placeholder="Wayne Enterprises"
								type="text"
								name="name"
								value={name}
								onChange={handleChange}
								readOnly={uiState === UI_STATE.READ_ONLY}
								disabled={uiState === UI_STATE.LOADING}
								error={hasUpdated && !name.length ? errorMsg : null}
								onBlur={reCheckErrors}
							/>
							<IF condition={uiState !== UI_STATE.READ_ONLY}>
								<Input
									label={<Label className="m-0" label="Site Slug" isRequired={uiState !== UI_STATE.READ_ONLY} />}
									labelAlignment="left"
									className={infoField}
									placeholder="wayne-enterprise"
									type="text"
									name="slug"
									value={slug}
									onChange={handleChange}
									onBlur={() => handleChange({ target: { name: 'slug', value: kebabCase(slug) } }) && reCheckErrors}
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
									error={hasUpdated && !slug.length ? 'This field is required.' : null}
								/>
							</IF>
							<IF condition={uiState === UI_STATE.READ_ONLY}>
								<div
									className={infoField}
								>
									<Label
										className="m-0"
										label="Site Slug"
										htmlFor="slug-link"
										isRequired={uiState !== UI_STATE.READ_ONLY}
									/>
									<br />
									<a
										id="slug-link"
										className="font-normal text-buddy-red no-underline active:text-buddy-red hover:text-buddy-red visited:text-buddy-red hover:no-underline active:no-underline focus:text-buddy-red focus:no-underline"
										href={`https://my.buddy.insure/${slug}`}
										target="_blank"
										rel="noreferrer"
									>
										{slug}
										<FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
									</a>
								</div>
							</IF>
						</div>
						<div className={infoRow}>
							<div className={`${infoField} flex flex-row`}>
								<Input
									label={<Label className="m-0" label="Address" htmlFor="address-street" />}
									id="address-street"
									labelAlignment="left"
									className="w-full pr-4"
									placeholder="101 Wayne Manor"
									type="text"
									name="address.street"
									value={street}
									onChange={handleChange}
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
								/>
								<Input
									label={<Label className="m-0" label="City" htmlFor="address-city" />}
									id="address-city"
									labelAlignment="left"
									className="w-full md:w-full lg:w-full ml-7"
									placeholder="Gotham"
									type="text"
									name="address.city"
									value={city}
									onChange={handleChange}
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
								/>
							</div>
							<div className={infoField}>
								<IF condition={uiState !== UI_STATE.READ_ONLY}>
									<StateSelect
										label={<Label className="m-0" label="State" htmlFor="address-state" />}
										id="address-state"
										className="w-full md:w-20"
										name="address.state"
										value={state}
										onChange={handleChange}
										disabled={uiState === UI_STATE.LOADING}
									/>
								</IF>
								<IF condition={uiState === UI_STATE.READ_ONLY}>
									<Input
										label={<Label className="m-0" label="State" htmlFor="address-state" />}
										id="address-state"
										className="w-full md:w-10"
										placeholder="VA"
										type="text"
										name="address.state"
										value={state}
										readOnly
										disabled={uiState === UI_STATE.LOADING}
									/>
								</IF>
							</div>
						</div>
						<div className={infoRow}>
							<div className={`${infoField} flex flex-row`}>
								<Input
									label={<Label label="Zip" htmlFor="address-zip" />}
									labelAlignment="left"
									id="address-zip"
									className="w-full pr-4"
									placeholder="84755"
									type="text"
									name="address.zip"
									value={zip}
									onChange={handleChange}
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
								/>
								<IF condition={uiState !== UI_STATE.READ_ONLY}>
									<CountrySelect
										label={<Label className="m-0" label="Country" htmlFor="address-country" />}
										id="address-country"
										className="w-full md:w-full lg:w-full"
										name="address.country"
										value={country}
										onChange={handleChange}
										readOnly={uiState === UI_STATE.READ_ONLY}
										disabled={uiState === UI_STATE.LOADING}
									/>
								</IF>
								<IF condition={uiState === UI_STATE.READ_ONLY}>
									<Input
										label={<Label className="m-0" label="Country" htmlFor="address-country" />}
										id="address-country"
										className="w-full md:w-full lg:w-full ml-7"
										placeholder="US"
										type="text"
										name="address.country"
										value={country}
										readOnly
									/>
								</IF>
							</div>
							<div className={infoField}>
								<Label className={uiState !== UI_STATE.READ_ONLY ? 'ml-4' : ''} label="Website" />
								<br />
								<IF condition={uiState !== UI_STATE.READ_ONLY}>
									<Input
										id="website"
										className="w-full"
										placeholder="https://wayne-corp.com"
										type="text"
										name="website"
										value={website}
										onChange={handleChange}
										readOnly={uiState === UI_STATE.READ_ONLY}
										disabled={uiState === UI_STATE.LOADING}
									/>
								</IF>
								<IF condition={uiState === UI_STATE.READ_ONLY}>
									<IF condition={!!website}>
										<a
											className="font-normal text-buddy-red no-underline active:text-buddy-red hover:text-buddy-red visited:text-buddy-red hover:no-underline active:no-underline focus:text-buddy-red focus:no-underline"
											href={website}
											target="_blank"
											rel="noreferrer"
										>
											{website}
											<FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
										</a>
									</IF>
									<IF condition={!website}>
										<p className="text-sm pt-2 text-gray-400">(not set)</p>
									</IF>
								</IF>
							</div>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}

export default GeneralInfo;
