import React, { useState } from 'react';
import AceEditor from 'react-ace';
import { snakeCase } from 'lodash';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTicketAlt,
	faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import {
	productToMarkdown,
	getFakeTicketCancellationFormData,
	CHIP_VARIANTS,
	PRODUCT_ACTIONS,
} from '@/utils';
import { useApiContext, useAppContext } from '@/providers';
import {
	Card,
	Chip,
	ButtonMenu,
	Modal,
} from '@/lib/ReactRainbow';

import { Summary } from '@/components/builder/TC';
import { SWITCH } from '@/components/utils';

import {
	DownloadButton,
} from '@/components/UI';

import ProductActions from '@/components/ProductActions';

const formatDate = (date) => {
	const year = date.getFullYear();
	const month = (1 + date.getMonth()).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	return `${month}/${day}/${year}`;
};

const MODAL_TYPES = {
	RAW: 'RAW',
	SUMMARY: 'SUMMARY',
};

// this is for the downloads menu button
const DOWNLOADS = [
	{ label: 'Sample File', value: PRODUCT_ACTIONS.DOWNLOAD_SAMPLE, fileType: 'pdf' },
	{ label: 'Summary PDF', value: PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_PDF, fileType: 'pdf' },
	{ label: 'Summary Markdown', value: PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_MD, fileType: 'md' },
];

function TicketCancellationCard({ product, className = '', onSelectProduct }) {
	const [modalIsOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState(MODAL_TYPES.RAW);

	const { pdfAPI } = useApiContext();
	const { displayError } = useAppContext();

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openModal = (type) => {
		setModalType(type);
		setIsModalOpen(true);
	};

	const onDownloadSelected = async (id) => {
		switch (id) {
		case PRODUCT_ACTIONS.DOWNLOAD_SAMPLE:
			try {
				const info = getFakeTicketCancellationFormData(product);
				const sendable = await pdfAPI.fill(info.pdf, info.data);
				await sendable.send();
			} catch (error) {
				displayError(error);
			}
			break;
		case PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_MD:
			try {
				const markdown = productToMarkdown(product);
				const filename = snakeCase(product?.event || 'event');
				const file = new File([markdown], `${filename}.md`, {
					type: 'text/markdown;charset=utf-8',
				});
				FileSaver.saveAs(file);
			} catch (error) {
				displayError(error);
			}
			break;
		case PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_PDF:
			try {
				const markdown = productToMarkdown(product);
				const filename = snakeCase(product?.event || 'event');
				const sendable = await pdfAPI.fromMarkdown(markdown, `${filename}.pdf`);
				await sendable.send();
			} catch (error) {
				displayError(error);
			}
			break;
		default:
			// eslint-disable-next-line no-console
			console.log(id);
		}
	};

	const onActionSelected = async (id, action) => {
		switch (action) {
		case PRODUCT_ACTIONS.VIEW_RAW:
			openModal(MODAL_TYPES.RAW);
			break;
		case PRODUCT_ACTIONS.VIEW_SUMMARY:
			openModal(MODAL_TYPES.SUMMARY);
			break;
		case PRODUCT_ACTIONS.EDIT:
			onSelectProduct(product);
			break;
		default:
			// eslint-disable-next-line no-console
			console.log(id, action);
		}
	};

	return (
		<div
			key={`card-${product.id}`}
			className={`px-3 w-full sm:w-1/2 mb-5 ${className}`}
		>
			<Card
				id={`card-${product.id}`}
				title={(
					<div className="flex text-xl capitalize">{product.event}</div>
				)}
				actions={(
					<Chip
						variant={CHIP_VARIANTS[product.status]}
						label={(
							<div className="flex items-center justify-between">

								<div>{product.status}</div>
							</div>
						)}
					/>
				)}
				icon={(
					<FontAwesomeIcon
						icon={product.icon || faTicketAlt}
						className=""
					/>
				)}
				className="w-full"
				footer={(
					<div className="flex justify-between w-full">
						<DownloadButton
							menuOptions={DOWNLOADS}
							onMenuSelect={onDownloadSelected}
						/>
						<ButtonMenu
							menuSize="x-small"
							icon={<FontAwesomeIcon icon={faEllipsisV} />}
						>
							<ProductActions status={product.status} id={product.id} onActionSelected={onActionSelected} />
						</ButtonMenu>
					</div>
				)}
			>
				<div className="p-5" style={{ fontSize: '1rem ' }}>
					<p className="capitalize">
						<strong>Type:</strong>
						{' '}
						{product.eventType || 'CONFERENCE'}
					</p>
					<p>
						<strong>Registration Start Date:</strong>
						{' '}
						{formatDate(new Date(product?.registrationStartDate || Date.now()))}
					</p>
					<p>
						<strong>Registration End Date:</strong>
						{' '}
						{formatDate(new Date(product.registrationEndDate || Date.now()))}
					</p>
				</div>
			</Card>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				title={product.event || product.name}
				size={modalType === MODAL_TYPES.SUMMARY ? 'large' : 'small'}
			>
				<SWITCH match={modalType}>
					<AceEditor
						mode="json"
						theme="github"
						className="w-full"
						name="ACE"
						wrapEnabled
						editorProps={{ $blockScrolling: true }}
						value={JSON.stringify(product, null, 2)}
						readOnly
						style={{ width: '100%' }}
						case={MODAL_TYPES.RAW}
					/>
					<Summary productDetails={product} productID={`/${product.id}`} case={MODAL_TYPES.SUMMARY} />
				</SWITCH>
			</Modal>
		</div>
	);
}

export default TicketCancellationCard;
