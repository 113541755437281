import moment from 'moment-timezone';
import diff from 'microdiff';

const getDiffs = (obj1, obj2) => diff(obj1, obj2);

const Data = {
	groupDataByTimeIncrement: (data, increment, prop = 'orderDate') => {
		const { unit, interval } = increment;
		const grouped = {};
		// console.log(increment)
		const timeLabels = {
			minutes: 'h:mm a',
			hours: 'ddd-ha',
			hour: 'ddd-ha',
			day: 'MMM-D',
			days: 'MMM-D',
			weeks: 'MMM YYYY',
		};

		const findIndexAfterMoment = (m) => (el) => moment(el[prop]).isAfter(m); // returns a function used for finding

		const m = moment(data[0][prop]).startOf(unit);
		let label = m.add(interval, unit).format(timeLabels[unit]);

		while (data.length) {
			const finderFunc = findIndexAfterMoment(m);
			const index = data.findIndex(finderFunc);

			if (index === -1) {
				if (data.length) {
					// this is the end
					grouped[label] = data.splice(0);
				}
			} else {
				grouped[label] = data.splice(0, index);
			}

			label = m.add(interval, unit).format(timeLabels[unit]);
		}

		return grouped;
	},
};

const isRequired = (argument) => {
	throw new Error(`The argument ${argument} is required`);
};

// converts object to querystring
const serialize = (obj, startChar = '?') => {
	const str = [];
	Object.keys(obj).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			// don't add empty strings
			if (typeof obj[key] === 'string') {
				if (obj[key].length) {
					str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
				}
			} else if (obj[key] !== null) { // don't let nulls in either
				str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
			}
		}
	});
	return `${startChar}${str.join('&')}`;
};

const defaultOptions = {
	strict: false, // makes sure each object has the EXACT SAME DATA
	fieldOrder: null, // allows the order to be changed, values include an array that contains the key order, alphabetical, or null that maintains whatever JS decides
	columnDelimiter: ',', // separator between columns
	rowDelimiter: '\n', // separator between rows
};

// a quick way to check if 2 arrays have the same elements, used in strict mode only
const arraysAreEqual = (keys, obj) => keys.sort().join('') === Object.keys(obj).sort().join('');

const arrayToCSV = (data, newOptions = {}) => {
	const options = { ...defaultOptions, ...newOptions };
	// make sure there is an array of data
	if (!data || !data.length || data[0] === null) {
		return null;
	}

	const { columnDelimiter, rowDelimiter } = options;

	let keys;
	if (options.strict) {
		// check to make sure ALL data is the same
		const compare = Object.keys(data[0]);
		if (data.filter((el) => !arraysAreEqual(compare, el)).length) {
			return null;
		}
	} else {
		// go through and find ALL available fields
		// using reducer, we take the current set of keys, concat the next set of keys, use "Set" to get unique values, then covert right back to array
		keys = data.reduce(
			(curSet, set) => [...new Set(curSet.concat(Object.keys(set)))],
			[],
		);
	}

	if (options.fieldOrder) {
		if (Array.isArray(options.fieldOrder)) {
			// any fields found that are NOT in fieldOrder, will be placed at the end
			keys = options.fieldOrder.concat(
				keys.filter((needle) => options.fieldOrder.indexOf(needle) === -1),
			);
		} else if (options.fieldOrder.toLowerCase() === 'alphabetical') {
			keys.sort();
		}
	}

	let csv = '';
	csv += keys.join(columnDelimiter);
	csv += rowDelimiter;

	data.forEach((item) => {
		keys.forEach((key, i) => {
			if (i > 0) {
				csv += columnDelimiter;
			}
			csv += item[key] !== null ? item[key] : '';
		});
		csv += rowDelimiter;
	});
	return csv;
};

export {
	Data,
	arrayToCSV,
	getDiffs,
	isRequired,
	serialize,
};
