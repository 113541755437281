import { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { unCamelCase, properCase } from '@buddy-technology/buddy_helpers';
import { useAppContext, useApiContext } from '@/providers';
import AsyncTable from '@/components/UI/AsyncTable';
import { Chip, Column } from '@/lib/ReactRainbow';

function LoginHistoryTable({ id, type }) {
	const [isLoading, setIsLoading] = useState(false);
	const [history, setHistory] = useState([]);

	const getHistoryRef = useRef();

	const { authAPI } = useApiContext();
	const { displayError } = useAppContext();

	if (!id) {
		// eslint-disable-next-line no-console
		console.error('id is required to get login history');
	}
	if (!(type === 'partner' || type === 'customer')) {
		// eslint-disable-next-line no-console
		console.error('type must be either customer or partner');
	}

	const getHistory = async (nextToken = null) => {
		if (getHistoryRef.current) {
			getHistoryRef.current.abort();
		}
		setIsLoading(true);
		try {
			const fetchHistory = await authAPI.getHistory({
				id, maxResults: 10, nextToken, type,
			});
			getHistoryRef.current = fetchHistory;

			const res = await fetchHistory.send();
			// clean up the data.
			res.data = res.data.map((el) => ({
				...el,
				CreationDate: moment(el.CreationDate).format('MMM Do YYYY, h:mm:ss a'),
				EventType: properCase(unCamelCase(el.EventType)),
			}));
			if (nextToken && history?.data?.length) {
				res.data = [...history.data, ...res.data];
			}

			setHistory(res);
			setIsLoading(false);
			getHistoryRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error);
				setIsLoading(false);
				getHistoryRef.current = null;
			}
		}
	};

	useEffect(() => {
		getHistory();
		return () => {
			if (getHistoryRef.current) {
				getHistoryRef.current.abort();
			}
		};
	}, []);

	const getRiskVariant = (value) => {
		if (value?.toLowerCase() === 'low') {
			return 'success';
		}
		if (value?.toLowerCase() === 'medium') {
			return 'warning';
		}
		if (value?.toLowerCase() === 'high') {
			return 'error';
		}
		return 'neutral';
	};

	return (
		<AsyncTable
			title="Login History"
			className="my-5"
			keyField="EventId"
			isLoading={isLoading}
			data={history?.data}
			pageSize={10}
			onRefresh={() => getHistory()}
			onGetNextDataSet={() => getHistory(history?.nextToken)}
			canGetMoreData={!!history?.nextToken}
		>
			<Column header="Event" field="EventType" />
			<Column header="Timestamp" field="CreationDate" />
			<Column header="Location" field="EventContextData.City" />
			<Column header="Device" field="EventContextData.DeviceName" />
			<Column header="IP Address" field="EventContextData.IpAddress" />
			<Column
				header="Risk"
				field="EventRisk.RiskLevel"
				component={(data) => (
					data?.value ? <Chip label={data?.value} variant={getRiskVariant(data?.value)} />
						: null
				)}
			/>
		</AsyncTable>
	);
}

export default LoginHistoryTable;
