const OFFERING_STATUS = {
	CREATED: 'CREATED',
	PUBLISHED: 'PUBLISHED',
	SUBMITTED: 'SUBMITTED',
	DEACTIVATED: 'DEACTIVATED',
};

const EVENT_TYPES = [
	'CONFERENCE',
	'MEETUP',
	'RACE',
	'TOURNAMENT',
	'OTHER',
];

const DEFAULT_RATES = {
	DEFAULT: 0.15,
	AK: 0.1436,
	FL: 0.0171,
	NV: 0.1586,
	WA: 0.2143,
};

const DEFAULT_MIN_PREMIUMS = {
	DEFAULT: 5,
	AK: 5,
	FL: 5,
	NV: 5,
	WA: 5,
};

const DEFAULT_COMMISSIONS = {
	DEFAULT: 0.4,
	AK: 0.4,
	FL: 0.4,
	NV: 0.4,
	WA: 0.4,
};

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

const WHO_TYPE = {
	ME: { label: 'Customer only', value: 'ME' },
	ME_AND_SPOUSE: { label: 'Customer and spouse', value: 'ME_AND_SPOUSE' },
	FAMILY: { label: 'Whole family', value: 'FAMILY' },
	KIDS: { label: 'Just kids', value: 'KIDS' },
};

const DURATION_TYPE = {
	YEAR: { value: 'YEAR', label: 'Annual' },
	MONTH: { value: 'MONTH', label: 'Month' },
	WEEK: { value: 'WEEK', label: 'Week' },
	DAY: { value: 'DAY', label: 'Day' },
	MANUAL: { value: 'MANUAL', label: 'Specific Dates' },
};

const PAYMENT_TYPE = {
	PAYMENTS: { label: 'Monthly', value: 'PAYMENTS' },
	SINGLE_PAYMENT: { label: 'One-time', value: 'SINGLE_PAYMENT' },
};

const PAYMENT_OPTIONS = {
	SINGLE_PAYMENT: 'SINGLE_PAYMENT',
	PAYMENTS: 'PAYMENTS',
};

const COVERED_TYPE = {
	ME: 'ME',
	SPOUSE: 'SPOUSE',
	KID: 'KID',
};

const POLICY_STATUS = {
	ACTIVE: 'ACTIVE',
	CANCELLED: 'CANCELLED',
	EXPIRED: 'EXPIRED',
	NOT_STARTED: 'NOT_STARTED',
	CANCELLED_BEFORE_USE: 'CANCELLED_BEFORE_USE',
	RESERVATION: 'RESERVATION',
	CANCELLED_RESERVATION: 'CANCELLED_RESERVATION',
};

const POLICY_STATUS_ANY_CANCELLED = [
	POLICY_STATUS.CANCELLED,
	POLICY_STATUS.CANCELLED_BEFORE_USE,
	POLICY_STATUS.CANCELLED_RESERVATION,
];

const POLICY_GENESIS_TYPE = {
	RESERVED: 'RESERVED',
	ISSUED: 'ISSUED',
};

const LOG_TYPE = {
	CREATION: 'CREATION',
	ENDORSEMENT: 'ENDORSEMENT',
	PAYMENT_MADE: 'PAYMENT',
	CANCELLATION: 'CANCELLATION',
	RESERVATION_ACTIVATION: 'RESERVATION_ACTIVATION',
	RESERVATION_CANCELLATION: 'RESERVATION_CANCELLATION',
	RESERVATION_CHANGE: 'RESERVATION_CHANGE',
	ADJUSTMENT: 'ADJUSTMENT',
	DISCOUNT: 'DISCOUNT',
};

const ALL_PAYMENTS = [
	LOG_TYPE.CREATION,
	LOG_TYPE.ENDORSEMENT,
	LOG_TYPE.PAYMENT_MADE,
	LOG_TYPE.ADJUSTMENT,
	LOG_TYPE.RESERVATION_ACTIVATION,
	LOG_TYPE.DISCOUNT,
];
const ALL_CUSTOMER_PAYMENTS = [
	LOG_TYPE.CREATION,
	LOG_TYPE.ENDORSEMENT,
	LOG_TYPE.PAYMENT_MADE,
	LOG_TYPE.RESERVATION_ACTIVATION,
];
const ALL_CANCELLATIONS = [LOG_TYPE.CANCELLATION, LOG_TYPE.RESERVATION_CANCELLATION];
const JUST_ADJUSTMENTS = [LOG_TYPE.ADJUSTMENT];
const JUST_DISCOUNTS = [LOG_TYPE.DISCOUNT];
const DISCOUNTS_AND_ADJUSTMENTS = [LOG_TYPE.DISCOUNT, LOG_TYPE.ADJUSTMENT];

const NOTE_CATEGORIES = {
	GENERAL: 'GENERAL',
	CLAIMS: 'CLAIMS',
	ENDORSEMENTS: 'ENDORSEMENTS',
	ACCOUNTING: 'ACCOUNTING',
};

// DISCOUNTS
const DISCOUNT_TYPE = {
	PERCENT: 'PERCENT',
	// FIXED: 'FIXED', // removed after conversation with Jay and Brittany
};

// for downloading
const DOWNLOAD_TYPE = {
	BASE64: 'BASE64',
	BLOB: 'BLOB',
	DOWNLOAD: 'DOWNLOAD',
	FILE: 'FILE',
};

const DATE_FORMAT = 'MM/DD/YYYY';

// these are just for v1 of the product
const STATE_REQUIRED_VALUES = {
	OH: {
		isCompeting: true,
	},
	TN: {
		paymentOption: PAYMENT_TYPE.SINGLE_PAYMENT.value,
	},
};

const ACCEPTED_STATES = [
	'AK',
	'AL',
	'AR',
	'AZ',
	'CO',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'IN',
	'IA',
	'IL',
	'KS',
	'KY',
	'LA',
	'ME',
	'MI',
	'MN',
	'MO',
	'MT',
	'MS',
	'NC',
	'ND',
	'NE',
	'NH',
	'NV',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY',
];

const CLAIM_STATUS_TYPE = {
	ACKNOWLEDGED: 'ACKNOWLEDGED',
	POL_SUBMITTED: 'POL_SUBMITTED',
	SUBMITTED_TO_TPA: 'SUBMITTED_TO_TPA',
	UNDER_REVIEW: 'UNDER_REVIEW',
	APPROVED: 'APPROVED',
	DENIED: 'DENIED',
	INFO_REQUESTED: 'INFO_REQUESTED',
};

const CLAIM_LOG_TYPE = {
	CREATED: 'CREATED',
	STATUS_UPDATE: 'STATUS_UPDATE',
	FILES_ADDED: 'FILES_ADDED',
	NOTE_ADDED: 'NOTE_ADDED',
};

const CARRIERS_STATES = {
	SNIC: ['AZ', 'KY', 'NC', 'OH'],
	NSIC: ['CO', 'TN', 'TX'],
};

const FILE_CATEGORIES = {
	CLAIM: 'CLAIM',
	POLICY: 'POLICY',
	CUSTOMER: 'CUSTOMER',
};

const EMAIL_TEMPLATES = {
	CLAIMS: {
		ACKNOWLEDGED: 'CLAIM_ACKNOWLEDGED',
		SUBMITTED_TO_TPA: 'CLAIM_SUBMITTED_TO_TPA',
	},
};

const OFFERINGS = {
	BUDDY_ACCIDENT_V1: 'BUDDY_ACCIDENT_V1',
	BUDDY_ACCIDENT_V2: 'BUDDY_ACCIDENT_V2',
	ITC_TICKET_CANCELLATION: 'ITC_TICKET_CANCELLATION',
};

const PALETTES = {
	base: {
		primary: '#000000',
		secondary: '#254E70',
		tertiary: '#848484',
		negative: '#e45b78',
		positive: '#A3D9B1',
		textPrimary: '#000',
		textSecondary: '848484',
		backgroundPrimary: '#fff',
		backgroundSecondary: '#7d8387',
	},
	buddy: {
		primary: '#DC5648',
		secondary: '#0A242D',
		tertiary: '#46460E',
		negative: '#D62719',
		positive: '#A3D9B1',
		textPrimary: '#0A242D',
		textSecondary: '#0A242D',
		backgroundPrimary: 'linear-gradient(#D6F1F2, #F8F6D2)',
		backgroundSecondary: '#FBF9EF',
	},
};

const BASE_THEME_OPTIONS = [
	{ label: 'Base', value: 'base' },
	{ label: 'Buddy', value: 'buddy' },
];

export {
	WHO_TYPE,
	DURATION_TYPE,
	PAYMENT_TYPE,
	PAYMENT_OPTIONS,
	COVERED_TYPE,
	DATE_FORMAT,
	POLICY_STATUS,
	LOG_TYPE,
	ALL_PAYMENTS,
	ALL_CUSTOMER_PAYMENTS,
	ALL_CANCELLATIONS,
	JUST_ADJUSTMENTS,
	JUST_DISCOUNTS,
	DISCOUNTS_AND_ADJUSTMENTS,
	POLICY_GENESIS_TYPE,
	NOTE_CATEGORIES,
	STATE_REQUIRED_VALUES,
	ACCEPTED_STATES,
	DISCOUNT_TYPE,
	DOWNLOAD_TYPE,
	CLAIM_STATUS_TYPE,
	CLAIM_LOG_TYPE,
	CARRIERS_STATES,
	FILE_CATEGORIES,
	EMAIL_TEMPLATES,
	POLICY_STATUS_ANY_CANCELLED,
	OFFERINGS,
	OFFERING_STATUS,
	EVENT_TYPES,
	DEFAULT_RATES,
	DEFAULT_COMMISSIONS,
	DEFAULT_MIN_PREMIUMS,
	DEFAULT_DATE_FORMAT,
	BASE_THEME_OPTIONS,
	PALETTES,
};
