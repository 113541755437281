import React, {
	useEffect, useState, useRef, useCallback,
} from 'react';
import { Range, getTrackBackground } from 'react-range';

import { SWITCH } from '../utils';
import DraggableNumberInput, { getMoneyMask, getGenericMask } from '../DraggableNumberInput';

const MODES = {
	STANDARD: 'STANDARD',
	EDITABLE: 'EDITABLE',
};

function RangeSlider({
	min = 0,
	max = 100,
	values,
	onChange,
	step = 1,
	className = '',
	style = {},
	valueRender = (val) => val,
	isCurrency = false,
	disabled = false,
}) {
	const [mode, setMode] = useState(MODES.STANDARD);
	const node = useRef();

	const handleClick = useCallback((event) => {
		if (node.current.contains(event.target)) {
			// inside click
			return;
		}
		setMode(MODES.STANDARD);
	}, []);

	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			setMode(MODES.STANDARD);
		}
	}, []);

	useEffect(() => {
		if (mode === MODES.EDITABLE) {
			// add when mounted
			document.addEventListener('mousedown', handleClick);
			document.addEventListener('keydown', escFunction);
		} else {
			document.removeEventListener('mousedown', handleClick);
			document.addEventListener('keydown', escFunction);
		}
		// return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', escFunction);
		};
	}, [mode]);

	const colors = values.length === 2 ? [
		'rgba(248,221,218,0.7)',
		'#dc5648',
		'rgba(248,221,218,0.7)',
	] : [
		'rgba(248,221,218,0.7)',
		'rgba(248,221,218,0.7)',
	];

	const handleDoubleClick = (event) => {
		setMode(mode === MODES.STANDARD ? MODES.EDITABLE : MODES.STANDARD);
	};
	const inputClass = 'outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl w-1/3 md:w-1/6 lg:w-1/12 mx-auto px-1';
	let valueLabel;
	if (disabled) {
		valueLabel = valueRender(0);
	} else {
		valueLabel = values.length === 1 ? valueRender(values[0]) : `${valueRender(values[0])} - ${valueRender(values[1])}`;
	}
	return (
		<div className={`w-full ${className} ${disabled && 'opacity-50 cursor-not-allowed'}`} style={style}>
			<SWITCH match={mode}>
				<label
					className={`block text-center select-none ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
					onDoubleClick={disabled ? null : handleDoubleClick}
					case={MODES.STANDARD}
				>
					{valueLabel}
				</label>
				<div
					className="flex flex-row mx-auto my-2"
					case={MODES.EDITABLE}
					ref={node}
				>
					<div className="flex flex-row mx-auto">
						<DraggableNumberInput
							className={inputClass}
							value={values[0]}
							onChange={(value) => onChange([value, values[1]])}
							min={min}
							max={max}
							speed={step}
							mask={isCurrency ? getMoneyMask : getGenericMask}
							style={{ width: '70%' }}
							disabled={disabled}
						/>
						{values.length > 1
						&& (
							<>
								<div>-</div>
								<DraggableNumberInput
									className={inputClass}
									value={values[1]}
									onChange={(value) => onChange([values[0], value])}
									min={min}
									max={max}
									speed={step}
									mask={isCurrency ? getMoneyMask : getGenericMask}
									style={{ width: '70%' }}
									disabled={disabled}
								/>
							</>
						)}

						{/* {values.length === 2
						&& (
							<div className="fex flex-col">

							</div>
						)} */}
					</div>
				</div>
			</SWITCH>
			<Range
				step={step}
				min={min}
				max={max}
				values={values}
				onChange={onChange}
				renderTrack={({ props, children }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '0.625rem',
							width: '100%',
							background: getTrackBackground({
								values,
								min,
								max,
								colors,
							}),
							borderRadius: '1em',
							// boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px',
						}}
					>
						{children}
					</div>
				)}
				renderThumb={({ props }) => (disabled && <></>) || (
					<div
						{...props}
						style={{
							...props.style,
							height: '42px', // leaving these big for easier dragging and to center the dots
							width: '42px',
							backgroundColor: 'transparent',
							cursor: 'grab',
							outline: 'none',
						}}
						className="range-slider-outer-thumb flex justify-center items-center"
					>
						<div
							style={{
								height: '1.25em',
								width: '1.25em',
								backgroundColor: '#dc5648',
								borderRadius: '100%',
								boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px',
							}}
						/>
					</div>
				)}
				disabled={disabled}
			/>
		</div>
	);
}

export default RangeSlider;
