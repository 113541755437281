import { createContext, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Modal, Button } from '@/lib/ReactRainbow';

export const AppContext = createContext({
	appState: { authData: null },
	setAppState: () => {},
});

export const AppProvider = ({ children }) => {
	const [appState, setState] = useState({
		authData: null,
	});
	const [modalOptions, setModalOptions] = useState({
		isOpen: false,
		children: null,
	});

	const [confirmationOptions, setConfirmationOptions] = useState({
		isOpen: false,
	});

	const displayError = (error, showErrorTitle) => {
		let errorMsg = error?.message || error?.error || error;
		if (Array.isArray(error)) {
			errorMsg = (
				<>
					The following errors occurred:
					<br />
					<ul>
						{error.map((el) => (
							<li>
								{el.message || el.error || el.toString()}
							</li>
						))}
					</ul>
				</>
			);
		} else if (typeof errorMsg === 'object') {
			// eslint-disable-next-line no-console
			console.log(error);
			errorMsg = 'An unknown error occurred.';
		}
		const ErrorComponent = (
			<div className={`px-3 ${showErrorTitle ? 'pb-5' : 'py-5'}`}>
				{errorMsg}
			</div>
		);
		setModalOptions((current) => ({
			...current,
			isOpen: true,
			children: ErrorComponent,
			title: showErrorTitle ? 'Error' : null,
		}));
	};

	// eslint-disable-next-line no-console
	const displayConfirmation = (handleConfirmation = (didConfirm) => { console.log(didConfirm); }, options = {}) => {
		const {
			yes = 'Yes',
			no = 'No',
			message = 'Are you sure?',
			title = (<div className="mt-4 text-xl text-center">Please Confirm</div>),
		} = options;
		const ConfirmationComponent = (
			<>
				<div className="px-3 mt-4 mb-12 text-2xl text-center">
					{message}
				</div>
				<div className="flex flex-row justify-between mb-2 px-3">
					<Button
						size="medium"
						className="w-2/6"
						variant="destructive"
						onClick={() => {
							handleConfirmation(false);
							setConfirmationOptions({ isOpen: false });
						}}
						label={no}
					/>
					<Button
						size="medium"
						className="w-2/6"
						variant="success"
						label={yes}
						onClick={() => {
							handleConfirmation(true);
							setConfirmationOptions({ isOpen: false });
						}}
					/>
				</div>
			</>
		);
		setConfirmationOptions((current) => ({
			...current,
			isOpen: true,
			children: ConfirmationComponent,
			title,
		}));
	};

	function Confirmation({
		message,
		yes = 'Yes',
		no = 'No',
	}) {
		return (
			<Modal
				{...confirmationOptions}
				onRequestClose={() => setConfirmationOptions({ isOpen: false })}
			/>
		);
	}

	const update = (updates) => {
		setState((prevState) => ({ ...prevState, ...updates }));
	};

	// accepts a string of item's key name, or array of strings.
	const getCustomPreferences = async (items) => {
		try {
			const { authData } = appState;
			if (!authData?.signInUserSession) {
				return Promise.reject(new Error('User must be signed in.'));
			}
			const { attributes } = authData;
			let requestedData;
			if (Array.isArray(items)) {
				requestedData = items.map((itemName) => ({
					[itemName]: attributes[itemName],
				}));
			} else {
				requestedData = attributes[items];
			}
			return requestedData;
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const setCustomPreferences = async (newPreferencesObject) => {
		try {
			const { authData } = appState;
			if (!authData?.signInUserSession) {
				return Promise.reject(new Error('User must be signed in.'));
			}
			await Auth.updateUserAttributes(authData, newPreferencesObject);
			const newAttributes = { ...authData.attributes, ...newPreferencesObject };
			// spread is too shallow here and doesn't copy object methods over.
			const newAuthData = Object.assign(authData, {
				attributes: newAttributes,
			});
			// instead of making a new network call, let's store our new prefs locally.
			update({ authData: newAuthData });
			return { ...newAttributes };
		} catch (error) {
			return Promise.reject(error);
		}
	};

	return (
		<AppContext.Provider
			value={{
				appState,
				setAppState: update,
				displayError,
				displayConfirmation,
				getCustomPreferences,
				setCustomPreferences,
			}}
		>
			<Modal
				{...modalOptions}
				onRequestClose={() => setModalOptions({ isOpen: false })}
			/>
			<Confirmation
				{...confirmationOptions}
			/>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useAppContext must be used within a AppProvider');
	}
	const {
		appState,
		setAppState,
		displayError,
		displayConfirmation,
		getCustomPreferences,
		setCustomPreferences,
	} = context;
	return {
		appState,
		setAppState,
		displayError,
		displayConfirmation,
		getCustomPreferences,
		setCustomPreferences,
	};
};
