import moment from 'moment-timezone';

const DATE_DISPLAY_OPTIONS = {
	lg: (value) => moment(Number(value)).format('MMM Do YYYY, h:mm:ss a'),
	md: (value) => moment(Number(value)).format('MM/DD/YY, h:mm:ss a'),
	sm: (value) => moment(Number(value)).fromNow(),
};

const DATE_OPTIONS = [
	{ name: 'lasthour', label: 'Last Hour' },
	{ name: 'today', label: 'Today' },
	{ name: '48hours', label: 'Last 48 Hours' },
	{ name: 'lastweek', label: 'Last Week' },
	{ name: 'last30', label: 'Last 30 Days' },
	{ name: 'specificdate', label: 'Specific Date' },
	{ name: 'range', label: 'Custom Range' },
];

const INITIAL_VENUE = { name: 'all', label: 'All Venues', value: 'all' };

const ROWS_PER_PAGE = [
	{ name: '10', label: '10', value: 10 },
	{ name: '20', label: '20', value: 20 },
	{ name: '50', label: '50', value: 50 },
	{ name: '100', label: '100', value: 100 },
];

const END_POINT_OPTIONS = [
	{ name: 'all', label: 'All Endpoints' },
	{ name: 'order', label: 'Order' },
	{ name: 'quote', label: 'Quote' },
	{ name: 'order/batch', label: 'Order/Batch' },
	{ name: 'quote/batch', label: 'Quote/Batch' },
];

const CORE_END_POINT_OPTIONS = [
	{ name: 'all', label: 'All Endpoints' },
	{ name: '/policy', label: 'Policy' },
	{ name: '/customer', label: 'Customer' },
	{ name: '/partner', label: 'Partner' },
	{ name: '/offering', label: 'Offering' },
	{ name: '/calls', label: 'Calls' },
];

const RESPONSE_OPTIONS = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'success',
		label: 'success',
	},
	{
		value: 'error',
		label: 'error',
	},
];

const THEMES = {
	buddy: {
		rainbow: {
			palette: {
				brand: '#dc5648',
				success: '#e2eba8',
				error: '#dc5648',
			},
		},
	},
};

const PARTNER_REPORTS = [
	{
		id: 'ordersPlaced',
		title: 'Orders Placed',
	},
	{
		id: 'salesDollars',
		title: 'Written Premium',
	},
	{
		id: 'marketingFeeEarned',
		title: 'Marketing Fee Earned',
	},
	{
		id: 'quoteToOrderRatio',
		title: 'Quote to Order Ratio',
	},
	{
		id: 'leadVenueBySales',
		title: 'Lead Venue by Sales',
	},
];

const DEMO_BUDDIES = [
	{
		id: 'casey@iambuddy.com',
		title: 'Casey Baum',
	},
	{
		id: 'charles@iambuddy.com',
		title: 'Charles Merritt',
	},
	{
		id: 'nick@iambuddy.com',
		title: 'Nick Green',
	},
	{
		id: 'vogey@iambuddy.com',
		title: 'David Vogeleer',
	},
	{
		id: 'jpaul@iambuddy.com',
		title: 'James Paul',
	},
	{
		id: 'cezar@iambuddy.com',
		title: 'Cezar Carvalhaes',
	},
].sort((a, b) => (a.title > b.title ? 1 : -1));

const CHIP_VARIANTS = {
	DEFAULT: 'base',
	PUBLISHED: 'success',
	SUBMITTED: 'warning',
	CREATED: 'base',
};

const PRODUCT_ACTIONS = {
	VIEW_RAW: 'VIEWRAW',
	VIEW_SUMMARY: 'VIEWSUMMARY',
	DOWNLOAD: 'DOWNLOAD',
	CLONE: 'CLONE',
	PUBLISH: 'PUBLISH',
	UNSUBMIT: 'UNSIBMIT',
	ACTIVATE: 'ACTIVATE',
	EDIT: 'EDIT',
	SUBMIT: 'SUBMIT',
	DOWNLOAD_SAMPLE: 'DOWNLOAD_SAMPLE',
	DOWNLOAD_SUMMARY_PDF: 'DOWNLOAD_SUMMARY_PDF',
	DOWNLOAD_SUMMARY_MD: 'DOWNLOAD_SUMMARY_MD',
};

const HTTP_METHOD_OPTIONS = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'GET',
		label: 'GET',
	},
	{
		value: 'POST',
		label: 'POST',
	},
	{
		value: 'PUT',
		label: 'PUT',
	},
];

const HTTP_METHOD_COLORS = {
	GET: 'text-green-400',
	POST: 'text-blue-400',
	PUT: 'text-yellow-500',
	DELETE: 'text-red-500',
	ALL: 'text-black',
};

const TECHNOLOGY_OPTIONS = [
	{
		label: 'All',
		name: 'ALL',
	},
	{
		label: 'Site',
		name: 'SITE',
	},
	{
		label: 'Admin',
		name: 'AMP',
	},
	{
		label: 'Partner Page',
		name: 'PARTNER_PAGE',
	},
	{
		label: 'Portal',
		name: 'PORTAL',
	},
	{
		label: 'iFrame',
		name: 'IFRAME',
	},
	{
		label: 'API',
		name: 'API',
	},
];

const OFFERING_TYPE_OPTIONS = [
	{
		label: 'ALL',
		value: 'ALL',
	},
	{
		label: 'Accident',
		value: 'ACCIDENT',
	},
	{
		label: 'Cancellation',
		value: 'TICKET_CANCELLATION',
	},
];

export {
	DATE_DISPLAY_OPTIONS,
	DATE_OPTIONS,
	INITIAL_VENUE,
	ROWS_PER_PAGE,
	END_POINT_OPTIONS,
	CORE_END_POINT_OPTIONS,
	RESPONSE_OPTIONS,
	THEMES,
	PARTNER_REPORTS,
	DEMO_BUDDIES,
	CHIP_VARIANTS,
	PRODUCT_ACTIONS,
	HTTP_METHOD_OPTIONS,
	HTTP_METHOD_COLORS,
	TECHNOLOGY_OPTIONS,
	OFFERING_TYPE_OPTIONS,
};
