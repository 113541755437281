import React, { useState } from 'react';
import startCase from 'lodash/startCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTable,
	faChartLine,
	faChartPie,
	faChartBar,
	faChartArea,
} from '@fortawesome/free-solid-svg-icons';
import { Numbers } from '@buddy-technology/buddy_helpers';
import ErrorBoundary from '@/components/ErrorBoundary';
import {
	Tabset,
	Tab,
	TableWithBrowserPagination,
	Column,
	Chart,
	Dataset,
} from '@/lib/ReactRainbow';

const rowCount = 8;
const colors = [
	'#1de9b6',
	'#ffcc00',
	'#1ad1a3',
	'#ff6837',
	'#663398',
	'#061c3f',
];
const formatters = {
	orderAmount: (data) => Numbers.toUSD(data.value),
	marketingFee: (data) => Numbers.toUSD(data.value),
	venue: (data) => startCase(data.value),
};

const ShowTable = ({ shouldShow, data }) => {
	if (!shouldShow) {
		return null;
	}

	if (!data.length) {
		return <></>;
	}

	const headers = Object.keys(data[0]).map((el) => {
		if (el === 'id') {
			return null;
		}
		return (
			<Column
				key={`header-${el}`}
				header={startCase(el)}
				field={el}
				component={formatters[el]}
			/>
		);
	});

	return (
		<TableWithBrowserPagination
			paginationAlignment="right"
			pageSize={rowCount}
			data={data}
			keyField="id"
		>
			{headers}
		</TableWithBrowserPagination>
	);
};

const ShowChart = ({ shouldShow, data, chartID }) => {
	if (!shouldShow) {
		return null;
	}

	if (!data?.length) {
		return <></>;
	}

	const chartToDisplay = data.find((el) => el.id === chartID);
	return (
		<Chart
			labels={chartToDisplay.labels.map(startCase)}
			type={chartToDisplay.chartType || 'line'}
			showLegend
			legendPosition="top"
		>
			{Object.keys(chartToDisplay.datasets).map((key, i) => (
				<Dataset
					title={startCase(key)}
					key={`dataset-${key}`}
					values={chartToDisplay.datasets[key]}
					backgroundColor={
						chartToDisplay?.chartType === 'pie' ? colors : colors[i]
					}
					borderColor={chartToDisplay?.chartType === 'pie' ? colors : colors[i]}
				/>
			))}
		</Chart>
	);
};

const DataLabel = (
	<span>
		<FontAwesomeIcon icon={faTable} className="rainbow-m-right_medium mr-1" />
		DATA
	</span>
);

const RankingLabel = (
	<span>
		<FontAwesomeIcon icon={faTable} className="rainbow-m-right_medium mr-1" />
		RANKED DATA
	</span>
);
const chartIcon = (chartType) => {
	switch (chartType?.toLowerCase()) {
	case 'pie':
		return faChartPie;
	case 'bar':
		return faChartBar;
	case 'area':
		return faChartArea;
	default:
		return faChartLine;
	}
};
const ChartLabel = (chartInfo) => (
	<span>
		<FontAwesomeIcon
			icon={chartIcon(chartInfo?.chartType)}
			className="rainbow-m-right_medium mr-1"
		/>
		{(chartInfo?.tabTitle || 'CHART').toUpperCase()}
	</span>
);

const DataDisplay = ({
	rawData, charts, rankedData, canTakePic = () => {},
}) => {
	const [selectedTab, setSelectedTab] = useState('data');

	const switchToTab = (tab) => {
		canTakePic(tab !== 'data');
		setSelectedTab(tab);
	};

	const chartTabs = charts.map((chartData, i) => (
		<Tab
			key={chartData?.id || `chartTab-${i}`}
			name={chartData?.id || `chartTab-${i}`}
			id={chartData?.id || `chartTab-${i}`}
			ariaControls={chartData?.id || `chartTab-${i}`}
			label={ChartLabel(chartData)}
		/>
	));
	return (
		<ErrorBoundary>
			<div>
				<ErrorBoundary>
					<Tabset
						id="tableSet"
						onSelect={(event, selected) => switchToTab(selected)}
						activeTabName={selectedTab}
						className="pt-5 bg-gray-100"
					>
						<Tab
							label={DataLabel}
							name="data"
							id="data"
							ariaControls="dataTab"
						/>
						{rankedData && (
							<Tab
								label={RankingLabel}
								name="ranking"
								id="ranking"
								ariaControls="dataTab"
							/>
						) }
						<>{chartTabs}</>
					</Tabset>
				</ErrorBoundary>
				<div id="graph-display" className="flex bg-white w-full">
					<ErrorBoundary>
						<ShowTable
							shouldShow={selectedTab === 'data'}
							data={rawData || []}
						/>
					</ErrorBoundary>
					<ErrorBoundary>
						<ShowTable
							shouldShow={selectedTab === 'ranking'}
							data={rankedData || []}
							sortedBy="orders"
							sortDirection="desc"
						/>
					</ErrorBoundary>
					<ErrorBoundary>
						<ShowChart
							shouldShow={selectedTab !== 'data' && selectedTab !== 'ranking'}
							chartID={selectedTab}
							data={charts}
						/>
					</ErrorBoundary>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default DataDisplay;
