import React from 'react';

function Label({
	label,
	isRequired = false,
	className = '',
	style = {},
	htmlFor = null,
}) {
	return (
		<label
			className={`text-sm text-left ${className}`}
			style={style}
			htmlFor={htmlFor}
		>
			{isRequired && <span className="font-bold text-buddy-red mr-1">*</span>}
			{label}
		</label>
	);
}

export default Label;
