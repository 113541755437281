import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
const ProductContext = createContext(null);

const useProductContext = () => {
	const context = useContext(ProductContext);
	if (!context) {
		throw new Error('useProductData must be used within a ProductProvider');
	}
	const [productDetails, setProductDetails] = context;
	return [productDetails, setProductDetails];
};

// enforce display name for DEV tools
ProductContext.displayName = 'ProductContext';

const ProductProvider = ({ children }) => {
	const [productDetails, setProductDetails] = useState(null);
	const store = [productDetails, setProductDetails];

	return <ProductContext.Provider value={store}>{children}</ProductContext.Provider>;
};

export {
	ProductContext,
	ProductProvider,
	useProductContext,
};
