import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import normalizeTheme from 'react-rainbow-components/styles/helpers/normalizeTheme';

const buddyTheme = {
	rainbow: {
		palette: {
			brand: '#dc5648',
			success: '#c6d94b',
			error: '#dc5648',
			warning: '#ffcf33',
		},
	},
};

export const secondaryTheme = {
	rainbow: {
		palette: {
			brand: '#33b7bf',
			success: '#c6d94b',
			error: '#dc5648',
			warning: '#ffcf33',
		},
	},
};

function Application({
	children, className, style, theme = buddyTheme,
}) {
	const [normalizedTheme, setTheme] = useState(() => normalizeTheme(theme));

	useEffect(() => {
		setTheme(normalizeTheme(theme));
	}, [theme]);

	return (
		<ThemeProvider theme={normalizedTheme}>
			<div className={className} style={style}>
				{children}
			</div>
		</ThemeProvider>
	);
}

export default Application;
