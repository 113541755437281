import React, { useEffect, useState, useRef } from 'react';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlus,
	faList,
	faGripVertical,
	faTicketAlt,
	faBandAid,
} from '@fortawesome/free-solid-svg-icons';

import { useAppContext, useApiContext, useProductContext } from '@/providers';
import {
	RadioButtonGroup,
	ButtonMenu,
	MenuItem,
} from '@/lib/ReactRainbow';
import {
	ErrorBoundary,
	ProductList,
} from '@/components';

import { SectionHeader } from '@/components/UI';
import { SWITCH } from '@/components/utils';

import {
	TicketCancellationCard,
} from '@/components/ProductCards';

// todo: replace default with a new Accident card?
const CARDS = {
	DEFAULT: <TicketCancellationCard />,
	TICKET_CANCELLATION: <TicketCancellationCard />,
};

const VIEW_OPTIONS = [
	{ value: 'card', label: <FontAwesomeIcon icon={faGripVertical} /> },
	{ value: 'list', label: <FontAwesomeIcon icon={faList} /> },
];

const PRODUCT_TYPES = {
	ACCIDENT: {
		label: 'Accident',
		icon: <FontAwesomeIcon icon={faBandAid} />,
		path: 'accident',
	},
	TICKET_CANCELLATION: {
		label: 'Ticket Cancellation',
		icon: <FontAwesomeIcon icon={faTicketAlt} />,
		path: 'ticket-cancellation',
	},
};

function ProductsHome() {
	const { displayError } = useAppContext();
	const { offeringAPI } = useApiContext();
	const [, setProductDetails] = useProductContext();
	const [products, setProducts] = useState([]);
	const [view, setView] = useState(VIEW_OPTIONS[0].value);
	const loadOfferingsRef = useRef();

	const loadData = async () => {
		if (loadOfferingsRef.current) {
			loadOfferingsRef.current.abort();
		}
		try {
			// grab all the offerings
			const loadOfferings = await offeringAPI.list();
			loadOfferingsRef.current = loadOfferings;
			const offerings = await loadOfferings.send();
			setProducts(offerings);
			loadOfferingsRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error.message);
			}
			loadOfferingsRef.current = null;
		}
	};

	useEffect(() => {
		loadData();
		return () => {
			if (loadOfferingsRef.current) {
				loadOfferingsRef.current.abort();
			}
		};
	}, []);

	const productSelectHandler = (product) => {
		const type = product.offeringType;
		setProductDetails(product);
		navigate(`/products/builder/${PRODUCT_TYPES[type]?.path}/event-details/${product.id}`);
	};

	const startNewProductHandler = (type) => {
		setProductDetails(null);
		navigate(`/products/builder/${PRODUCT_TYPES[type]?.path}`);
	};

	return (
		<ErrorBoundary>
			<div className="flex flex-col pt-5">
				<SectionHeader
					className="ml-4 mb-5"
					title={(
						<>
							<div>Products</div>
							<RadioButtonGroup
								id="view"
								className="mt-2"
								options={VIEW_OPTIONS}
								value={view}
								onChange={({ target }) => setView(target.value)}
								size="small"
								variant="brand"
							/>
						</>
					)}
					actions={(
						<ButtonMenu
							className="mr-3"
							menuSize="small"
							icon={<FontAwesomeIcon icon={faPlus} className="mr-2 text-buddy-red" />}
							label={<span className="text-buddy-red">Create New</span>}
							iconPosition="left"
						>
							{Object.keys(PRODUCT_TYPES).map((key) => {
								const product = PRODUCT_TYPES[key];
								return (
									<MenuItem key={`option-${key}`} icon={product.icon} onClick={() => startNewProductHandler(key)} label={product.label} />
								);
							})}
						</ButtonMenu>
					)}
				/>
				{/* list or card view */}
				<SWITCH match={view}>
					<div case="card" className="flex flex-row flex-wrap w-full justify-between">
						{products.map((card) => React.cloneElement(CARDS[card.offeringType || 'DEFAULT'], { product: card, key: `product-${card.id}`, onSelectProduct: productSelectHandler }))}
					</div>
					<ProductList products={products} case="list" onSelectProduct={productSelectHandler} />
				</SWITCH>
			</div>
		</ErrorBoundary>
	);
}

export default ProductsHome;
