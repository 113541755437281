import { Numbers, formatToStandardDate } from '@buddy-technology/buddy_helpers';

const getFakeTicketCancellationFormData = (productDetails) => {
	const endDate = new Date().setDate(new Date().getDate() + 5);
	const registrationStartDate = new Date().setDate(new Date().getDate() - 30);
	const registrationEndDate = new Date().setDate(new Date().getDate() + 1);

	return {
		pdf: 'ticket_cancellation>berkley_tc_policy_default.pdf',
		data: {
			policyID: 'IET V247123456701',
			order: 'o-12341234',
			customerName: 'Johnny Appleseed',
			customerAddress: '#1 Orchard Rd.',
			customerEmail: 'j.appleseed@iambuddy.com',
			orderDate: formatToStandardDate(new Date()),
			premiumTotal: Numbers.toUSD(101.01),
			endDate: formatToStandardDate(endDate),
			numberOfTickets: '1',
			totalTicketPrice: Numbers.toUSD(600),
			event: productDetails.event || 'Uknown Event',
			registrationStartDate: formatToStandardDate(registrationStartDate),
			registrationEndDate: formatToStandardDate(registrationEndDate),
		},
	};
};

export default getFakeTicketCancellationFormData;
