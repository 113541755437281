import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Button } from '@/lib/ReactRainbow';

const Fallback = () => (
	<div className="w-full py-8 px-3 flex flex-col justify-center items-center">
    Uh oh, an error occurred here. Don't worry, our tech team is on it. In the
    meantime, try reloading the page.
		<br />
    If that doesn't fix the issue and you need further assistance, please
    contact us.
		<Button
			onClick={() => window.location.reload()}
			style={{ display: 'block' }}
			className="mx-auto my-4"
			label="Reload"
		/>
	</div>
);
const ErrorBoundary = ({ children, fallback = Fallback }) => (
	<SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
);

export default ErrorBoundary;
