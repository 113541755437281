import React, { useState } from 'react';
import AceEditor from 'react-ace';
import FileSaver from 'file-saver';
import { snakeCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRedoAlt,
	faEllipsisV,
	faEllipsisH,
	faFilter,
	faFileDownload,
} from '@fortawesome/free-solid-svg-icons';

import {
	productToMarkdown, getFakeTicketCancellationFormData,
	CHIP_VARIANTS,
	PRODUCT_ACTIONS,
} from '@/utils';
import { useApiContext, useAppContext } from '@/providers';
import {
	Card,
	Button,
	ButtonIcon,
	Chip,
	ButtonMenu,
	Modal,
	Table,
	Column,
} from '@/lib/ReactRainbow';

import ProductActions from '@/components/ProductActions';
import { SWITCH } from '@/components/utils';

import { Summary } from '@/components/builder/TC';

import {
	DownloadButton,
} from '@/components/UI';

const formatDate = (date) => {
	const year = date.getFullYear();
	const month = (1 + date.getMonth()).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	return `${month}/${day}/${year}`;
};

const MODAL_TYPES = {
	RAW: 'RAW',
	SUMMARY: 'SUMMARY',
};

// this is for the downloads menu button
const DOWNLOADS = [
	{ label: 'Sample File', value: PRODUCT_ACTIONS.DOWNLOAD_SAMPLE, fileType: 'pdf' },
	{ label: 'Summary PDF', value: PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_PDF, fileType: 'pdf' },
	{ label: 'Summary Markdown', value: PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_MD, fileType: 'md' },
];

function ProductList({ products }) {
	const [modalIsOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState(MODAL_TYPES.RAW);
	const [product, setProduct] = useState({});

	const { pdfAPI } = useApiContext();
	const { displayError } = useAppContext();

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openModal = (type) => {
		setModalType(type);
		setIsModalOpen(true);
	};

	// eslint-disable-next-line no-unused-vars
	const onDownloadSelected = async (productID, id) => {
		const selectedProduct = products.find((productEl) => productEl.id === id); 	// eslint-disable-line no-unused-vars
		switch (id) {
		case PRODUCT_ACTIONS.DOWNLOAD_SAMPLE:
			try {
				const info = getFakeTicketCancellationFormData(product);
				const sendable = await pdfAPI.fill(info.pdf, info.data);
				await sendable.send();
			} catch (error) {
				displayError(error);
			}
			break;
		case PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_MD:
			try {
				const markdown = productToMarkdown(product);
				const filename = snakeCase(product?.event || 'event');
				const file = new File([markdown], `${filename}.md`, {
					type: 'text/markdown;charset=utf-8',
				});
				FileSaver.saveAs(file);
			} catch (error) {
				displayError(error);
			}
			break;
		case PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_PDF:
			try {
				const markdown = productToMarkdown(product);
				const filename = snakeCase(product?.event || 'event');
				const sendable = await pdfAPI.fromMarkdown(markdown, `${filename}.pdf`);
				await sendable.send();
			} catch (error) {
				displayError(error);
			}
			break;
		default:
			// eslint-disable-next-line no-console
			console.log(id);
		}
	};

	const onActionSelected = (id, action) => {
		const selectedProduct = products.find((productEl) => productEl.id === id);
		setProduct(selectedProduct);
		switch (action) {
		case PRODUCT_ACTIONS.VIEW_RAW:
			openModal(MODAL_TYPES.RAW);
			break;
		case PRODUCT_ACTIONS.VIEW_SUMMARY:
			openModal(MODAL_TYPES.SUMMARY);
			break;
		default:
			// eslint-disable-next-line no-console
			console.log(id, action);
		}
	};
	return (
		<div className="flex justify-center items-center w-full">
			<Card
				className="mx-3"
				icon={(
					<Button
						variant="neutral"
						title="filter api calls"
					// onClick={() => handleJust(null)}
					// disabled={isLoading}
					>
						<FontAwesomeIcon
							icon={faFilter}
							className="rainbow-m-right_medium mr-1"
						/>
										Filter Products
					</Button>
				)}
				actions={(
					<ButtonIcon
						assistiveText="refresh data button"
						title="Refresh the data"
						type="button"
						icon={<FontAwesomeIcon icon={faRedoAlt} />}
						variant="border"
						size="medium"
						// disabled={isLoading}
						className=""
					// onClick={handleRefresh}
					/>
				)}
			>
				<Table
					data={products}
					keyField="id"
					emptyTitle="Whoops!"
					emptyDescription="You should have at least 1 product to show. Ping Vogey or Cezar and let them know."
					showRowNumberColumn
				>
					<Column
						header="Event"
						field="event"
					/>
					<Column
						header="Type"
						field="eventType"
						component={({ value }) => value.toUpperCase()}
					/>
					<Column
						header="Status"
						field="status"
						component={({ value }) => (
							<Chip
								className="h-6"
								variant={CHIP_VARIANTS[value]}
								label={(
									<div className="flex items-center justify-between">

										<div>{value}</div>
									</div>
								)}
							/>
						)}
					/>
					<Column
						header="Start Date"
						field="eventStartDate"
						component={({ value }) => formatDate(value)}
					/>
					<Column
						header="End Date"
						field="eventEndDate"
						component={({ value }) => formatDate(value)}
					/>
					<Column
						header={<FontAwesomeIcon size="2x" icon={faFileDownload} />}
						width={60}
						field="eventEndDate"
						component={({ value }) => (
							<DownloadButton
								label=""
								menuOptions={DOWNLOADS}
								// onMenuSelect={onDownloadSelected}
								icon={<FontAwesomeIcon icon={faFileDownload} />}
							/>
						)}
					/>
					<Column
						header={<FontAwesomeIcon size="2x" icon={faEllipsisH} />}
						field="status"
						width={60}
						component={({ row }) => (
							<div className="h-12 py-1">
								<ButtonMenu
									className="my-auto"
									menuSize="x-small"
									icon={<FontAwesomeIcon icon={faEllipsisV} />}
								>
									<ProductActions
										status={row.status}
										id={row.id}
										onActionSelected={onActionSelected}
									/>
								</ButtonMenu>
							</div>
						)}
					/>
				</Table>
			</Card>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				title={product.event || product.name}
				size={modalType === MODAL_TYPES.SUMMARY ? 'large' : 'small'}
			>
				<SWITCH match={modalType}>
					<AceEditor
						mode="json"
						theme="github"
						className="w-full"
						name="ACE"
						wrapEnabled
						editorProps={{ $blockScrolling: true }}
						value={JSON.stringify(product, null, 2)}
						readOnly
						style={{ width: '100%' }}
						case={MODAL_TYPES.RAW}
					/>
					<Summary productDetails={product} productID={`/${product.id}`} case={MODAL_TYPES.SUMMARY} />
				</SWITCH>
			</Modal>
		</div>

	);
}

export default ProductList;
