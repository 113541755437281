const ION = {
	id: 'AAC_AD_AND_D',
	type: 'OFFERING',
	description: 'The AD&D product designed specifically for AAC members.',
	status: 'PUBLISHED',
	ionVersion: 1,
	ionType: 'OFFERING',
	issuanceType: 'GROUP',
	issuanceBasis: 'VOLUNTARY',
	insuranceLine: 'AD_AND_D',
	variables: {
		allStates: [],
		approvedStateOptions: [
			'AL', 'AK', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'DC', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KY', 'MA', 'MI', 'MN', 'MS', 'MT', 'NE', 'NJ', 'NM', 'NY', 'ND', 'OH', 'OK', 'OR', 'PA', 'SC', 'SD', 'TN', 'TX', 'VA', 'WV', 'WI', 'WY',
		],
		approvedStateLabels: [
			{ value: 'AL', label: 'AL' },
			{ value: 'AK', label: 'AK' },
			{ value: 'AR', label: 'AR' },
			{ value: 'AZ', label: 'AZ' },
			{ value: 'CA', label: 'CA' },
			{ value: 'CO', label: 'CO' },
			{ value: 'CT', label: 'CT' },
			{ value: 'DC', label: 'DC' },
			{ value: 'DE', label: 'DE' },
			{ value: 'GA', label: 'GA' },
			{ value: 'HI', label: 'HI' },
			{ value: 'IA', label: 'IA' },
			{ value: 'ID', label: 'ID' },
			{ value: 'IL', label: 'IL' },
			{ value: 'IN', label: 'IN' },
			{ value: 'KY', label: 'KY' },
			{ value: 'MA', label: 'MA' },
			{ value: 'MI', label: 'MI' },
			{ value: 'MS', label: 'MS' },
			{ value: 'MT', label: 'MT' },
			{ value: 'ND', label: 'ND' },
			{ value: 'NE', label: 'NE' },
			{ value: 'NJ', label: 'NJ' },
			{ value: 'NM', label: 'NM' },
			{ value: 'NY', label: 'NY' },
			{ value: 'OH', label: 'OH' },
			{ value: 'OK', label: 'OK' },
			{ value: 'OR', label: 'OR' },
			{ value: 'PA', label: 'PA' },
			{ value: 'SC', label: 'SC' },
			{ value: 'SD', label: 'SD' },
			{ value: 'TN', label: 'TN' },
			{ value: 'TX', label: 'TX' },
			{ value: 'VA', label: 'VA' },
			{ value: 'WV', label: 'WV' },
			{ value: 'WI', label: 'WI' },
			{ value: 'WY', label: 'WY' },
		],
		beneficiaryRelationshipOptions: [
			'SPOUSE',
			'MOTHER',
			'FATHER',
			'SISTER',
			'BROTHER',
			'CHILD',
			'ESTATE',
			'OTHER',
		],
		beneficiaryRelationshipLabels: [
			{ value: 'SPOUSE', label: 'Spouse' },
			{ value: 'MOTHER', label: 'Mother' },
			{ value: 'FATHER', label: 'Father' },
			{ value: 'SISTER', label: 'Sister' },
			{ value: 'BROTHER', label: 'Brother' },
			{ value: 'CHILD', label: 'Child' },
			{ value: 'ESTATE', label: 'Estate' },
			{ value: 'OTHER', label: 'Other' },
		],
		dependentRelationshipOptions: [
			'SPOUSE',
			'CHILD',
		],
		dependentRelationshipLabels: [
			{ value: 'SPOUSE', label: 'Souse' },
			{ value: 'CHILD', label: 'Child' },
		],
		whoOptions: [
			'ME',
			'ME_AND_SPOUSE',
			'ME_AND_ONE_CHILD',
			'FAMILY',
		],
		whoLabels: [
			{ value: 'ME', label: 'Just me' },
			{ value: 'ME_AND_SPOUSE', label: 'Me and my spouse' },
			{ value: 'ME_AND_ONE_CHILD', label: 'Me and one kid' },
			{ value: 'FAMILY', label: 'My family' },
		],
		paymentLabels: [
			{ value: 'SINGLE_PAYMENT', label: 'Make 1 single payment' },
			{ value: 'QUARTERLY_PAYMENTS', label: 'Make four equal payments over the year' },
		],
	},
	application: {
		views: [
			{
				id: 'offer',
				type: 'OFFER',
				title: 'Accidental Death and Dismemberment',
				subTitle: 'Get up to $500,000 in accidental death & dismemberment insurance— without the underwriting questions or price hikes because of your outdoor hobbies.',
				order: 0,
			},
			{
				id: 'quote',
				type: 'GROUP',
				title: 'Quote',
				subTitle: 'Please select who you would like to buy a policy for and the total benefit amount.',
				order: 1,
			},
			{
				id: 'customerName',
				type: 'GROUP',
				title: 'Your Name',
				subTitle: 'Please use your full legal name',
				order: 2,
			},
			{
				id: 'customerInfo',
				type: 'GROUP',
				title: 'Your Info',
				order: 3,
			},
			{
				id: 'address',
				type: 'GROUP',
				title: 'Address',
				order: 4,
			},
			{
				id: 'beneficiaries',
				type: 'GROUP',
				title: 'Beneficiaries',
				order: 5,
			},
			{
				id: 'dependents',
				type: 'GROUP',
				title: 'Dependents',
				order: 6,
				isHidden: {
					$calculate: [
						{
							$project: {
								isHidden: {
									$cond: {
										if: {
											$eq: [
												'$policy.who',
												'ME',
											],
										},
										then: true,
										else: false,
									},
								},
							},
						},
					],
				},
			},
			{
				id: 'checkout',
				type: 'CHECKOUT',
				title: 'Checkout',
				order: 7,
			},
		],
		fields: [
			{
				id: 'customer',
				type: 'FIELDS',
				isRequired: true,
				uiDisplay: {
					label: 'Customer Information',
					order: 0,
				},
				value: [
					{
						id: 'firstName',
						type: 'STRING',
						description: 'Customer\'s first name.',
						rules: {
							minLength: 2,
						},
						uiDisplay: {
							placeholder: 'Bruce',
							label: 'First Name',
							order: 0,
							element: 'INPUT',
							autocomplete: 'given-name',
							view: 'customerName',
						},
						isRequired: true,
					},
					{
						id: 'middleInitial',
						type: 'STRING',
						description: 'Customer\'s middle initial.',
						defaultValue: '',
						rules: {
							length: 1,
							upperCase: true,
							allowNull: true,
							allowEmptyString: true,
						},
						uiDisplay: {
							placeholder: 'M',
							label: 'Middle Initial',
							element: 'INPUT',
							order: 1,
							view: 'customerName',
						},
						isRequired: false,
					},
					{
						id: 'lastName',
						type: 'STRING',
						description: 'Customer\'s last name.',
						rules: {
							minLength: 2,
						},
						uiDisplay: {
							placeholder: 'Wayne',
							label: 'Last Name',
							order: 2,
							element: 'INPUT',
							autocomplete: 'family-name',
							view: 'customerName',
						},
						isRequired: true,
					},
					{
						id: 'name',
						type: 'STRING',
						description: 'Customer\'s full name.',
						uiDisplay: {
							isHidden: true,
							label: 'Customer Full Name',
						},
					// value: {
					// 	'$calculate': '<do the calculation here>',
					// },
					},
					{
						id: 'email',
						type: 'STRING',
						description: 'Customer\'s email address.',
						rules: {
							regex: {
								value: '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
								error: '{{#label}} needs to be a valid email address. Value passed was "{{#value}}".',
							},
						},
						uiDisplay: {
							placeholder: 'bruce@wayneenterprises.net',
							label: 'Email Address',
							order: 3,
							element: 'INPUT',
							autocomplete: 'email',
							view: 'customerInfo',
						},
						isRequired: true,
					},
					{
						id: 'dob',
						type: 'DATE',
						description: 'Your full date of birth.',
						rules: {
							format: 'MM/DD/YYYY',
							minAge: 18,
							raw: true,
						},
						uiDisplay: {
							placeholder: '02/14/2000',
							label: 'Date of Birth',
							order: 4,
							element: 'DATE_PICKER',
							autocomplete: 'bday',
							view: 'customerInfo',
						},
						isRequired: true,
					},
					{
						id: 'age',
						type: 'NUMBER',
						rules: {
							allowEmptyString: true,
							allowNull: true,
						},
						uiDisplay: {
							element: 'INPUT',
							isHidden: true,
							label: 'Age',
						},
						// value: {
						// 	$calculate: '<do calculation>',
						// },
					},
					{
						id: 'address',
						type: 'FIELDS',
						isRequired: true,
						uiDisplay: {
							label: 'Address',
							order: 6,
						},
						value: [
							{
								id: 'line1',
								type: 'STRING',
								description: 'The first line of customer\'s home address.',
								rules: {
									minLength: 2,
								},
								uiDisplay: {
									placeholder: '1007 Mountain Drive',
									label: 'Resident Address',
									order: 0,
									element: 'INPUT',
									autocomplete: 'address-line1',
									view: 'address',
								},
								isRequired: true,
							},
							{
								id: 'line2',
								type: 'STRING',
								description: 'The second line of customer\'s home address.',
								rules: {
									minLength: 2,
									allowNull: true,
									allowEmptyString: true,
								},
								uiDisplay: {
									placeholder: 'Suite 380',
									label: 'Resident Address, Line 2',
									order: 1,
									element: 'INPUT',
									autocomplete: 'address-line2',
									view: 'address',
								},
								isRequired: false,
							},
							{
								id: 'city',
								type: 'STRING',
								description: 'The city of customer\'s home address.',
								rules: {
									minLength: 2,
								},
								uiDisplay: {
									placeholder: 'Gotham',
									label: 'Resident City',
									order: 2,
									element: 'INPUT',
									autocomplete: 'address-level2',
									view: 'address',
								},
								isRequired: true,
							},
							{
								id: 'state',
								type: 'STRING',
								description: 'The state of customer\'s home address.',
								rules: {
									upperCase: true,
									allowedValues: '$variables.approvedStateOptions',
								},
								uiDisplay: {
									placeholder: 'NY',
									label: 'Resident State',
									order: 3,
									element: 'SELECT',
									optionLabels: '$variables.approvedStateLabels',
									autocomplete: 'address-level1',
									view: 'address',
								},
								isRequired: true,
							},
							{
								id: 'postalCode',
								type: 'STRING',
								description: 'The postal code of customer\'s home address.',
								rules: {
									minLength: 5,
									regex: {
										value: '^[0-9]*$',
										error: '{{#label}} must be numeric characters only.',
									},
								},
								uiDisplay: {
									placeholder: '90210',
									label: 'Resident Postal Code',
									order: 4,
									element: 'INPUT',
									autocomplete: 'postal-code',
									view: 'address',
								},
								isRequired: true,
							},
						],
					},
					{
						id: 'phone',
						type: 'STRING',
						description: 'Customer\'s phone number.',
						rules: {
							length: 12,
							startsWith: '+1',
							regex: {
								value: '\\+1[0-9]{10}',
								error: '{{#label}} needs to be a valid phone number. Value passed was "{{#value}}".',
							},
						},
						uiDisplay: {
							placeholder: '555-867-5309',
							label: 'Phone Number',
							autocomplete: 'tel',
							order: 5,
							element: 'MASKED_INPUT',
							view: 'customerInfo',
							format: {
								type: 'MASK',
								mask: '{+1}(000)000-0000',
							},
						},
						isRequired: true,
					},
				],
			},
			{
				id: 'policy',
				type: 'FIELDS',
				isRequired: false,
				uiDisplay: {
					label: 'Policy Information',
					order: 1,
				},
				value: [
					{
						id: 'offering',
						type: 'STRING',
						value: 'AAC_AD_AND_D',
						rules: {
							value: 'AAC_AD_AND_D',
						},
						uiDisplay: {
							isHidden: true,
							label: 'Offering',
						},
						isRequired: true,
					},
					{
						id: 'who',
						type: 'STRING',
						description: 'Dependents relationship to customer.',
						rules: {
							allowedValues: '$variables.whoOptions',
						},
						uiDisplay: {
							label: 'Who',
							order: 0,
							element: 'SELECT',
							optionLabels: '$variables.whoLabels',
							view: 'quote',
						},
						isEndorsable: true,
						isRequired: true,
					},
					{
						id: 'benefitAmount',
						type: 'NUMBER',
						description: 'The total benefit the policy pays.',
						defaultValue: 50000.00,
						rules: {
							min: 50000,
							max: 500000,
							isInteger: true,
							multipleOf: 10000,
						},
						uiDisplay: {
							label: 'Benefit Amount',
							increment: 10000,
							order: 1,
							element: 'SLIDER',
							view: 'quote',
							format: {
								type: 'CURRENCY',
								currencyType: 'USD',
								precision: 2,
							},
						},
						isEndorsable: true,
						isRequired: true,
					},
					{
						id: 'dependents',
						type: 'ARRAY',
						description: 'List of customer\'s dependents.',
						isEndorsable: true,
						isRequired: false,
						defaultValue: [],
						uiDisplay: {
							label: 'Dependents',
							order: 6,
							view: 'dependents',
						},
						rules: {
							minLength: 0,
							maxLength: 6,
							items: {
								type: 'FIELDS',
								value: [
									{
										id: 'name',
										type: 'STRING',
										description: 'Name of the dependent.',
										rules: {
											minLength: 5,
											minWordCount: 2,
										},
										uiDisplay: {
											label: 'Name (Last/First/Middle)',
											order: 0,
											element: 'INPUT',
										},
										isRequired: true,
										isEndorsable: true,
									},
									{
										id: 'relationship',
										type: 'STRING',
										description: 'Dependents relationship to customer.',
										rules: {
											allowedValues: '$variables.dependentRelationshipOptions',
										},
										uiDisplay: {
											label: 'Relationship',
											order: 1,
											element: 'SELECT',
											optionLabels: '$variables.dependentRelationshipLabels',
										},
										isEndorsable: true,
										isRequired: true,
									},
									{
										id: 'dob',
										type: 'DATE',
										description: 'Dependent\'s date of birth.',
										rules: {
											format: 'MM/DD/YYYY',
											raw: true,
										},
										uiDisplay: {
											placeholder: '02/14/2000',
											label: 'Date of Birth',
											order: 2,
											element: 'DATE_PICKER',
										},
										isRequired: true,
									},
								],
							},
						},
					},
					{
						id: 'primaryBeneficiary',
						type: 'STRING',
						rules: {
							minWordCount: 2,
							allowNull: true,
							allowEmptyString: true,
						},
						uiDisplay: {
							label: 'Primary Beneficiary',
							order: 2,
							element: 'INPUT',
							view: 'beneficiaries',
						},
						isEndorsable: true,
						isRequired: false,
					},
					{
						id: 'primaryBeneficiaryRelationship',
						type: 'STRING',
						rules: {
							allowedValues: '$variables.beneficiaryRelationshipOptions',
						},
						uiDisplay: {
							label: 'Primary Beneficiary Relationship',
							order: 3,
							element: 'SELECT',
							optionLabels: '$variables.beneficiaryRelationshipLabels',
							view: 'beneficiaries',
						},
						isEndorsable: true,
						isRequired: false,
					},
					{
						id: 'contingentBeneficiary',
						type: 'STRING',
						rules: {
							minWordCount: 2,
							allowNull: true,
							allowEmptyString: true,
						},
						uiDisplay: {
							label: 'Contingent Beneficiary',
							order: 4,
							element: 'INPUT',
							view: 'beneficiaries',
						},
						isRequired: false,
					},
					{
						id: 'contingentBeneficiaryRelationship',
						type: 'STRING',
						rules: {
							allowedValues: '$variables.beneficiaryRelationshipOptions',
						},
						uiDisplay: {
							label: 'Contingent Beneficiary Relationship',
							order: 5,
							element: 'SELECT',
							optionLabels: '$variables.beneficiaryRelationshipLabels',
							view: 'beneficiaries',
						},
						isRequired: false,
					},
					{
						id: 'premiumTotal',
						type: 'NUMBER',
						rules: {
							min: 0.01,
						},
						uiDisplay: {
							isHidden: true,
							element: 'INPUT',
							label: 'Premium Total',
						},
						isRequired: true,
					},
					{
						id: 'startDate',
						type: 'DATE',
						description: 'The policy start date.',
						defaultValue: '$TODAY',
						isRequired: true,
						rules: {
							format: 'MM/DD/YYYY',
							min: '$TODAY',
							raw: true,
						},
						uiDisplay: {
							placeholder: '$TODAY',
							label: 'Start Date',
							isHidden: true,
							order: 7,
							element: 'DATE_PICKER',
						},
					},
					{
						id: 'endDate',
						type: 'DATE',
						isRequired: true,
						rules: {
							format: 'MM/DD/YYYY',
							greaterThan: '$TODAY',
							raw: true,
						},
						uiDisplay: {
							isHidden: true,
							label: 'End Date',
						},
						value: {
							$calculate: [
								{
									$addFields: {
										tempStartDate: {
											$dateFromString: {
												dateString: '$startDate',
												format: '%m/%d/%Y',
											},
										},
									},
								},
								{
									$addFields: {
										endDate: {
											$dateFromParts: {
												year: {
													$add: [
														{
															$year: '$tempStartDate',
														},
														1,
													],
												},
												month: {
													$month: '$tempStartDate',
												},
												day: {
													$subtract: [
														{
															$dayOfMonth: '$tempStartDate',
														},
														1,
													],
												},
											},
										},
									},
								},
								{
									$project: {
										endDate: {
											$dateToString: {
												date: '$endDate',
												format: '%m/%d/%Y',
											},
										},
									},
								},
							],
						},
					},
					{
						id: 'paymentOption',
						type: 'STRING',
						isRequired: true,
						defaultValue: 'SINGLE_PAYMENT',
						rules: {
							allowedValues: ['SINGLE_PAYMENT', 'QUARTERLY_PAYMENTS'],
							upperCase: true,
						},
						uiDisplay: {
							label: 'Payment Option',
							order: 8,
							element: 'SELECT',
							optionLabels: '$variables.paymentLabels',
							view: 'checkout',
						},
					},
					{
						id: 'meta',
						type: 'FIELDS',
						description: 'A key/value object for storing extra information about the order.',
						isRequired: false,
						defaultValue: null,
						rules: {
							allowNull: true,
						},
						uiDisplay: {
							isHidden: true,
						},
						// TODO: value below is a patch until we redefine this type
						value: [],
					},
					{
						id: 'partner',
						type: 'STRING',
						description: 'A unique identifier for the partner that order is coming from. (Should be either the partnerID, or the partner slug.)',
						isRequired: false,
						defaultValue: '',
						rules: {
							allowEmptyString: true,
							allowNull: true,
						},
						uiDisplay: {
							isHidden: true,
							label: 'Partner ID',
						},
					},
				],
			},
			{
				id: 'payment',
				type: 'FIELDS',
				isRequired: true,
				uiDisplay: {
					label: 'Payment',
					order: 2,
				},
				value: [
					{
						id: 'paymentType',
						type: 'STRING',
						description: 'What method of payment is being used?',
						isRequired: true,
						defaultValue: 'PAYMENT_TOKEN',
						rules: {
							upperCase: true,
							allowedValues: ['PAYMENT_TOKEN'],
						},
						uiDisplay: {
							isHidden: true,
							label: 'Payment Type',
						},
					},
					{
						id: 'paymentGateway',
						type: 'STRING',
						description: 'Which payment gateway is being used?',
						isRequired: true,
						defaultValue: 'STRIPE',
						rules: {
							upperCase: true,
							allowedValues: ['STRIPE'],
						},
						uiDisplay: {
							isHidden: true,
							label: 'Payment Gateway',
						},
					},
					{
						id: 'paymentToken',
						type: 'STRING',
						description: 'The hashed token of the credit card?',
						isRequired: true,
						rules: {
							startsWith: 'tok_',
						},
						uiDisplay: {
							isHidden: true,
							label: 'Payment Token',
						},
					},
				],
			},
		],
	},
	insurance: {
		general: {
			canHaveCoverageOverlap: false,
			carrier: 'STARR',
			type: 'AD_AND_D',
			typeDescription: 'Accidental Death & Dismemberment',
			coverageType: 'GROUP',
			requiresOFAC: true,
			membershipCheck: 'AAC',
			masterPolicyNumber: 'ADDG273825',
			policyIDGenerator: {
				prefix: 'aac',
				type: 'randomAlphaNumeric',
				length: 15,
			},
			policyEffectiveDate: '08/25/2021',
			policyAnniversaryDate: '08/25/2022',
			policyHolder: 'The American Alpine Club',
			policyHolderAddress: '2021 Arch Street\nPhiladelphia, PA 19103 ',
			enrollmentPeriodStart: 'August 25th',
			enrollmentPeriodEnd: 'August 24th',
			aggregateLimitation: '$1,000,000 per accident',
			principalSum: '$50,000 to $500,000 in increments of $10,000',
			principalSumSpouseOnlySpouse: '100%',
			principalSumSpouseOnlyChild: '0%',
			principalSumSpouseAndChildSpouse: '100%',
			principalSumSpouseAndChildChild: '20%',
			principalSumChildOnlySpouse: '0%',
			principalSumChildOnlyChild: '20%',
		},
		pricing: {
			rates: {
				ME: 0.48,
				ME_AND_SPOUSE: 1,
				ME_AND_ONE_CHILD: 0.58,
				FAMILY: 1.1,
			},
			quote: {
				expression: [{
					$facet: {
						rate: [
							{
								$addFields: {
									correctRate: {
										$filter: {
											input: {
												$objectToArray: '$rates',
											},
											as: 'el',
											cond: {
												$eq: [
													'$$el.k',
													'$fields.who',
												],
											},
										},
									},
								},
							},
							{
								$replaceRoot: {
									newRoot: {
										$first: '$correctRate',
									},
								},
							},
							{
								$project: {
									value: '$v',
								},
							},
						],
						amount: [
							{
								$project: {
									value: {
										$divide: [
											{
												$toDouble: '$fields.benefitAmount',
											},
											1000,
										],
									},
								},
							},
						],
					},
				},
				{
					$addFields: {
						rate: {
							$first: '$rate',
						},
						amount: {
							$first: '$amount',
						},
					},
				},
				{
					$project: {
						_id: 0,
						value: {
							$multiply: [
								'$rate.value',
								'$amount.value',
							],
						},
					},
				}],
				fields: [
					{
						id: 'offering',
						type: 'STRING',
						value: 'AAC_AD_AND_D',
						rules: {
							value: 'AAC_AD_AND_D',
						},
						isRequired: true,
						uiDisplay: {
							isHidden: true,
						},
					},
					{
						id: 'who',
						description: 'The type of people being covered on this policy.',
						type: 'STRING',
						isRequired: true,
						rules: {
							allowedValues: '$variables.whoOptions',
						},
						uiDisplay: {
							label: 'Who',
							order: 0,
							element: 'SELECT',
							optionLabels: '$variables.whoLabels',
						},
					},
					{
						id: 'benefitAmount',
						type: 'NUMBER',
						description: 'The total benefit the policy pays.',
						defaultValue: 50000.00,
						rules: {
							min: 50000,
							max: 500000,
							isInteger: true,
							multipleOf: 10000,
						},
						uiDisplay: {
							label: 'Benefit Amount',
							increment: 10000,
							order: 1,
							element: 'SLIDER',
							format: {
								type: 'CURRENCY',
								currencyType: 'USD',
								precision: 2,
							},
						},
						isRequired: true,
					},
				],
			},
		},
		forms: {
			allForms: [
				'example-1.pdf',
				'404-k-ach.pdf',
				'example-2.pdf',
			],
			calculateData: [
				{
					$addFields: {
						name: {
							$concat: [
								'$customer.lastName',
								' ',
								'$customer.firstName',
								{
									$cond: [
										{
											$in: [
												'$customer.middleInitial',
												[
													null,
													undefined,
													'',
												],
											],
										},
										'',
										{
											$concat: [
												'$customer.middleInitial',
												' ',
											],
										},

									],
								},
							],
						},
						'policy.dependents': {
							$map: {
								input: '$policy.dependents',
								as: 'dep',
								in: {
									$arrayToObject: {
										$map: {
											input: {
												$objectToArray: '$$dep',
											},
											as: 'this',
											in: {
												k: {
													$concat: [
														'$$this.k',
														{
															$toString: {
																$indexOfArray: [
																	'$policy.dependents',
																	'$$dep',

																],
															},
														},
													],
												},
												v: '$$this.v',
											},
										},
									},
								},
							},
						},
					},

				},
				{
					$addFields: {
						'policy.dependents': {
							$mergeObjects: '$policy.dependents',
						},
					},
				},
				{
					$project: {
						_id: 0,
						name: 1,
						dob: '$customer.dob',
						email: '$customer.email',
						phone: '$customer.phone',
						residentAddress: {
							$concat: [
								'$customer.address.line1',
								' ',
								'$customer.address.city',
							],
						},
						age: '$customer.age',
						'policy.dependents': 1,
						masterPolicyNumber: '$general.masterPolicyNumber',
						policyEffectiveDate: '$general.policyEffectiveDate',
						policyAnniversaryDate: '$general.policyAnniversaryDate',
						enrollmentPeriodStart: '$general.enrollmentPeriodStart',
						enrollmentPeriodEnd: '$general.enrollmentPeriodEnd',
						certificateEffectiveDate: '$policy.startDateDay',
						certificateNumber: '$policy._id',
						benefitAmount: { $toString: '$policy.benefitAmount' },
						principalSum: '$general.principalSum',
						principalSumSpouseOnlySpouse: '$general.principalSumSpouseOnlySpouse',
						principalSumSpouseOnlyChild: '$general.principalSumSpouseOnlyChild',
						principalSumSpouseAndChildSpouse: '$general.principalSumSpouseAndChildSpouse',
						principalSumSpouseAndChildChild: '$general.principalSumSpouseAndChildChild',
						principalSumChildOnlySpouse: '$general.principalSumChildOnlySpouse',
						principalSumChildOnlyChild: '$general.principalSumChildOnlyChild',
						aggregateLimitation: '$general.aggregateLimitation',
						policyHolder: '$general.policyHolder',
						policyHolder2: {
							$concat: [
								'$general.policyHolder',
								' (Policy Holder).',
							],
						},
						policyHolderAddress: '$general.policyHolderAddress',
						coveredPersonDefinition: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'You for whom the required premium is paid.',
								'You and Your Dependents for whom the required premium is paid.',
							],
						},
						dependentEffectiveDateTitle: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Dependent Effective Date',
							],
						},
						dependentEffectiveDateBody: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Contributory Coverage will start on the latest to occur of:\n\t1)\tThe date You become eligible for Dependent coverage, if You have enrolled the Dependent on or before that date; or\n\t2)\tthe date You enroll the Dependent, if You do so within 31 days from the date they are eligible for Dependent coverage or due to a Change in Family Status.\nIn no event will Dependent coverage become effective before You become insured.',
							],
						},
						dependentChildrenTitle: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Dependent Child(ren)',
							],
						},
						dependentChildrenBody1: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'\t1) Your unmarried: children, newborn children, stepchildren, legally adopted children, children in the process of adoption, foster children; or\n\t2) any other children related to You by blood or marriage or domestic partnership who live with You in a regular parent-child relationship;\nprovided such children are primarily dependent upon You for financial support and maintenance and are less than the Limiting Age of 26 years.',
							],
						},
						dependentChildrenBody2: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'The Limiting Age shall not apply to Your unmarried child who is incapable of self-support due to a mental or physical incapacity.  Proof of such incapacity must be furnished to Us upon enrollment or within 31 days of the child reaching the Limiting Age.  Thereafter proof will be required whenever reasonably necessary, but not more than once a year after the two-year period following reaching the Limiting Age.',
							],
						},
						dependentChildrenNote: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Note: The Limiting Age shall not be less than required by state law.',
							],
						},
						changeInCoverage: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'After your initial enrollment You may increase or decrease coverage for You:',
								'After your initial enrollment You may increase or decrease coverage for You or Your Dependents or add a new Dependent to Your existing Dependent coverage:',
							],
						},
						reserveNationalGuardService: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'You are:',
								'You or Your Dependents are:',
							],
						},
						enrollmentSpecial: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'If You do not enroll for Your coverage, within 31 days after becoming eligible under the Policy, You may only enroll for your coverage:',
								"If You do not enroll for Your coverage, and/or Your Dependent's coverage within 31 days after becoming eligible under the Policy, You may only enroll for your coverage and Your Dependent's coverage:",
							],
						},
						enrollmentPart2: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'If You do not enroll for Your coverage, within 31 days after becoming eligible under the Policy, You may only enroll for your coverage:',
								"If You do not enroll for Your coverage, and/or Your Dependent's coverage within 31 days after becoming eligible under the Policy, You may only enroll for your coverage and Your Dependent's coverage:",
							],
						},
						exclusions5: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'Injury sustained while on active duty as a member of the armed forces (land, water, air) of any country or international authority except Reserve National Guard Service;\n(We will refund the pro rata portion of any premium paid for You while You are in the armed forces on active duty.  Written notice must be given to Us within 12 months of the date You enter the armed forces.);',
								'Injury sustained while on active duty as a member of the armed forces (land, water, air) of any country or international authority except Reserve National Guard Service;\n(We will refund the pro rata portion of any premium paid for You or Your Dependents while You or Your Dependents are in the armed forces on active duty.  Written notice must be given to Us within 12 months of the date You or Your Dependents enter the armed forces.);',
							],
						},
						exclusions5Part2: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'Injury sustained while on active duty as a member of the armed forces (land, water, air) of any country or international authority except Reserve National Guard Service;\n\ta.  (We will refund the pro rata portion of any premium paid for You or Your Dependents while You or Your Dependents are in the armed forces on active duty.  Written notice must be given to Us within 12 months of the date You or Your Dependents enter the armed forces.);',
								'Injury sustained while on active duty as a member of the armed forces (land, water, air) of any country or international authority except Reserve National Guard Service;\n\ta.  (We will refund the pro rata portion of any premium paid for You while You  are in the armed forces on active duty.  Written notice must be given to Us within 12 months of the date You enter the armed forces.);',
							],
						},
						injuryWhich: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'which occurs while You are covered under the Policy.',
								'which occurs while You or Your Dependents are covered under the Policy.',
							],
						},
						eligibilityForDependentCoverageTitle: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Eligibility For Dependent Coverage',
							],
						},
						eligibilityForDependentCoverageBody: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'You will become eligible for Dependent coverage on the later of: \n\t1)	the date You become insured for coverage; or\n\t2)	the date You acquire Your first Dependent.\nYou may not elect coverage for Your Dependent if such Dependent is covered as a Member under the Policy. No person can be insured as a Dependent of more than one Member under the Policy.',
							],
						},
						changeInFamilyStatusTitle: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'Change in Family Status',
							],
						},
						changeInFamilyStatusBody: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'',
								'A Change in Family Status occurs when:\n\t1)\tYou get married or You execute a domestic partner affidavit;\n\t2)\tYou and Your spouse divorce or terminate a domestic partnership;\n\t3)\tYour child is born or You adopt or become the legal guardian of a child;\n\t4)\tYour spouse or domestic partner dies;\n\t5)\tYour child is no longer financially dependent on You or dies;\n\t6)\tYour spouse is no longer employed, which results in a loss of group insurance ; or\n\t7)\tYou have a change in classification from one class to another.',
							],
						},
						enrollmentNumber1: {
							$cond: [
								{
									$eq: [
										'$policy.who',
										'ME',
									],
								},
								'and ',
								"and Your Dependent's coverage; and",
							],
						},
					},
				},
				{
					$replaceRoot: {
						newRoot: {
							$mergeObjects: [
								'$$ROOT',
								'$policy.dependents',
							],
						},
					},
				},
				{
					$project: {
						policy: 0,
					},
				},
			],
			byState: {
				AL: [],
				AK: ['AH-40001C-DEFAULT.pdf', 'AH-40010-AK.pdf', 'AH-40007.pdf'],
				AZ: ['AH-40001C-DEFAULT.pdf', 'AH-40007.pdf', 'AH-40030-DEFAULT.pdf'],
				// AZ: ['AH-40001C-DEFAULT.pdf'],
				AR: [],
				CA: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				CO: [],
				CT: ['AH-40001C-CT.pdf', 'AH-40007-CT.pdf', 'AH-40030-CT.pdf'],
				DE: [],
				DC: ['AH-40001C-DEFAULT.pdf', 'AH-40010-DC.pdf', 'AH-40007-DC.pdf', 'AH-40030-DC.pdf', 'AH-40031-DC.pdf'],
				FL: [],
				GA: ['AH-40001C-DEFAULT.pdf', 'AH-40010-GA.pdf', 'AH-40007.pdf', 'AH-40030-DEFAULT.pdf'],
				HI: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				ID: [],
				IL: ['AH-40001C-DEFAULT.pdf', 'AH-40010-IL.pdf', 'AH-40007.pdf', 'AH-40030-IL.pdf'],
				IN: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				IA: [],
				KS: [],
				KY: [],
				LA: [],
				ME: [],
				MD: [],
				MA: ['AH-40001C-DEFAULT.pdf', 'AH-40010-MA.pdf', 'AH-40007-MA.pdf', 'AH-40030-MA.pdf'],
				MI: ['AH-40001C-DEFAULT.pdf', 'AH-40007.pdf', 'AH-40030-MI.pdf'],
				MN: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				MS: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				MO: [],
				MT: ['AH-40001C-MT.pdf', 'AH-40007-MT.pdf', 'AH-40030-MT.pdf', 'AH-40015-MT.pdf'],
				NE: [],
				NV: [],
				NH: [],
				NJ: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				NM: ['AH-40001C-DEFAULT.pdf', 'AH-40010-NM.pdf', 'AH-40007.pdf', 'AH-40030-NM.pdf'],
				NY: ['AH-40001C-NY.pdf', 'AH-40007-NY.pdf', 'AH-40030-NY.pdf'],
				NC: [],
				ND: ['AH-40001C-DEFAULT.pdf', 'AH-40010-ND.pdf', 'AH-40007.pdf', 'AH-40030-ND.pdf'],
				OH: ['AH-40001C-DEFAULT.pdf', 'AH-40010-OH.pdf', 'AH-40007-OH.pdf', 'AH-40030-OH.pdf'],
				OK: ['AH-40001C-OK.pdf', 'AH-40007.pdf', 'AH-40030-OK.pdf'],
				OR: [],
				PA: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				RI: [],
				SC: ['AH-40001C-DEFAULT.pdf', 'AH-40010-SC.pdf', 'AH-40007.pdf', 'AH-40030-SC.pdf'],
				SD: [],
				TN: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				TX: [],
				UT: [],
				VT: [],
				VA: ['AH-40001C-DEFAULT.pdf', 'AH-40010-VA.pdf', 'AH-40007-VA.pdf', 'AH-40030-VA.pdf', 'VA-NOTICE.pdf'],
				WA: [],
				WV: [],
				WI: ['AH-40001C-DEFAULT.pdf', 'AH-40010-PA.pdf', 'AH-40030-PA.pdf'],
				WY: [],
			},
		},
	},
};

export default ION;
