import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';

const getInitials = (name) => {
	if (typeof name !== 'string') {
		return null;
	}
	const initials = name.split(' ').map((el) => el.charAt(0).toUpperCase());
	return [initials[0], initials[initials.length - 1]].join('');
};

function lowerCase(str) {
	return str.toLowerCase();
}

function upperCase(str) {
	return str.toUpperCase();
}

function properCase(str) {
	return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
}

const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
};

const isEmptyString = (str) => typeof str === 'string' && !str.length;

const removeEmptyStrings = (obj) => {
	if (!isPlainObject(obj)) {
		// eslint-disable-next-line no-console
		console.error('removeEmptyStrings requires an object as an argument.');
		return null;
	}
	const clone = cloneDeep(obj);
	const keys = Object.keys(clone);
	keys.forEach((key) => {
		if (isEmptyString(clone[key])) {
			delete clone[key];
		} else if (isPlainObject(clone[key])) {
			clone[key] = removeEmptyStrings(clone[key]);
		}
	});
	return clone;
};

export {
	getInitials,
	lowerCase,
	upperCase,
	properCase,
	capitalize,
	isEmptyString,
	removeEmptyStrings,
};
