import React from 'react';

function RequiredLabel({ label }) {
	return (
		<label className="sc-fAjcbJ exXWeL m-0">
			<span className="font-bold text-buddy-red mr-1">*</span>
			{label}
		</label>
	);
}

export default RequiredLabel;
