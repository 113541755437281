import { createContext, useContext } from 'react';
import { Auth } from 'aws-amplify';
import fetch from 'isomorphic-fetch';
import FileSaver from 'file-saver';
import { jsonToGraphQLQuery, EnumType } from 'json-to-graphql-query';
import { formatToStandardDate, Numbers } from '@buddy-technology/buddy_helpers';
import { serialize, isRequired } from '@/utils/dataHelpers';
import { useAppContext } from '@/providers/AppContext';

const { dollarsToCents, centsToDollars } = Numbers;

const {
	REACT_APP_buddy_api_url: API_URL = 'https://staging.api.buddyinsurance.com/',
	REACT_APP_buddy_api_version: API_VERSION = 2,
	// REACT_APP_partner_api_url: partnerApiUrl,
	// REACT_APP_TEST_partnerAPI: testPartnerApiUrl,
	// REACT_APP_buddy_partner_api_version: partnerAPIVersion = 1,
	REACT_APP_buddy_auth_url: authURL = 'http://localhost:8080/dev/user-management',
	REACT_APP_bitly_token: bitlyToken,
} = process.env;
const FULL_URL = `${API_URL}v${API_VERSION}`;
const ION_URL = `${API_URL}v3/ion/graph`;

// some ENUMs for helping
export const ENUM = {
	ION_TYPE: {
		BLUEPRINT: new EnumType('BLUEPRINT'),
		OFFERING: new EnumType('OFFERING'),
		BUNDLE: new EnumType('BUNDLE'),
	},
	ISSUANCE_TYPE: {
		INDIVIDUAL: new EnumType('INDIVIDUAL'),
		GROUP: new EnumType('GROUP'),
		COMMERCIAL: new EnumType('COMMERCIAL'),
	},
	ISSUANCE_BASIS: {
		VOLUNTARY: new EnumType('VOLUNTARY'),
		INVOLUNTARY: new EnumType('INVOLUNTARY'),
	},
	ISSUANCE_LINE: {
		AD_AND_D: new EnumType('AD_AND_D'),
		ACCIDENT: new EnumType('ACCIDENT'),
		TICKET_CANCELLATION: new EnumType('TICKET_CANCELLATION'),
		REGISTRATION_CANCELLATION: new EnumType('REGISTRATION_CANCELLATION'),
		PROPERTY_AND_CASUALTY: new EnumType('PROPERTY_AND_CASUALTY'),
	},
	STATUS: {
		DRAFT: new EnumType('DRAFT'),
		SUBMITTED: new EnumType('SUBMITTED'),
		PUBLISHED: new EnumType('PUBLISHED'),
		ARCHIVED: new EnumType('ARCHIVED'),
	},
};

// helper function to generate the FETCHES
const createFetch = (options) => {
	const {
		token,
		url,
		method = 'GET',
		body = null,
	} = options;
	const abortController = new AbortController();
	const obj = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		signal: abortController.signal,
	};

	// if there is a token, put it in the header
	if (token) {
		obj.headers.Authorization = `Bearer ${token}`;
	}

	if (body) {
		obj.body = JSON.stringify(body);
	}
	return { send: async () => fetch(url, obj), abort: () => abortController.abort() };
};

export const helpers = {
	getTrueCount: (data, countOfWhat = 'order') => {
		let count = 0;
		if (countOfWhat === 'order') {
			data.forEach((call) => {
				if (call.end_point.startsWith('order')) {
					count += call.end_point === 'order/batch' ? call?.response?.data?.filter((el) => el.ok).length || 1 : 1;
				}
			});
		} else if (countOfWhat === 'quote') {
			data.forEach((call) => {
				if (call.end_point.startsWith('quote')) {
					count += call.end_point === 'quote/batch' ? call?.response?.data?.filter((el) => el.ok).length || 1 : 1;
				}
			});
		} else {
			throw new Error('Unsupported countOfWhat value');
		}
		return count;
	},
	// this function looks at all the orders sent, verifies them 1 at a time that they were accepted, and checks the premium for the accepted ones
	getWrittenPremiumFromBatch: (ordersSent, response) => {
		let writtenPremium = 0;
		ordersSent?.forEach((order, i) => {
			if (response?.data && response.data[i].ok) {
				writtenPremium += Number(order?.policy?.premiumTotal || 0);
			}
		});
		return writtenPremium;
	},
	bestGuessAtPremiumOfOrder: (order) => order?.payload?.policy?.premiumTotal
			|| helpers.getWrittenPremiumFromBatch(order?.payload?.orders, order?.response)
			|| order?.response?.premiumCollected
			|| 0,
};

export const calls = {
	ofEndpoint: (data, endpoints) => {
		const endpointsArray = Array.isArray(endpoints) ? endpoints : [endpoints];
		return data?.filter((el) => endpointsArray.includes(el.end_point));
	},
	premiumCollected: (data) => {
		// orders only
		const orders = calls.ofEndpoint(data, ['order', 'order/batch']);
		const total = orders.reduce(
			(runningValue, currentElement) => runningValue +
							dollarsToCents(
								helpers.bestGuessAtPremiumOfOrder(currentElement) || 0,
							),
			0,
		);

		return centsToDollars(total);
	},
};

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
	const { appState: { authData }, setAppState } = useAppContext();

	const refreshToken = async () => {
		setAppState({ isRefreshingToken: true });
		try {
			const { idToken } = await Auth.currentSession();
			const updatedAuthData = {
				...authData,
				signInUserSession: { ...authData.signInUserSession, idToken },
			};
			setAppState({
				authData: updatedAuthData,
				token: idToken.jwtToken,
				isRefreshingToken: false,
			});
			return idToken.jwtToken;
		} catch (error) {
			setAppState({ authData: null, isRefreshingToken: false });
			return Promise.reject(error);
		}
	};

	const getToken = async () => {
		const idToken = authData?.signInUserSession?.idToken;
		const expirationTime = idToken?.payload?.exp;
		const nowInSeconds = Math.round(Date.now() / 1000);
		const isTokenExpired = nowInSeconds >= expirationTime;
		if (!isTokenExpired) {
			return idToken.jwtToken;
		}
		try {
			const token = await refreshToken();
			return token;
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const ionAPI = {
		list: async (
			properties = {
				id: true,
				ionVersion: true,
				status: true,
				ionType: true,
				issuanceType: true,
				issuanceBasis: true,
				insuranceLine: true,
				createdAtMillisecond: true,
				lastUpdatedAtMillisecond: true,
				label: true,
				description: true,
			},
			search = {},
		) => {
			const ions = { ...properties };
			if (Object.keys(search).length) {
				// eslint-disable-next-line no-underscore-dangle
				ions.__args = search;
			}
			const query = {
				query: {
					ions,
				},
			};

			// get our token
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}

			// setup the call
			const apiCall = createFetch({
				token,
				url: ION_URL,
				method: 'POST',
				body: {
					query: jsonToGraphQLQuery(query),
				},
			});

			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.errors) {
							// bad stuff happened
							// for now, just send back the first thing
							// note: figure out a better way?
							return Promise.reject(new Error(json.errors[0].error));
						}
						return json?.data?.ions;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		getDetails: async (id, properties = {
			id: true,
			application: {
				views: true,
				fields: true,
			},
			variables: true,
			status: true,
			ionVersion: true,
		}) => {
			// first, add the id as an argument and setup query props
			Object.assign(properties, { __args: { id } });
			const query = {
				query: {
					ion: {
						...properties,
					},
				},
			};

			// get our token
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}

			// setup the call
			const apiCall = createFetch({
				token,
				url: ION_URL,
				method: 'POST',
				body: {
					query: jsonToGraphQLQuery(query),
				},
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.errors) {
							// bad stuff happened
							// for now, just send back the first thing
							// note: figure out a better way?
							return Promise.reject(new Error(json.errors[0].error));
						}
						return json?.data?.ion;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		create: async (ion = {}, properties = { id: true }) => {
			// first, add the id as an argument
			const mutation = {
				mutation: {
					newION: {
						...properties,
						__args: {
							ion,
						},
					},
				},
			};

			// get our token
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}

			// setup the call
			const apiCall = createFetch({
				token,
				url: ION_URL,
				method: 'POST',
				body: {
					query: jsonToGraphQLQuery(mutation),
				},
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.errors) {
							// bad stuff happened
							// for now, just send back the first thing
							// note: figure out a better way?
							return Promise.reject(new Error(json.errors[0].error));
						}
						return json?.data?.ion;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		update: async (id, updates = {}, properties = { id: true }) => {
			// first, add the id as an argument
			const mutation = {
				mutation: {
					updateION: {
						...properties,
						__args: {
							id,
							update: updates,
						},
					},
				},
			};

			// get our token
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}

			// setup the call
			const apiCall = createFetch({
				token,
				url: ION_URL,
				method: 'POST',
				body: {
					query: jsonToGraphQLQuery(mutation),
				},
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.errors) {
							// bad stuff happened
							// for now, just send back the first thing
							// note: figure out a better way?
							return Promise.reject(new Error(json.errors[0].error));
						}
						return json?.data?.ion;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	const callsAPI = {
		query: async (query = isRequired('query')) => {
			const url = `${FULL_URL}/calls/query${serialize(query)}`;
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const apiCall = createFetch({ token, url });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};
	const salesDataAPI = {
		get: async (query = isRequired('query')) => {
			const url = `${FULL_URL}/sales/${serialize(query)}`;
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const apiCall = createFetch({ token, url });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	const offeringAPI = {
		helpers: {
			convertToStandardDates: (offering) => ({
				...offering,
				eventStartDate: formatToStandardDate(offering.eventStartDate),
				eventEndDate: formatToStandardDate(offering.eventEndDate),
				registrationStartDate: formatToStandardDate(offering.registrationStartDate),
				registrationEndDate: formatToStandardDate(offering.registrationEndDate),
			}),
			convertFromStandardDates: (offering) => ({
				...offering,
				eventStartDate: new Date(offering.eventStartDate),
				eventEndDate: new Date(offering.eventEndDate),
				registrationStartDate: new Date(offering.registrationStartDate),
				registrationEndDate: new Date(offering.registrationEndDate),
			}),
		},
		list: async () => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.offerings.map((offering) => offeringAPI.helpers.convertFromStandardDates(offering));
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		get: async (offeringID) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering/${offeringID}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return offeringAPI.helpers.convertFromStandardDates(json.offering);
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		create: async (offering) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering`;
			const apiCall = createFetch({
				token,
				url,
				method: 'POST',
				body: offeringAPI.helpers.convertToStandardDates(offering),
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return offeringAPI.helpers.convertFromStandardDates(json.offering);
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		update: async (offeringID = isRequired('offeringID'), update) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering/${offeringID}`;
			const apiCall = createFetch({
				token,
				url,
				method: 'PUT',
				body: offeringAPI.helpers.convertToStandardDates(update),
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return offeringAPI.helpers.convertFromStandardDates(json.offering);
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		submit: async (offeringID = isRequired('offeringID')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering/${offeringID}/submit`;
			const apiCall = createFetch({
				token,
				url,
				method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.ok;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		publish: async (offeringID = isRequired('offeringID')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/offering/${offeringID}/publish`;
			const apiCall = createFetch({
				token,
				url,
				method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.ok;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	const partnerAPI = {
		list: async () => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		get: async (partnerID = isRequired('partnerID')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner/${partnerID.toLowerCase()}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		add: async (partnerData) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner`;
			const method = 'POST';
			const body = { ...partnerData };
			const apiCall = createFetch({
				url, token, method, body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json?.data || json.partner; // future proof this end point
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		addNote: async (partner, noteInfo) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner/${partner}/note`;
			const method = 'POST';
			const body = { ...noteInfo };
			const apiCall = createFetch({
				url, token, method, body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json?.data || json.note; // future proof this end point
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		deleteNote: async (partner, noteID) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner/${partner}/note/${noteID}/delete`;
			const method = 'PUT';
			const apiCall = createFetch({
				url, token, method,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return true;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		getNotes: async (partnerID) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner/${partnerID.toLowerCase()}/notes`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		update: async (partnerID, partnerData) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/partner/${partnerID}`;
			const method = 'PUT';
			const body = { ...partnerData };
			const apiCall = createFetch({
				url, token, method, body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json?.data || json.partner; // future proof this end point
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		analytics: {
			getCalls: async (options) => {
				let token;
				try {
					token = await getToken();
				} catch (error) {
					return Promise.reject(error);
				}
				// const { useTestData, ...query } = options;
				const { ...query } = options;
				// todo: need to switch between staging/production core
				// const urlToUse = useTestData ? testPartnerApiUrl : partnerApiUrl;
				const url = `${FULL_URL}/partner/calls${serialize(query)}`;
				const apiCall = createFetch({ url, token });
				return {
					send: async () => {
						try {
							const res = await apiCall.send();
							const json = await res.json();
							if (!json.ok) {
								return Promise.reject(new Error(json.error));
							}
							return json.data;
						} catch (error) {
							return Promise.reject(error);
						}
					},
					abort: () => apiCall.abort(),
				};
			},
			calls,
		},
	};

	const customerAPI = {
		listDelinquentPayers: async ({ page = 1, limit = 50, nextSet = null }) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = nextSet ? `${FULL_URL}/customer/${nextSet}`
				: `${FULL_URL}/customer/list/delinquent/?limit=${limit}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						// strict check here bc Api currently doesn't return OK for this route
						if (json.ok === false) {
							return Promise.reject(new Error(json.error));
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		list: async ({ page = 1, limit = 50, nextSet = null }) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = nextSet ? `${FULL_URL}/customer/${nextSet}`
				: `${FULL_URL}/customer/list/${page}?limit=${limit}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		search: async ({ term, page = 1, limit = 50 }) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/customer/search/${term}/${page}?limit=${limit}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						// strict check here bc Api currently doesn't return OK for this route
						if (json.ok === false) {
							// On successful searches with no matches the api currently returns an errorCode of 'NOT_FOUND'.
							// Instead of prompting a modal error (API CONTEXT:44) we'll let ReactRainbow give us back a friendly
							// message by passing an empty array. This may change with the TODO below.
							if (json?.errorCode === 'NOT_FOUND') {
								return { data: [] };
							}
							return Promise.reject(new Error(json.error));
						}
						// TODO: once the API is updated, update here. Keeping things consistent for the front-front-end.
						return { data: json };
					} catch (error) {
						// if no results, simply return an empty array.
						if (error?.errorCode === 'NOT_FOUND') {
							return [];
						}
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		add: async (body = isRequired('body')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/customer?`;
			const apiCall = createFetch({
				url, token, method: 'POST', body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.ok === false) {
							return Promise.reject(new Error(json.error));
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		get: async (id) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/customer?id=${id}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.ok === false) {
							return Promise.reject(json.error);
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		addNote: async (customerID, noteInfo) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/note?`;
			const method = 'POST';
			const body = { ...noteInfo };
			const apiCall = createFetch({
				url, token, method, body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json?.data || json.note; // future proof this end point
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		update: async (id = isRequired('id'), body = isRequired('body')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/customer?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT', body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.ok === false) {
							return Promise.reject(new Error(json.error));
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		updatePayment: async (id = isRequired('id'), paymentToken = isRequired('paymentToken')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${FULL_URL}/customer/payment?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT', body: { paymentToken },
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (json.ok === false) {
							return Promise.reject(new Error(json.error));
						}
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	const authAPI = {
		create: async ({
			email = isRequired('email'),
			type = isRequired('type'),
			partnerID = type === 'partner' ? isRequired('partnerID') : null,
			...attributes
		}) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/`;
			const body = {
				email,
				partnerID,
				...attributes,
			};
			const apiCall = createFetch({
				url, token, method: 'POST', body,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		list: async (type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/list`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		get: async (id = isRequired('id'), type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/?id=${id}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},

		/**
		* getHistory params: id (req'd), maxResults, nextToken.
		*/
		getHistory: async ({
			id = isRequired('id'),
			type = isRequired('type'),
			...params
		}) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/history/${serialize({ id, ...params })}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						// return everything in case there's a nextToken we need to keep in state.
						return json;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		listGroups: async (type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/group/list`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		listGroupsForUser: async (id = isRequired('id'), type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/group/?id=${id}`;
			const apiCall = createFetch({ url, token });
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.data;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		addToGroup: async ({
			id = isRequired('id'),
			group = isRequired('group'),
			type = isRequired('type'),
		}) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/group/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT', body: { group },
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		removeFromGroup: async ({
			id = isRequired('id'),
			group = isRequired('group'),
			type = isRequired('type'),
		}) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/remove-group/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT', body: { group },
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		disableUser: async (id = isRequired('id'), type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/disable/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		enableUser: async (id = isRequired('id'), type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/enable/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		deleteUser: async (id = isRequired('id'), type = isRequired('type')) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'DELETE',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		// ID must be the original email, even if you're changing the email address.
		update: async ({
			id = isRequired('id'),
			type = isRequired('type'),
			...updates
		}) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT', body: updates,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		enableSMSMfa: async (
			id = isRequired('id'),
			type = isRequired('type'),
		) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/enable-sms?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		disableSMSMfa: async (
			id = isRequired('id'),
			type = isRequired('type'),
		) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/disable-sms?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		resetPassword: async (
			id = isRequired('id'),
			type = isRequired('type'),
		) => {
			let token;
			try {
				token = await getToken();
			} catch (error) {
				return Promise.reject(error);
			}
			const url = `${authURL}/v1/${type}/reset?id=${id}`;
			const apiCall = createFetch({
				url, token, method: 'PUT',
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const json = await res.json();
						if (!json.ok) {
							return Promise.reject(new Error(json.error));
						}
						return json.message;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	const getBitlyLink = (long_url) => {
		const url = 'https://api-ssl.bitly.com/v4/shorten';
		const apiCall = createFetch({
			url, token: bitlyToken, method: 'POST', body: { long_url },
		});
		return {
			send: async () => {
				try {
					const res = await apiCall.send();
					const json = await res.json();
					return json;
				} catch (error) {
					return Promise.reject(error);
				}
			},
			abort: () => apiCall.abort(),
		};
	};

	const pdfAPI = {
		fromMarkdown: async (markdown, filename) => {
			const url = 'http://localhost:3010/pdf/fromMarkdown';
			const apiCall = createFetch({
				url, token: null, method: 'POST', body: { markdown },
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						const blob = await res.blob();
						FileSaver.saveAs(blob, filename);
						return true;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
		fill: async (pdf, data) => {
			const url = `http://localhost:3010/pdf/${pdf}/fill?stamp=true`;
			const apiCall = createFetch({
				url, token: null, method: 'POST', body: data,
			});
			return {
				send: async () => {
					try {
						const res = await apiCall.send();
						// const json = await res.json();
						const blob = await res.blob();
						// FileSaver.saveAs(blob, filename);
						FileSaver.saveAs(blob, 'test.pdf');
						// console.log(json)
						return true;
					} catch (error) {
						return Promise.reject(error);
					}
				},
				abort: () => apiCall.abort(),
			};
		},
	};

	return (
		<ApiContext.Provider
			value={{
				callsAPI,
				salesDataAPI,
				offeringAPI,
				partnerAPI,
				customerAPI,
				authAPI,
				getBitlyLink,
				pdfAPI,
				ionAPI,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export const useApiContext = () => {
	const context = useContext(ApiContext);
	if (!context) {
		throw new Error('useApiContext must be used within a ApiProvider');
	}
	return context;
};
