const ColorSwatch = ({ theme, size = 10 }) => {
	if (!theme) {
		return null;
	}
	const colors = Object.values(theme);
	const keys = Object.keys(theme);
	return (
		<div className="flex">
			{colors.map((color, i) => (
				<div key={`${keys[i]}-${color}`} style={{ background: color, width: size, height: size }} />
			))}
		</div>
	);
};

export default ColorSwatch;
