import { Numbers } from '@buddy-technology/buddy_helpers';

import { RangeSlider } from '../../UI';
import DraggableNumberInput, { getPercentageMask } from '../../DraggableNumberInput';

const PriceSlider = ({
	value = [1000],
	onRangeChange,
	step = 100,
	max = 10000,
	min = 0,
}) => (
	<div className="mb-5">
		<RangeSlider
			max={max}
			min={min}
			values={value}
			onChange={onRangeChange}
			step={step}
			valueRender={(val) => Numbers.toUSD(val, 0)}
			isCurrency
		/>
	</div>
);

function Underwriting({ productDetails, updateProduct }) {
	const {
		underwriting,
	} = productDetails;
	const {
		lossRatio,
		perTicketCap,
		maxLossForEvent,
	} = underwriting;

	const handleChange = (name, value) => {
		const newUnderwriting = { ...underwriting, ...{ [name]: value } };
		updateProduct({ underwriting: newUnderwriting });
	};

	return (
		<div>
			<h4 className="mb-5">Loss Ratio</h4>
			<DraggableNumberInput
				className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl px-2 w-1/3 md:w-1/6 lg:w-1/12"
				mask={getPercentageMask}
				max={100}
				min={0}
				value={lossRatio}
				onChange={(value) => handleChange('lossRatio', value)}
			/>
			<h4 className="mt-10">Per-Ticket Cap</h4>
			<PriceSlider
				onRangeChange={(value) => handleChange('perTicketCap', value[0])}
				value={[perTicketCap]}
				min={500}
				max={25000}
			/>
			<h4 className="mt-10">Max Loss for Event</h4>
			<PriceSlider
				onRangeChange={(value) => handleChange('maxLossForEvent', value[0])}
				value={[maxLossForEvent]}
				min={20000}
				max={5000000}
			/>
		</div>
	);
}

export default Underwriting;
