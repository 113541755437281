import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {
	Button,
	TextArea,
	Modal,
	FileSelector,
	Chip,
} from '@/lib/ReactRainbow';

import DraggableNumberInput, { getPercentageMask } from '@/components/DraggableNumberInput';

function RefundPolicy({ productDetails, updateProduct }) {
	const [modalOpen, setModalOpen] = useState(false);
	const { hasRefundPolicy, refundPolicy } = productDetails;

	const setPercentage = (percentage) => {
		updateProduct({ refundPolicy: { ...refundPolicy, percentage } });
	};

	return (
		<div>
			<h4 className="mb-5">Add refund details (optional)</h4>
			{hasRefundPolicy ? (
				<>
					<label className="mt-5 rainbow-label">
          Percentage of Registration Cost Refunded (optional)
					</label>
					<DraggableNumberInput
						className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl px-2 w-1/3 md:w-1/6 lg:w-1/12"
						mask={getPercentageMask}
						onChange={setPercentage}
						value={refundPolicy?.percentage}
					/>
					<TextArea
						label="Paste your refund policy here. Or upload a document (.pdf, .doc, .txt) using the UPLOAD button below. (optional)"
						labelAlignment="left"
						className="mt-5"
						placeholder="Refund Policy: Tickets are eligible for a 95% refund if refund is requested within 10 days of purchase."
						value={refundPolicy?.text || ''}
						onChange={({ target: { value } }) => updateProduct({ refundPolicy: { ...refundPolicy, text: value } })}
					/>
					{refundPolicy.file ? (
						<div className="mt-3">
							<Chip
								label={refundPolicy.file[0].name}
								onDelete={() => updateProduct({
									refundPolicy: { ...refundPolicy, file: null },
								})}
							/>
						</div>
					) : null}
					<Button
						className="block mt-5"
						onClick={() => setModalOpen(true)}
						label={(
							<>
								<FontAwesomeIcon
									icon={faFileUpload}
									className="rainbow-m-right_medium mr-1"
								/>
              Upload
							</>
						)}
						variant="neutral"
					/>
					<Modal
						isOpen={modalOpen}
						onRequestClose={() => setModalOpen(false)}
						title="Upload Refund Policy"
					>
						<div className="p-12">
							<FileSelector
								placeholder="Drag & Drop or Click to Browse"
								onChange={(value) => updateProduct({
									refundPolicy: { ...refundPolicy, file: value },
								})}
								variant="multiline"
								value={refundPolicy?.file}
							/>
						</div>
						<Button
							className="my-5 block mx-auto"
							label="Done"
							onClick={() => setModalOpen(false)}
						/>
					</Modal>
				</>
			) : null}
		</div>
	);
}

export default RefundPolicy;
