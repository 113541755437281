import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileDownload,
	faPlus,
	faCode,
	faTrash,
	faExclamationTriangle,
	faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { useApiContext, useAppContext, usePartnerData } from '@/providers';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import { ErrorBoundary } from '@/components';
import { IF } from '@/components/utils';
import { Label, SectionHeader } from '@/components/UI';
import {
	Table,
	Column,
	Card,
	Button,
	ButtonIcon,
	Input,
	TextArea,
	Modal,
	Spinner,
	MarkdownOutput,
	Picklist,
	Option,
	MenuItem,
} from '@/lib/ReactRainbow';

const NoteModal = (props) => {
	// partner information passed through context
	const { partnerData } = usePartnerData();
	const { partnerAPI } = useApiContext();
	// REFS
	const addedNoteRef = useRef();

	const {
		isOpen,
		handleCloseRequest,
		venue: venueSent = null,
		title: titleSent = '',
		note: noteSent = '',
		isReadOnly = false,
		handleNewNote = () => {},
		download,
	} = props;

	const [info, setInfo] = useState({
		title: titleSent,
		note: noteSent,
		venue: (venueSent ? {
			name: venueSent, value: venueSent, label: venueSent, icon: null,
		} : null),
	});
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [isPreview, setIsPreview] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setIsLoading(false);
			setErrorMsg(null);
			setHasSubmitted(false);
			setIsPreview(false);
			setInfo({
				title: titleSent,
				note: noteSent,
				venue: venueSent ? {
					name: venueSent, value: venueSent, label: venueSent, icon: null,
				} : null,
			});
		}
	}, [isOpen]);

	const checkForErrors = () => {
		const requiredProps = [
			{
				id: 'title',
				label: 'Title',
			},
			{
				id: 'note',
				label: 'Note',
			}];
		const emptyFields = requiredProps.filter((prop) => !info[prop.id]?.length);
		if (emptyFields.length) {
			const errorMessage = `The following fields are required: [${emptyFields.map((el) => el.label).join(', ')}].`;
			return errorMessage;
		}
		return null;
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		setHasSubmitted(true);
		const submittedObject = {
			title: info.title,
			note: info.note,
			venue: info.venue?.value,
		};

		// do error check
		const errorFound = checkForErrors();
		if (errorFound) {
			setErrorMsg(errorFound);
			setIsLoading(false);
			return;
		}

		try {
			if (addedNoteRef.current) {
				addedNoteRef.current.abort();
			}
			const addedNote = await partnerAPI.addNote(partnerData.id, submittedObject);
			addedNoteRef.current = addedNote;

			const addedNoteFromServer = await addedNote.send();
			handleNewNote(addedNoteFromServer);
			setIsLoading(false);
			addedNoteRef.current = null;
			handleCloseRequest();
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setErrorMsg(error.message || error);
				setIsLoading(false);
				addedNoteRef.current = null;
			}
		}
	};

	const handleChange = ({ target: { name, value } }) => {
		const newInfo = { ...info, ...{ [name]: value } };
		setInfo(newInfo);
	};

	const onHandleCloseRequest = () => {
		if (!isLoading) {
			handleCloseRequest();
		}
	};

	const {
		title,
		note,
		venue = null,
	} = info;

	return (
		<ErrorBoundary>
			<Modal
				onRequestClose={onHandleCloseRequest}
				isOpen={isOpen}
				title={`${isReadOnly ? '' : 'New'} Note for ${partnerData?.name || '?'}`}
				footer={isReadOnly ?
					(
						<Button
							className="w-full"
							variant="neutral"
							onClick={() => download({ title, note, venue })}
							disabled={isLoading}
						>
							Download Note
							<FontAwesomeIcon icon={faFileDownload} className="ml-2" />
						</Button>
					)
					: (
						<div className="flex justify-between">
							<Button
								label="Cancel"
								variant="base"
								onClick={handleCloseRequest}
								disabled={isLoading}
							/>
							<Button
								label="Submit"
								variant="success"
								onClick={handleSubmit}
								disabled={isLoading}
							/>
						</div>
					)}
			>
				<Input
					id="title"
					name="title"
					label={<Label label="Title" isRequired={!isReadOnly} />}
					labelAlignment="left"
					className={`mb-4 ${isReadOnly && 'ml-4'}`}
					placeholder="The Bat Note"
					type="text"
					value={title}
					onChange={handleChange}
					error={hasSubmitted && !title.length ? 'This field is required.' : null}
					readOnly={isReadOnly}
				/>
				<Label label="Note" className="ml-4" isRequired={!isReadOnly} />
				<IF condition={!isPreview && !isReadOnly}>
					<TextArea
						id="note"
						name="note"
						className="mb-4"
						labelAlignment="left"
						rows={8}
						onChange={handleChange}
						value={note}
						placeholder="And then the Joker got away..."
						footer={(
							<div className="flex flex-row justify-between bg-gray-100 w-full rounded-b-xl px-2 py-1">
								<a href="https://guides.github.com/features/mastering-markdown/" target="_blank" rel="noreferrer" className="my-auto text-gray-400 hover:text-buddy-red active:text-buddy-red focus:text-buddy-red text-left text-xs">
									What is markdown?
								</a>
								<Button
									variant="base"
									size="small"
									style={{ boxShadow: 'none' }}
									onClick={() => setIsPreview(true)}
								>
									Preview
								</Button>
							</div>
						)}
						error={hasSubmitted && !note.length ? 'This field is required.' : null}
					/>
				</IF>
				<IF condition={isPreview || isReadOnly}>
					<div className="flex flex-col mb-4 border border-gray-400 rounded-xl" style={{ minHeight: 284 }}>
						<MarkdownOutput
							className="flex flex-row flex-grow p-4"
							value={note}
						/>
						<IF condition={!isReadOnly}>
							<div className="flex flex-row justify-between place-self-end bg-gray-100 w-full rounded-b-xl px-2 py-1">
								<a href="https://guides.github.com/features/mastering-markdown/" target="_blank" rel="noreferrer" className="my-auto text-gray-400 hover:text-buddy-red active:text-buddy-red focus:text-buddy-red text-left text-xs">
									Supports Markdown
								</a>
								<Button
									variant="base"
									size="small"
									style={{ boxShadow: 'none' }}
									onClick={() => setIsPreview(false)}
								>
									Edit
								</Button>
							</div>
						</IF>
					</div>
				</IF>
				<ErrorBoundary>
					<IF condition={(!isReadOnly && partnerData?.venues?.length > 1) || (isReadOnly && !!venue)}>
						<div className="flex flex-row justify-between">
							<Label label="Venue" className="ml-4 text-md text-sm my-auto" htmlFor="venue" />
							<IF condition={!isReadOnly}>
								<Button
									className="text-buddy-red py-0 my-0"
									variant="base"
									size="small"
									disabled={!venue}
									onClick={() => handleChange({ target: { name: 'venue', value: null } })}
								>
									clear
								</Button>
							</IF>
						</div>
						<Picklist
							id="venue"
							name="venue"
							onChange={(value) => handleChange({ target: { name: 'venue', value } })}
							value={venue}
							label="Venue Selection"
							className={`mb-8 ${isReadOnly && 'ml-4'}`}
							placeholder="No venue selected"
							hideLabel
							readOnly={isReadOnly}
						>
							<Option
								name="header"
								label="Venue Options"
								variant="header"
							/>
							{partnerData?.venues?.length && partnerData.venues.map((el, i) => (
								<Option key={`venue-${el.name}`} name={el.name} label={el.name} value={el.name} />
							))}
						</Picklist>
					</IF>
				</ErrorBoundary>
				<IF condition={errorMsg}>
					<div className="text-buddy-red text-sm text-center mt-3">
						<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
						{errorMsg}
					</div>
				</IF>
			</Modal>
		</ErrorBoundary>
	);
};

function PartnersNote({ navigate }) {
	// top level app info from context

	const [notes, setNotes] = useState([]);
	const { displayError, displayConfirmation } = useAppContext();
	const loadNotesRef = useRef();
	// partner information passed through context
	const { partnerData } = usePartnerData();
	const { partnerAPI } = useApiContext();
	const [selectedNote, setSelectedNote] = useState(null);

	// 2 different modals
	const [modalIsOpen, setModalOpen] = useState(false);

	// controls the overall state of the view
	const [isLoading, setIsLoading] = useState(false);

	const loadData = async () => {
		if (!partnerData?.id) {
			return;
		}

		// if a request is still pending, cancel and start a new one.
		if (loadNotesRef.current) {
			loadNotesRef.current.abort();
		}
		setIsLoading(true);
		try {
			// get a reference to fetch
			const fetchNotes = await partnerAPI.getNotes(partnerData?.id);
			loadNotesRef.current = fetchNotes;

			const results = await fetchNotes.send();
			setNotes(results);
			setIsLoading(false);
			loadNotesRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error?.message || error);
				setIsLoading(false);
				loadNotesRef.current = null;
			}
		}
	};

	useEffect(() => {
		loadData();
	}, [partnerData?.id]);

	const viewNote = (data) => {
		setSelectedNote(data);
		setModalOpen(true);
	};

	const saveData = (data, filename = 'data.json') => {
		const json = JSON.stringify(data, null, 2);
		const file = new File([json], filename, {
			type: 'text/json;charset=utf-8',
		});
		FileSaver.saveAs(file);
	};

	const saveNoteAsMarkdown = (note) => {
		let str = `# ${note.title}\n`;
		if (note.venue) {
			str += `#### Venue: ${note.venue}\n`;
		}
		str += note.note;
		const filename = `${kebabCase(partnerData.name)}_${kebabCase(note.title)}_note.md`;
		const file = new File([str], filename, {
			type: 'text/json;charset=utf-8',
		});
		FileSaver.saveAs(file);
	};

	const startNewNote = () => {
		setSelectedNote(null);
		setModalOpen(true);
	};

	const handleNewNote = (newNote) => {
		const existingNotes = [...notes];
		existingNotes.push(newNote);
		setNotes(existingNotes);
	};

	const deleteNote = async (note) => {
		displayConfirmation();
		try {
			await partnerAPI.deleteNote(partnerData.id, note.id).send();
			const existingNotes = [...notes];
			const deleteIndex = existingNotes.findIndex((noteEl) => noteEl.id === note.id);
			existingNotes.splice(deleteIndex, 1);
			setNotes(existingNotes);
		} catch (error) {
			displayError(error);
			setIsLoading(false);
		}
	};

	const confirmNoteDeletion = (note) => {
		displayConfirmation(
			(didConfirm) => {
				if (didConfirm) {
					deleteNote(note);
				}
			},
			{
				message: (
					<div>
						Are you sure you want to delete the
						{' '}
						<span className="font-extrabold underline">{note.title}</span>
						{' '}
						note?
					</div>),
			},
		);
	};

	return (
		<div className="w-full pt-5">
			<SectionHeader
				className="mb-5 pl-3"
				title="Partner Notes"
				actions={(
					<Button
						variant="neutral"
						onClick={() => startNewNote()}
						disabled={isLoading}
						size="small"
					>
						<FontAwesomeIcon
							icon={faPlus}
							className="mr-2"
						/>
					Add Note
					</Button>
				)}
			/>
			<div className="">
				<Card
					className="mx-auto"
					// actions={(
					// 	<ButtonIcon
					// 		assistiveText="refresh data button"
					// 		title="Refresh the data"
					// 		type="button"
					// 		icon={<FontAwesomeIcon icon={faRedoAlt} />}
					// 		variant="border"
					// 		size="medium"
					// 		disabled={isLoading}
					// 		className=""
					// 		onClick={handleRefresh}
					// 	/>
					// )}
					footer={
						isLoading ? (
							<Spinner className="relative inset-0 mt-6" size="medium" variant="brand" />
						) : (
							<div className="flex justify-between">
								<ButtonIcon
									assistiveText="download json button"
									title="Download notes as CSV"
									type="button"
									icon={<FontAwesomeIcon icon={faFileDownload} />}
									variant="border"
									size="medium"
									disabled={isLoading || !notes?.length}
									onClick={() => saveData(
										notes,
										'notes.json',
									)}
									className=""
								/>
							</div>
						)
					}
				>
					<Table
						data={notes}
						keyField="id"
						isLoading={isLoading}
						emptyDescription={(
							<div>
								Try
								<Button
									variant="base"
									className="px-1"
									onClick={startNewNote}
									style={{ boxShadow: 'none' }}
								>
								adding a new note.
								</Button>
							</div>
						)}
						showRowNumberColumn
					>
						<Column
							header="Timestamp"
							field="timestampMilliseconds"
							component={({ row, value }) => (
								<Button
									variant="base"
									className="text-sm px-0 hover:underline"
									style={{ border: 'none', boxShadow: 'none' }}
									onClick={() => viewNote(row)}
									label={value ? moment(value).format('MMM Do YYYY, h:mm:ss a') : 'unknown'}
								/>
							)}
						/>
						<Column header="Title" field="title" />
						<Column
							header="Note"
							field="note"
							// the below function grabs the first 20 characters and drops ALL special characters
							component={({ value }) => `${value.substr(0, 20).replace(/[^a-zA-Z0-9 ]/g, '')}...`}
						/>

						{partnerData?.venues?.length && (
							<Column
								header="Venue"
								field="venue"
								component={({ value }) => (value && startCase(value.name || value)) || '---'}
							/>
						)}
						<Column type="action">
							<MenuItem
								label="View"
								icon={<FontAwesomeIcon icon={faCode} />}
								onClick={(event, note) => viewNote(note)}
							/>
							<MenuItem
								label="Download"
								icon={<FontAwesomeIcon icon={faDownload} />}
								onClick={(event, note) => saveNoteAsMarkdown(note)}
							/>
							<MenuItem
								label="Delete"
								icon={<FontAwesomeIcon icon={faTrash} />}
								onClick={(event, note) => confirmNoteDeletion(note)}
							/>
						</Column>
					</Table>
				</Card>
			</div>
			<NoteModal
				{...selectedNote}
				isOpen={modalIsOpen}
				handleCloseRequest={() => setModalOpen(false)}
				isReadOnly={!!selectedNote}
				handleNewNote={handleNewNote}
				download={saveNoteAsMarkdown}
			/>
		</div>
	);
}

export default PartnersNote;
