import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartLine, faCopy, faCompressAlt, faTimesCircle, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { serialize } from '@/utils';
import { useAppContext, useApiContext } from '@/providers';
import {
	Card,
	Input,
	TextArea,
	Button,
} from '@/lib/ReactRainbow';
import copyToClipboard from '@/hooks/copyToClipboard';

const initialForm = {
	url: '',
	utm_source: '',
	utm_medium: '',
	utm_campaign: '',
	utm_term: '',
	utm_content: '',
};

function UTMBuilder() {
	const [form, setForm] = useState(initialForm);
	const [completeUrl, setCompleteUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [didCopy, setDidCopy] = useState(false);
	const { displayError } = useAppContext();
	const { getBitlyLink } = useApiContext();
	const bitlyRef = useRef();
	const { url, ...utms } = form;
	const {
		utm_source,
		utm_medium,
		utm_campaign,
		utm_term,
		utm_content,
	} = utms;

	const handleChange = ({ target: { name, value } }) => {
		// no whitespace allowed!
		const formattedValue = value.replace(/\s/g, '_');
		setForm((oldState) => ({ ...oldState, [name]: formattedValue }));
	};

	const clearForm = () => {
		setForm(initialForm);
		setCompleteUrl('');
	};

	const shortenUrl = async () => {
		setIsLoading(true);
		if (bitlyRef.current) {
			bitlyRef.current.abort();
		}
		const getBitlyFetch = getBitlyLink(completeUrl);
		bitlyRef.current = getBitlyFetch;
		try {
			const res = await getBitlyFetch.send();
			setCompleteUrl(res.link);
			setIsLoading(false);
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error);
				setIsLoading(false);
			}
		}
	};

	const handleCopy = () => {
		copyToClipboard(completeUrl);
		setDidCopy(true);
		setTimeout(() => setDidCopy(false), 3000);
	};

	useEffect(() => {
		let finalUrl = '';
		if (url.length) {
			const hasTrailingSlash = url.charAt(url.length - 1) === '/';
			finalUrl += hasTrailingSlash ? url : `${url}/`;
			if (utm_source.length) {
				setCompleteUrl(`${finalUrl}${serialize(utms)}`);
			} else {
				setCompleteUrl(url);
			}
		}
		return () => {
			if (bitlyRef.current) {
				bitlyRef.current.abort();
			}
		};
	}, [form]);

	const isGoodToGo = url.length && utm_source.length && utm_medium.length && utm_campaign.length;

	return (
		<div>
			<Card
				title="UTM Builder"
				icon={<FontAwesomeIcon icon={faChartLine} className="color-primary" />}
				className="py-8 px-3"
			>
				<div className="w-full px-3">
					{/* <p>
						Fill out the required fields in the form below to generate the full campaign URL.
						<br />
						Note: the generated URL is automatically updated as you make changes.
					</p> */}
					<div className="flex flex-col lg:flex-row mt-3">
						<div className="w-full lg:mr-8">
							<Button
								className="block ml-auto mt-5"
								style={{ boxShadow: 'none' }}
								variant="base"
								label={(
									<span>
										<FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
												Clear Form
									</span>
								)}
								onClick={clearForm}
							/>
							<Input
								label="Website URL"
								labelAlignment="left"
								placeholder="https://buddy.insure/"
								name="url"
								value={url}
								onChange={handleChange}
								required
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">The full website URL</div>
							<Input
								label="Campaign Source"
								labelAlignment="left"
								placeholder="mbaa"
								name="utm_source"
								value={utm_source}
								onChange={handleChange}
								required
								className="mt-4"
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">The referrer: (eg: mbaa, google)</div>
							<Input
								label="Campaign Medium"
								labelAlignment="left"
								placeholder="email"
								name="utm_medium"
								value={utm_medium}
								onChange={handleChange}
								required
								className="mt-4"
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">Delivery medium: (e.g. email, website, banner)</div>
							<Input
								label="Campaign Name"
								labelAlignment="left"
								placeholder="january_newsletter"
								name="utm_campaign"
								value={utm_campaign}
								onChange={handleChange}
								required
								className="mt-4"
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">
						Name to identify campaign (e.g. whirltank_whirlwind_checkbox_email)
							</div>
							<Input
								label="Campaign Term"
								labelAlignment="left"
								placeholder="ski+snowboard"
								name="utm_term"
								value={utm_term}
								onChange={handleChange}
								className="mt-4"
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">(Optional) Paid keywords</div>
							<Input
								label="Campaign Content"
								labelAlignment="left"
								placeholder="ski_pic"
								name="utm_content"
								value={utm_content}
								onChange={handleChange}
								className="mt-4"
							/>
							<div className="text-gray-400 text-sm mt-3 pl-4">(Optional) Differentiate between content in same campaign</div>
						</div>
						<div className="w-full">
							<div className="border border-solid border-gray-400 rounded p-3 mt-6 bg-gray-50">
								<h4>Complete URL</h4>
								<TextArea
									className="mt-4"
									grow
									rows={4}
									value={completeUrl}
								/>
								<div className="mt-4 flex flex-col md:flex-row w-full justify-between">
									<Button
										label={didCopy ? (
											<span>
												<FontAwesomeIcon icon={faCheck} className="mr-2" />
												Copied!
											</span>
										)
											: (
												<span>
													<FontAwesomeIcon icon={faCopy} className="mr-2" />
												Copy URL
												</span>
											)}
										disabled={!isGoodToGo}
										onClick={handleCopy}
										className="w-full md:w-auto"
									/>
									<Button
										label={(
											<span>
												<FontAwesomeIcon icon={faCompressAlt} className="mr-2" />
												Convert to Short Link
											</span>
										)}
										disabled={!isGoodToGo}
										onClick={shortenUrl}
										isLoading={isLoading}
										className="w-full mt-4 md:mt-0 md:w-auto"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default UTMBuilder;
