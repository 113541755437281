import React, {
	useState, useEffect, useRef,
} from 'react';
import startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileDownload,
	faFilter,
	faCode,
	faCopy,
	faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import AceEditor from 'react-ace';
import { useApiContext, useAppContext, usePartnerData } from '@/providers';
import {
	Table,
	Column,
	Card,
	Badge,
	Button,
	ButtonIcon,
	MenuItem,
	Modal,
	Pagination,
	Picklist,
	Option,
	DatePicker,
	Spinner,
	RadioButtonGroup,
	HelpText,
	Select,
} from '@/lib/ReactRainbow';
import {
	THEMES,
	ROWS_PER_PAGE,
	DATE_OPTIONS,
	INITIAL_VENUE,
	END_POINT_OPTIONS,
	RESPONSE_OPTIONS,
} from '@/utils';
import copyToClipboard from '@/hooks/copyToClipboard';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import { ErrorBoundary } from '@/components';
import { Label, SectionHeader } from '@/components/UI';
import { IF } from '@/components/utils';

const logViewSettings = {
	dateType: DATE_OPTIONS[1],
	dateRange: [new Date()],
	rowsPerPage: ROWS_PER_PAGE[0].value,
	endPoint: END_POINT_OPTIONS[0],
	selectedVenue: INITIAL_VENUE,
	selectedResponse: RESPONSE_OPTIONS[0].value,
};

const APIFilterModal = (props) => {
	const {
		appState: { partnerData = null },
	} = useAppContext();

	const venues = [INITIAL_VENUE];
	if (partnerData?.venues?.length) {
		venues.push(
			...partnerData?.venues.map((el) => ({
				name: el?.name,
				label: startCase(el?.name),
			})),
		);
	}

	const {
		onSubmit,
		endPoint: endPointSent,
		timeframe,
		startDate,
		endDate,
		isOpen,
		onRequestClose,
		venue,
		response,
		showVenue = true,
		onlyShow = null,
	} = props;

	const starterRange = [startDate || new Date()];
	if (endDate) {
		starterRange.push(endDate);
	}

	const [endPoint, setEndPoint] = useState(endPointSent);
	const [selectedTimeframe, setSelectedTimeframe] = useState(timeframe);
	const [selectedSpecificDate, setSelectedSpecificDate] = useState(startDate || new Date());
	const [selectedRange, setSelectedRange] = useState(starterRange);

	const [selectedVenue, setSelectedVenue] = useState(venue);
	const [selectedResponseOption, setSelectedResponseOption] = useState(
		response,
	);

	const handleOnOpen = () => {
		setEndPoint(endPoint);
		setSelectedTimeframe(timeframe);
		setSelectedSpecificDate(startDate || new Date());
		setSelectedVenue(venue);
		setSelectedResponseOption(response);
		const range = [startDate || new Date()];
		if (endDate) {
			range.push(endDate);
		}
		setSelectedRange(range);
	};

	useEffect(() => {
		switch (selectedTimeframe.name) {
		case 'today':
			setSelectedSpecificDate(new Date());
			break;
		case '48hours':
			setSelectedRange([moment().subtract(1, 'day').toDate(), new Date()]);
			break;
		case 'lastweek':
			setSelectedRange([moment().subtract(6, 'day').toDate(), new Date()]);
			break;
		case 'lasthour':
			setSelectedSpecificDate(moment().subtract(1, 'hour').toDate());
			break;
		case 'last30':
			setSelectedRange([moment().subtract(30, 'day').toDate(), new Date()]);
			break;
		default:
			// nothing
		}
	}, [selectedTimeframe]);

	const handleSubmit = () => {
		onRequestClose(); // this needs to be first for speed
		const submittedObject = {
			endPoint,
			timeframe: selectedTimeframe,
			venue: selectedVenue,
			response: selectedResponseOption,
		};

		if (['specificdate', 'today'].includes(selectedTimeframe.name)) {
			submittedObject.startDate = moment(selectedSpecificDate);
		} else {
			submittedObject.startDate = moment(selectedRange[0]);
			submittedObject.endDate = moment(selectedRange[1]);
		}

		// send it back up
		onSubmit(submittedObject);
	};

	const handleDates = (value) => {
		if (Array.isArray(value)) {
			setSelectedRange(value);
		} else {
			setSelectedSpecificDate(value);
		}
	};

	const handleRestoreDefaults = () => {
		setEndPoint(END_POINT_OPTIONS[0]);
		setSelectedTimeframe(DATE_OPTIONS[0]);
		setSelectedVenue(INITIAL_VENUE);
		setSelectedResponseOption('all');
	};

	return (
		<ErrorBoundary>
			<Modal
				onRequestClose={onRequestClose}
				onOpened={handleOnOpen}
				isOpen={isOpen}
				title="Filter API Calls"
				footer={(
					<ErrorBoundary>
						<div className="flex justify-between">
							<Button label="Cancel" variant="base" onClick={onRequestClose} />
							{!onlyShow && <Button label="Restore Defaults" variant="border-filled" size="small" onClick={handleRestoreDefaults} />}
							<Button label="Submit" variant="success" onClick={handleSubmit} />
						</div>
					</ErrorBoundary>
				)}
			>
				<ErrorBoundary>
					<IF condition={!onlyShow || onlyShow === 'endPoint'}>
						<Label label="API Endpoint" className="ml-4 text-md text-sm" htmlFor="endpoint-selection" />
						<Picklist
							id="endpoint-selection"
							onChange={(value) => setEndPoint(value)}
							value={endPoint}
							label="Endpoint Selection"
							className="mb-8"
							hideLabel
						>
							<Option name="header" label="Endpoint Options" variant="header" />
							{END_POINT_OPTIONS.map((el, i) => (
								<Option key={`endpoint-${el.name}`} name={el.name} label={el.label} />
							))}
						</Picklist>
					</IF>
					<IF condition={!onlyShow || onlyShow === 'dates'}>
						<Label label="Timeframe" className="ml-4 text-md text-sm" htmlFor="timeframe-selection" />
						<Picklist
							id="timeframe-selection"
							onChange={(value) => setSelectedTimeframe(value)}
							value={selectedTimeframe}
							label="Endpoint Selection"
							className="mb-8"
							hideLabel
						>
							<Option name="header" label="Endpoint Options" variant="header" />
							{DATE_OPTIONS.map((el, i) => (
								<Option key={`datetype-${el.name}`} name={el.name} label={el.label} />
							))}
						</Picklist>
					</IF>
					<IF condition={!onlyShow || onlyShow === 'dates'}>
						<Label label={`Specific Date ${selectedTimeframe.name === 'range' ? 'range' : ''}`} className="ml-4 text-md text-sm" htmlFor="datePicker-1" />
						<DatePicker
							className="mb-8"
							hideLabel
							id="datePicker-1"
							value={
								['specificdate', 'today', 'lasthour'].includes(selectedTimeframe.name)
									? selectedSpecificDate
									: selectedRange
							}
							maxDate={new Date()}
							selectionType={
								['specificdate', 'today', 'lasthour'].includes(selectedTimeframe.name)
									? 'single'
									: 'range'
							}
							onChange={(value) => handleDates(value)}
							label="Date"
							formatStyle="large"
							disabled={
								!['specificdate', 'range'].includes(selectedTimeframe.name)
							}
						/>
					</IF>
					<IF condition={(!onlyShow || onlyShow === 'venue') && (showVenue && venues.length > 1)}>
						<Label label="Venue" className="ml-4 text-md text-sm" htmlFor="venue-selection" />
						<Picklist
							id="venue-selection"
							onChange={(value) => setSelectedVenue(value)}
							value={selectedVenue}
							label="Venue Selection"
							className="mb-8"
							hideLabel
						>
							<Option name="header" label="Venue Options" variant="header" />
							{venues.map((el, i) => (
								<Option key={`venue-${el.name}`} {...el} />
							))}
						</Picklist>
					</IF>
					<IF condition={!onlyShow || onlyShow === 'response'}>
						<div className="mx-auto">
							<Label label="Response Status" className="mb-1 ml-4 text-md text-sm block" htmlFor="response-status" />
							<RadioButtonGroup
								id="response-status"
								variant="brand"
								options={RESPONSE_OPTIONS}
								value={selectedResponseOption}
								onChange={(event) => setSelectedResponseOption(event.target.value)}
							/>
						</div>
					</IF>
				</ErrorBoundary>
			</Modal>
		</ErrorBoundary>
	);
};
const style = { width: '250px' };

function PartnerLog({ navigate }) {
	// top level app info from context
	const { displayError } = useAppContext();
	const { partnerAPI } = useApiContext();
	const loadDataRef = useRef();
	// partner information passed through context
	const { partnerData } = usePartnerData();

	// 2 different modals
	const [modalIsOpen, setModalOpen] = useState(false);
	const [filterIsOpen, setFilterIsOpen] = useState(false);

	// used for pagination
	const [activePage, setActivePage] = useState(1);

	// used for filtering data
	const [endPoint, setEndPoint] = useState(logViewSettings.endPoint);
	const [dateType, setDateType] = useState(logViewSettings.dateType);
	const [dateRange, setDateRange] = useState(logViewSettings.dateRange);
	const [rowsPerPage, setRowsPerPage] = useState(logViewSettings.rowsPerPage);
	const [selectedVenue, setSelectedVenue] = useState(logViewSettings.selectedVenue);
	const [selectedResponseOption, setSelectedResponseOption] = useState(logViewSettings.selectedResponse);
	const [onlyShow, setOnlyShow] = useState(null);

	// controls the overall state of the view
	const [isLoading, setIsLoading] = useState(true);

	// dealing with data
	const [selectedCall, setSelectedCall] = useState({});
	const [calls, setCalls] = useState([]);

	const loadData = async () => {
		// figure out what the query is
		const query = {};

		if (partnerData) {
			query.partnerID = partnerData?.id; // always pass partner ID
		}

		if (endPoint.name !== 'all') {
			query.endpoint = endPoint.name;
		}

		if (selectedVenue.name !== 'all') {
			query.venue = selectedVenue.name;
		}

		if (selectedResponseOption !== 'all') {
			query.success = Boolean(selectedResponseOption === 'success');
		}

		if (dateType?.name === 'lasthour') {
			query.startMilliseconds = moment().subtract(1, 'hour').valueOf();
			query.endMilliseconds = moment().valueOf();
		} else if (['today', '48hours', 'lastweek', 'last30'].includes(dateType?.name)) {
			switch (dateType?.name) {
			case 'today':
				query.startDate = moment().format('MM/DD/YYYY');
				break;
			case '48hours':
				query.startDate = moment().subtract(1, 'day').format('MM/DD/YYYY');
				query.endDate = moment().format('MM/DD/YYYY');
				break;
			case 'lastweek':
				query.startDate = moment().subtract(6, 'day').format('MM/DD/YYYY');
				query.endDate = moment().format('MM/DD/YYYY');
				break;
			case 'last30':
				query.startDate = moment().subtract(30, 'day').format('MM/DD/YYYY');
				query.endDate = moment().format('MM/DD/YYYY');
				break;
			default:
				// nothing
			}
		} else {
			query.startDate = moment(dateRange[0]).format('MM/DD/YYYY');
			if (dateRange.length === 2) {
				query.endDate = moment(dateRange[1]).format('MM/DD/YYYY');
			}
		}

		// if a request is still pending, cancel and start a new one.
		if (loadDataRef.current) {
			loadDataRef.current.abort();
		}
		setIsLoading(true);
		try {
			// get a reference to fetch
			const fetchCalls = await partnerAPI.analytics.getCalls(query);
			loadDataRef.current = fetchCalls;

			const results = await fetchCalls.send();
			setCalls(results);
			setIsLoading(false);
			loadDataRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error?.message || error);
				setIsLoading(false);
			}
			loadDataRef.current = null;
		}
	};

	// when filter data is updated, go get that data
	useEffect(() => {
		loadData();
		return () => {
			if (loadDataRef.current) {
				loadDataRef.current.abort();
			}
		};
		// const obj = {
		// 	dateType,
		// 	endPoint,
		// 	dateRange,
		// 	selectedVenue,
		// 	selectedResponse: selectedResponseOption,
		// 	rowsPerPage,
		// };
		// setAppState({ logViewSettings: obj });
	}, [
		endPoint,
		dateType,
		dateRange,
		selectedVenue,
		selectedResponseOption,
		partnerData,
	]);

	// useEffect(() => {
	// 	const obj = {
	// 		dateType,
	// 		endPoint,
	// 		dateRange,
	// 		selectedVenue,
	// 		selectedResponse: selectedResponseOption,
	// 		rowsPerPage,
	// 	};
	// 	setAppState({ logViewSettings: obj });
	// }, [rowsPerPage]);

	// useEffect(() => {
	// 	rowsPerPage = Number(rowsPerPage.name);
	// }, [rowsPerPageOption])

	const getFullData = (id, stringified = false) => {
		const data = calls.find((el) => el.id === id);
		if (stringified) {
			return JSON.stringify(data, null, 2);
		}
		return data;
	};

	const StatusBadge = ({ value, row }) => {
		const variant = value.split(' ')[0];
		if (variant === 'error') {
			const { error } = getFullData(row.id);
			return (
				<div className="flex flex-row my-2">
					<Badge label={value} variant={variant} />
					<HelpText
						variant="error"
						title="Error Message"
						className="ml-2"
						text={<p style={style}>{error}</p>}
					/>
				</div>
			);
		}
		return <Badge label={value} variant={variant} />;
	};

	const viewCall = (data) => {
		const fullData = getFullData(data.id);
		setSelectedCall(fullData);
		setModalOpen(true);
	};

	const copyCall = (data) => {
		copyToClipboard(JSON.stringify(data, null, 2));
	};
	const saveData = (data, filename = 'data.json') => {
		const json = JSON.stringify(data, null, 2);
		const file = new File([json], filename, {
			type: 'text/json;charset=utf-8',
		});
		FileSaver.saveAs(file);
		// saveAs(JSON.stringify(data, null, 2), filename);
	};

	const handleRefresh = () => {
		setActivePage(1);
		loadData();
	};

	const handleFilterUpdate = (values) => {
		const {
			endPoint: apiEndPoint, timeframe, startDate, endDate, venue, response,
		} = values;

		const datesUpdate = [startDate];

		if (endDate) {
			datesUpdate[1] = endDate;
		}
		setActivePage(1); // reset that bad boy
		setEndPoint(apiEndPoint);
		setDateType(timeframe);
		setDateRange(datesUpdate);
		setSelectedVenue(venue);
		setSelectedResponseOption(response);
	};

	const handleJust = (value) => {
		setOnlyShow(value);
		setFilterIsOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	const totalPages = Math.ceil(calls.length / rowsPerPage);
	const pagesOptions = Array.from({ length: totalPages }, (v, i) => ({
		value: i + 1,
		label: String(i + 1),
	}));

	// grab JUST the data we want to display
	// console.log(calls)
	const sliced = isLoading
		? []
		: calls
			.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
			.map((el) => ({
				created: moment(el.created_millisecond).format(
					'MMM Do YYYY, h:mm:ss a',
				),
				endPoint: el.end_point,
				status: `${el.error ? 'error' : 'success'} ${el.response_code}`,
				id: el.id,
				millisecond: el.created_millisecond,
				venue: el.venue || null,
				partnerSlug: el.partner_slug || null,
			}));

	const colors = {
		all: {
			color: '#000',
			background: 'none',
			outline: 'none',
		},
		error: {
			color: '#FFF',
			backgroundColor: THEMES.buddy.rainbow.palette.error,
			outline: 'none',
		},
		success: {
			color: '#000',
			backgroundColor: THEMES.buddy.rainbow.palette.success,
			outline: 'none',
		},
	};
	const isAllResponseTypes = selectedResponseOption !== 'all';

	const endPointCopy = endPoint.name !== 'all'
		? (
			<>
				{' '}
				on the
				{' '}
				<button
					type="button"
					className="font-bold underline"
					onClick={() => handleJust('endPoint')}
				>
					{endPoint.name}
				</button>
				{' '}
				endpoint
				{' '}
			</>
		)
		: (<></>);

	// the date part of the subtitle
	let dateToDisplay = '';
	if (dateType?.name === 'lasthour') {
		dateToDisplay = 'the last hour';
	} else {
		if (dateType?.name === 'sincedate') {
			dateToDisplay = 'since ';
		}
		dateToDisplay += moment(dateRange[0]).format('MM/DD/YYYY');
		if (dateRange.length > 1) {
			dateToDisplay += ` - ${moment(dateRange[1]).format('MM/DD/YYYY')}`;
		}
	}

	return (
		<div className="w-full pt-5">
			<SectionHeader
				className="mb-5 pl-3"
				title="Partner API Call Logs"
				subtitle={(
					<div className="text-sm">
						Viewing
						{' '}
						<button
							type="button"
							onClick={() => handleJust('response')}
							className={classnames('font-bold rounded-3xl', { underline: !isAllResponseTypes, 'px-2': isAllResponseTypes, 'py-0.5': isAllResponseTypes })}
							style={colors[selectedResponseOption]}
						>
							{selectedResponseOption}
						</button>
						{endPointCopy}
						{' '}
							calls for
						{' '}
						<button
							type="button"
							className="font-bold underline outline-none"
							style={{ outline: 'none' }}
							onClick={() => handleJust('dates')}
						>
							{dateToDisplay}
						</button>
							.
					</div>
				)}
				actions={(
					<Button
						variant="neutral"
						title="filter api calls"
						onClick={() => handleJust(null)}
						disabled={isLoading}
					>
						<FontAwesomeIcon
							icon={faFilter}
							className="rainbow-m-right_medium mr-1"
						/>
					Filter Call Logs
					</Button>
				)}
			/>
			<div className="">
				<Card
					className="mx-auto"
					icon={(
						<div className="flex flex-row">
							<span className="flex flex-col text-xl my-auto mr-2">Rows Per Page:</span>
							<Select
								hideLabel
								value={rowsPerPage}
								options={ROWS_PER_PAGE}
								id="pages-select"
								onChange={(event) => setRowsPerPage(event.target.value)}
								className="flex flex-col mr-2"
								variant="shaded"
							/>
						</div>
					)}
					actions={(
						<ButtonIcon
							assistiveText="refresh data button"
							title="Refresh the data"
							type="button"
							icon={<FontAwesomeIcon icon={faRedoAlt} />}
							variant="border"
							size="medium"
							disabled={isLoading}
							className=""
							onClick={handleRefresh}
						/>
					)}
					footer={
						isLoading ? (
							<Spinner className="relative inset-0 mt-6" size="medium" variant="brand" />
						) : (
							<div className="flex justify-between">
								<ButtonIcon
									assistiveText="download json button"
									title="Download calls as JSON"
									type="button"
									icon={<FontAwesomeIcon icon={faFileDownload} />}
									variant="border"
									size="medium"
									disabled={isLoading || calls.length === 0}
									onClick={() => saveData(
										calls,
										`${dateRange
											.map((el) => moment(el).format('MM_DD_YYYY'))
											.join('-')}_${endPoint.name}.json`,
									)}
									className=""
								/>
								{/* Only show pagination when necessary */}
								{calls.length > rowsPerPage && (
									<div className="flex flex-row">
										{totalPages > 5 && (
											<Select
												hideLabel
												value={activePage}
												options={pagesOptions}
												id="pages-select"
												className="mr-2"
											/>
										)}
										<Pagination
											className="my-auto"
											pages={totalPages}
											activePage={activePage}
											onChange={(event, page) => setActivePage(page)}
											disabled={calls.length < rowsPerPage}
										/>
									</div>
								)}
							</div>
						)
					}
				>
					<Table
						pageSize={rowsPerPage}
						data={sliced}
						keyField="id"
						isLoading={isLoading}
						showRowNumberColumn
						rowNumberOffset={(activePage - 1) * rowsPerPage}
						emptyDescription="Try changing the filter settings."
					>
						<Column
							header="Timestamp"
							field="created"
							component={(data) => (
								<Button
									variant="base"
									className="text-sm px-0 hover:underline"
									style={{ border: 'none', boxShadow: 'none' }}
									onClick={() => viewCall(data.row)}
									label={data?.value || null}
								/>
							)}
						/>
						<Column header="Endpoint" field="endPoint" />
						<Column
							header="Status"
							field="status"
							width={160}
							component={StatusBadge}
						/>
						{partnerData?.venues?.length && (
							<Column
								header="Venue"
								field="venue"
								component={({ value }) => (value && startCase(value.name || value)) || '---'}
							/>
						)}
						{!partnerData && (
							<Column
								header="Partner"
								field="partnerSlug"
								component={({ value }) => {
									if (value) {
										return (
											<Button
												variant="base"
												label={value}
												onClick={() => navigate(`/partners/${value}/log`)}
											/>
										);
									}
									return '---';
								}}
							/>
						)}
						<Column type="action">
							<MenuItem
								label="View"
								icon={<FontAwesomeIcon icon={faCode} />}
								onClick={(event, data) => viewCall(data)}
							/>
							<MenuItem
								label="Copy"
								icon={<FontAwesomeIcon icon={faCopy} />}
								onClick={(event, data) => copyCall(getFullData(data.id))}
							/>
							<MenuItem
								label="Download"
								icon={<FontAwesomeIcon icon={faFileDownload} />}
								onClick={(event, data) => saveData(
									getFullData(data.id),
									`${moment(data.millisecond).format('MM_DD_YYYY_h_mm_ss')}_${
										data.endPoint
									}.json`,
								)}
							/>
						</Column>
					</Table>
				</Card>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				title={moment(selectedCall.created_millisecond).format(
					'MMM Do YYYY, h:mm:ss a',
				)}
				footer={(
					<div className="rainbow-flex rainbow-justify_spread">
						<Button
							variant="neutral"
							title="download json file"
							className="rainbow-m-around_medium"
							onClick={() => saveData(
								selectedCall,
								`${moment(selectedCall.created_millisecond).format(
									'MM_DD_YYYY_h_mm_ss',
								)}_${selectedCall.end_point}.json`,
							)}
						>
							<FontAwesomeIcon icon={faFileDownload} className="mr-2" />
							Download JSON
						</Button>
					</div>
				)}
			>
				<AceEditor
					mode="json"
					theme="github"
					className="w-full"
					name="ACE"
					wrapEnabled
					editorProps={{ $blockScrolling: true }}
					value={JSON.stringify(selectedCall, null, 2)}
					readOnly
					style={{ width: '100%' }}
				/>
			</Modal>
			<APIFilterModal
				isOpen={filterIsOpen}
				onRequestClose={() => setFilterIsOpen(false)}
				endPoint={endPoint}
				timeframe={dateType}
				venue={selectedVenue}
				response={selectedResponseOption}
				startDate={dateRange[0]}
				endDate={dateRange[1] || null}
				onSubmit={handleFilterUpdate}
				onlyShow={onlyShow}
				// showVenue={!brand?.value?.isVenue}
			/>
		</div>
	);
}

export default PartnerLog;
