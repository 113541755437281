import { Button, ProgressBar } from '@/lib/ReactRainbow';

function BuilderFooter({
	handleBack,
	handleNext,
	progress,
	hideBack,
	hideNext,
	hideProgress,
	nextButtonLabel = 'Next',
	backButtonLabel = 'Back',
	backButtonVariant = 'outline-brand',
}) {
	return (
		<>
			{hideProgress ? null : (
				<div className="pt-3 pb-5">
					<ProgressBar value={progress} />
				</div>
			)}
			<div
				className={`w-full flex items-center justify-${
					hideBack ? 'end' : 'between'
				}`}
			>
				{hideBack ? null : (
					<Button
						label={backButtonLabel}
						onClick={handleBack}
						variant={backButtonVariant}
					/>
				)}
				{hideNext ? null : (
					<Button label={nextButtonLabel} onClick={handleNext} />
				)}
			</div>
		</>
	);
}

export default BuilderFooter;
