import { forwardRef, useImperativeHandle } from 'react';
import { capitalize, EVENT_TYPES } from '@/utils';
import { StateSelect, CountrySelect } from '@/components/UI';
import DraggableNumberInput, { getPercentageMask } from '@/components/DraggableNumberInput';
import {
	Input, Select, DatePicker,
} from '@/lib/ReactRainbow';

const Details = forwardRef((props, ref) => {
	const { productDetails, updateProduct } = props;
	const {
		event,
		eventType,
		eventStartDate,
		eventEndDate,
		venue,
		country,
		city,
		state,
		didHavePreviousCancellationInsurance,
		previousCancellationInsuranceTakeRate,
	} = productDetails;

	// exposes the checkForErrors function
	useImperativeHandle(ref, () => ({
		checkForErrors: () => {
			if (event.length) {
				return { hasError: false };
			}
			return { hasError: true, error: 'The Event name cannot be left blank.' };
		},
	}));

	const handleChange = ({ target: { name, value, checked } }) => {
		updateProduct({ [name]: value || checked });

		// extras
		if (name === 'didHavePreviousCancellationInsurance' && checked === false) {
			updateProduct({ previousCancellationInsuranceTakeRate: 0 });
		}
	};

	return (
		<>
			<h4>Event</h4>
			<div className="flex flex-col mx-auto">
				<Input
					label="Name"
					labelAlignment="left"
					className="w-full md:w-1/2 mt-5"
					placeholder="Buddy Conference"
					type="text"
					name="event"
					value={event}
					onChange={handleChange}
				/>
				<Select
					label="Type"
					labelAlignment="left"
					className="w-full md:w-1/4 mt-5"
					name="eventType"
					options={EVENT_TYPES.map((el) => ({
						value: el,
						label: capitalize(el.toLowerCase()),
					}))}
					value={eventType}
					onChange={handleChange}
				/>
				<h4 className="mt-12 mb-5 buddy">Dates</h4>
				<div className="flex flex-col md:flex-row">
					<DatePicker
						label="Event Start Date"
						labelAlignment="left"
						className="w-3/4 md:w-1/3 lg:w-1/4 mt-5 md:mt-0"
						name="eventStartDate"
						minDate={new Date()}
						value={eventStartDate}
						onChange={(date) => updateProduct({ eventStartDate: date })}
					/>
					<DatePicker
						label="Event End Date"
						labelAlignment="left"
						className="w-3/4 md:w-1/3 lg:w-1/4 md:ml-5 mt-5 md:mt-0"
						minDate={eventStartDate}
						name="eventEndDate"
						value={eventEndDate}
						onChange={(date) => updateProduct({ eventEndDate: date })}
					/>
				</div>
				<h4 className="mt-12 mb-5 buddy">Location</h4>
				<CountrySelect
					className="mt-5"
					name="country"
					value={country}
					onChange={handleChange}
				/>
				<Input
					label="Venue (optional)"
					labelAlignment="left"
					className="w-full md:w-1/2 lg:w-1/4 mt-5"
					placeholder="Buddy Adventure Center"
					type="text"
					name="venue"
					value={venue}
					onChange={handleChange}
				/>
				<div className="flex flex-col md:flex-row">
					<Input
						label="City"
						labelAlignment="left"
						className="w-full md:w-1/2 lg:1/4 mt-5"
						placeholder="Richmond"
						type="text"
						name="city"
						value={city}
						onChange={handleChange}
					/>
					<StateSelect
						className="mt-5 md:ml-5"
						name="state"
						value={state}
						onChange={handleChange}
					/>
				</div>
				<h4 className="mt-10">Previous Cancellation Insurance Information</h4>
				<Input
					className="mt-5"
					name="didHavePreviousCancellationInsurance"
					type="checkbox"
					label="Had refund cancellation insurance previously"
					onChange={handleChange}
					checked={didHavePreviousCancellationInsurance}
				/>
				<label className="text-base mt-5 block">Previous Insurance Take Rate</label>
				<DraggableNumberInput
					className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl px-2 w-1/3 md:w-1/6 lg:w-1/12"
					mask={didHavePreviousCancellationInsurance
						? getPercentageMask
						: []}
					max={100}
					min={0}
					value={previousCancellationInsuranceTakeRate}
					disabled={!didHavePreviousCancellationInsurance}
					onChange={(value) => handleChange({
						target: {
							name: 'previousCancellationInsuranceTakeRate',
							value,
						},
					})}
				/>
			</div>
		</>
	);
});

export default Details;
