import React, {
	useState, useEffect,
} from 'react';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import AceEditor from 'react-ace';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileDownload,
} from '@fortawesome/free-solid-svg-icons';

import { PARTNER_REPORTS, DEMO_BUDDIES } from '@/utils';
import { usePartnerData } from '@/providers';
import {
	SectionHeader,
} from '@/components/UI';
import GeneralInfo from '@/components/PartnerInfoCards/GeneralInfo';
import PartnerPortalInfo from '@/components/PartnerInfoCards/PartnerPortalInfo';
import InternalInfo from '@/components/PartnerInfoCards/InternalInfo';
import {
	Button,
	Card,
	TableWithBrowserPagination,
	Column,
	Modal,
} from '@/lib/ReactRainbow';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const toReact = (data) => {
	if (!data || !Object.keys(data).length) {
		return {};
	}
	const returnData = cloneDeep(data);
	returnData.partnerReports = PARTNER_REPORTS.filter((report) => returnData?.partnerReports?.includes(report.id))
		.map((report) => ({
			icon: null,
			label: report.title,
			name: `option-${report.id}`,
			value: report.id,
		}));

	returnData.buddies = DEMO_BUDDIES.filter((buddy) => returnData?.buddies?.includes(buddy.id))
		.map((buddy) => ({
			icon: null,
			label: buddy.title,
			name: `option-${buddy.id}`,
			value: buddy.id,
		}));

	return returnData;
};

const scrollToTop = () => window?.scrollTo({
	top: 0,
});

function PartnersInfo({
	onUpdate = () => {}, isLoading = false,
}) {
	// CONTEXTS
	const partnerData = toReact(usePartnerData().partnerData); // didn't destructure, because needed to alter

	// STATES
	const [selectedEventLog, setSelectedEventLog] = useState({});
	const [eventLogModalOpen, setEventLogModalOpen] = useState(false);

	const viewLogEntry = (data) => {
		setSelectedEventLog(data);
		setEventLogModalOpen(true);
	};

	const saveData = (data, filename = 'data.json') => {
		const json = JSON.stringify(data, null, 2);
		const file = new File([json], filename, {
			type: 'text/json;charset=utf-8',
		});
		FileSaver.saveAs(file);
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	const partnerEventLog = partnerData?.log.reverse() || [];

	return (
		<div className="mt-4">
			<GeneralInfo
				onUpdate={onUpdate}
			/>
			<PartnerPortalInfo
				onUpdate={onUpdate}
			/>
			<InternalInfo
				onUpdate={onUpdate}
			/>

			{/* EVENT LOG FOR PARTNER */}
			<SectionHeader
				title="Event Log"
				className="mt-5 ml-4 border-b-2"
				size="md"
			/>
			<div className="w-full pl-4 mt-4 pb-24">
				<Card className="mx-auto w-full mt-3">
					<TableWithBrowserPagination
						pageSize={10}
						paginationAlignment="right"
						data={partnerEventLog}
						keyField="timestamp"
						emptyTitle="Whoops!"
						emptyDescription="If the partner was created, that should at least show. Ping the dev team and let them know."
						isLoading={isLoading}
						showRowNumberColumn
					>
						<Column
							header="Type"
							field="type"
						/>
						<Column
							header="When"
							field="timestampMilliseconds"
							component={(data) => (
								<Button
									variant="base"
									className="text-sm px-0 hover:underline"
									label={moment(data?.value).format('MMM Do YYYY, h:mm:ss a')}
									onClick={() => viewLogEntry(data.row)}
								/>
							)}
						/>
						<Column
							header="Who did it"
							field="user"
							component={(data) => (
								<div
									variant="base"
									className="px-0 text-small"
								>
									{data?.value?.name}
								</div>
							)}
						/>
					</TableWithBrowserPagination>
				</Card>
			</div>
			<Modal
				isOpen={eventLogModalOpen}
				onRequestClose={() => setEventLogModalOpen(false)}
				title={`Event Log for: ${moment(selectedEventLog?.timestampMilliseconds).format('MMM Do YYYY, h:mm:ss a')}`}
				footer={(
					<div className="rainbow-flex rainbow-justify_spread">
						<Button
							variant="neutral"
							title="download json file"
							className="rainbow-m-around_medium"
							onClick={() => saveData(
								selectedEventLog,
								`event-log-${moment(selectedEventLog.timestampMilliseconds).format(
									'MM_DD_YYYY_h_mm_ss',
								)}.json`,
							)}
						>
							<FontAwesomeIcon icon={faFileDownload} className="mr-2" />
							Download JSON
						</Button>
					</div>
				)}
			>
				<AceEditor
					mode="json"
					theme="github"
					className="w-full"
					name="ACE"
					wrapEnabled
					editorProps={{ $blockScrolling: true }}
					value={JSON.stringify(selectedEventLog, null, 2)}
					readOnly
					style={{ width: '100%' }}
				/>
			</Modal>
		</div>
	);
}

export default PartnersInfo;
