import React from 'react';

const SectionHeader = ({
	title, subtitle, children, className, actions, style, size = '3xl',
}) => (
	<div className={className} style={style}>
		<div className="flex flex-row justify-between">
			<div className="flex flex-col self-end">
				<div className={`text-${size} font-bold capitalize`}>{title}</div>
				{subtitle && <div className="text-lg font-hairline">{subtitle}</div>}
			</div>
			<div className="flex flex-col">{actions}</div>
		</div>
		{children}
	</div>
);

export default SectionHeader;
