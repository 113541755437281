import React, {
	useState, useEffect, useContext, useRef,
} from 'react';
import moment from 'moment-timezone';
import groupBy from 'lodash/groupBy';
import FileSaver from 'file-saver';
import domToImage from 'dom-to-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Numbers } from '@buddy-technology/buddy_helpers';
import {
	faEye,
	faCashRegister,
	faDollarSign,
	faFileDownload,
	faPercentage,
	faFilter,
	faAward,
	faCommentDollar,
	faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
	useApiContext, helpers, PartnerContext, getReportData, REPORTS,
} from '@/providers';
import {
	Card,
	Button,
	Modal,
	ButtonIcon,
} from '@/lib/ReactRainbow';

import { DataDisplay, DateFilterModal } from '@/components';
import { SectionHeader } from '@/components/UI';

import {
	arrayToCSV,
	DEFAULT_DATE_FORMAT,
	DATE_OPTIONS,
	INITIAL_VENUE,
} from '@/utils';

import ErrorBoundary from '@/components/ErrorBoundary';

const dashboardFilterSettings = {
	dateType: DATE_OPTIONS[1],
	dateRange: [new Date()],
	selectedVenue: INITIAL_VENUE,
};

const ModalReportHeader = ({ title, icon }) => (
	<span className="ml-5 mt-5">
		<FontAwesomeIcon icon={icon} className="text-3xl mr-4" />
		<span className="text-3xl uppercase">{title}</span>
	</span>
);

function PartnersDashboard() {
	const { partnerAPI } = useApiContext();

	// partner information passed through context
	const { partnerData } = useContext(PartnerContext);

	const [modalInfo, setModalInfo] = useState(null);
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [dateType, setDateType] = useState(dashboardFilterSettings.dateType);
	const [dateRange, setDateRange] = useState(dashboardFilterSettings.dateRange);
	const [selectedVenue, setSelectedVenue] = useState(
		dashboardFilterSettings.selectedVenue,
	);

	// const stagingID = partnerData?.stagingID;
	// const partner = partnerData?.id || null;
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	// used for the report modal
	const [shouldShowPicDownload, setShouldShowPicDownload] = useState(false);

	const loadDataRef = useRef();

	const screenshot = async (filename = 'test.png') => {
		const node = document?.getElementById('graph-display');
		const blob = await domToImage.toBlob(node, { bgcolor: '#ffffff' });
		// eslint-disable-next-line no-undef
		window.saveAs(blob, filename);
	};

	const loadData = async () => {
		setIsLoading(true);
		// figure out what the query is
		const query = {
			startDate: moment(dateRange[0]).format(DEFAULT_DATE_FORMAT),
			endPoint: 'order,post:order,quote,post:quote,order/batch,quote/batch',
			success: true,
		};

		if (partnerData) {
			query.partnerID = partnerData?.id; // always pass partner ID
		}

		// condition below checks staging data because we can't isolate slug by environment
		if (partnerData?.slug) {
			// if we have a slug, let's make our query check for both ID and slug
			query.partner = `${partnerData.id},${partnerData.slug}`;
		}

		if (selectedVenue && selectedVenue.name !== 'all') {
			query.venue = selectedVenue.name;
		}

		if (dateType?.name === 'lasthour') {
			query.startMilliseconds = moment().subtract(1, 'hour').valueOf();
			query.endMilliseconds = moment().valueOf();
		} else if (['today', '48hours', 'lastweek', 'last30'].includes(dateType?.name)) {
			switch (dateType?.name) {
			case 'today':
				query.startDate = moment().format(DEFAULT_DATE_FORMAT);
				break;
			case '48hours':
				query.startDate = moment().subtract(1, 'day').format(DEFAULT_DATE_FORMAT);
				query.endDate = moment().format(DEFAULT_DATE_FORMAT);
				break;
			case 'lastweek':
				query.startDate = moment().subtract(6, 'day').format(DEFAULT_DATE_FORMAT);
				query.endDate = moment().format(DEFAULT_DATE_FORMAT);
				break;
			case 'last30':
				query.startDate = moment().subtract(30, 'day').format(DEFAULT_DATE_FORMAT);
				query.endDate = moment().format(DEFAULT_DATE_FORMAT);
				break;
			default:
					// nothing
			}
		} else {
			query.startDate = moment(dateRange[0]).format(DEFAULT_DATE_FORMAT);
			if (dateRange.length === 2) {
				query.endDate = moment(dateRange[1]).format(DEFAULT_DATE_FORMAT);
			}
		}
		if (loadDataRef.current) {
			loadDataRef.current.abort();
		}
		try {
			const getCalls = await partnerAPI.analytics.getCalls(query);
			loadDataRef.current = getCalls;

			const results = await getCalls.send();
			setData(results);
			setIsLoading(false);
			loadDataRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setIsLoading(false);
			}
			loadDataRef.current = null;
		}
	};

	const handleRefresh = () => {
		loadData();
	};

	// when filter data is updated or test data is toggled, go get that data
	useEffect(() => {
		loadData();
		return () => {
			if (loadDataRef.current) {
				loadDataRef.current.abort();
			}
		};
	}, [selectedVenue, dateRange, partnerData]);

	const saveCSV = (type) => {
		const { rawData } = getReportData(type, data);
		const csv = arrayToCSV(rawData);
		const filename = `${type}.csv`;
		const file = new File([csv], filename, {
			type: 'text/csv;charset=utf-8',
		});
		FileSaver.saveAs(file);
	};

	const openDetails = ({ title, type }) => {
		const newModalInfo = { title, ...getReportData(type, data), report: type };
		setShouldShowPicDownload(false);
		setModalInfo(newModalInfo);
	};

	const handleFilterUpdate = (values) => {
		const {
			venue, timeframe, startDate, endDate,
		} = values;
		const datesUpdate = [startDate];

		if (endDate) {
			datesUpdate[1] = endDate;
		}
		setSelectedVenue(venue);
		setDateType(timeframe);
		setDateRange(datesUpdate);
	};

	let dateToDisplay = moment(dateRange[0]).format(DEFAULT_DATE_FORMAT);
	if (dateRange.length > 1) {
		dateToDisplay += ` - ${moment(dateRange[1]).format(DEFAULT_DATE_FORMAT)}`;
	}

	// are we checking a venue?
	if (selectedVenue && selectedVenue.name !== 'all') {
		dateToDisplay += ` at ${selectedVenue.label}`;
	}

	// calculate the reports info
	const reportInfo = {};
	if (data) {
		// make sure venueName is root level (this is also cleanup/legacy)
		data.forEach((call) => {
			if (call.venue) {
				Object.assign(call, { venueName: call?.payload?.venue?.name || call.venue });
			}
		});
		reportInfo.orders = helpers.getTrueCount(data, 'order');
		reportInfo.quotes = helpers.getTrueCount(data, 'quote');
		reportInfo.salesDollars = partnerAPI.analytics.calls.premiumCollected(data);
		reportInfo.marketingFeeEarned = reportInfo.salesDollars * 0.125;
		reportInfo.quoteToOrderRatio =
			reportInfo.orders / reportInfo.quotes;

		reportInfo.ordersByVenue = groupBy(
			partnerAPI.analytics.calls.ofEndpoint(data, ['order', 'order/batch']),
			'venueName',
		);
		reportInfo.ordersByPartner = groupBy(
			partnerAPI.analytics.calls.ofEndpoint(data, ['order', 'order/batch']),
			'partner_slug',
		);

		const venueSorter = (a, b) => (
			helpers.getTrueCount(reportInfo.ordersByVenue[a], 'order') < helpers.getTrueCount(reportInfo.ordersByVenue[b], 'order')
				? 1
				: -1
		);

		reportInfo.sortedOrdersByVenue = Object.keys(reportInfo.ordersByVenue).sort(venueSorter);

		// eslint-disable-next-line max-len
		const partnerSorter = (a, b) => (
			helpers.getTrueCount(reportInfo.ordersByPartner[a], 'order') < helpers.getTrueCount(reportInfo.ordersByPartner[b], 'order')
				? 1
				: -1
		);

		reportInfo.sortedOrdersByPartner = Object.keys(reportInfo.ordersByPartner).sort(partnerSorter);
	}

	const cards = [
		{
			id: 'ordersPlaced',
			title: 'Orders Placed',
			icon: faCashRegister,
			reportType: REPORTS.ORDERS_PLACED,
			display: reportInfo?.orders || 0,
			disableButtons: reportInfo?.orders === 0,
		},
		{
			id: 'salesDollars',
			title: 'Written Premium',
			icon: faDollarSign,
			reportType: REPORTS.SALES_DOLLARS,
			display: Numbers.toUSD(reportInfo?.salesDollars || 0),
			disableButtons: reportInfo?.salesDollars === 0,
		},
		{
			id: 'marketingFeeEarned',
			title: 'Marketing Fee Earned',
			icon: faCommentDollar,
			reportType: REPORTS.MARKETING_FEE_EARNED,
			display: Numbers.toUSD(reportInfo?.marketingFeeEarned || 0),
			disableButtons: reportInfo?.marketingFeeEarned === 0,
		},
		{
			id: 'quoteToOrderRatio',
			title: 'Quote to Order Ratio',
			icon: faPercentage,
			reportType: REPORTS.QUOTE_TO_ORDER_RATIO,
			// eslint-disable-next-line no-restricted-globals
			display: isFinite(reportInfo?.quoteToOrderRatio)
				? (
					<div className="flex flex-row justify-between w-full">
						<div className="mr-auto my-auto">
							{Numbers.toPercentage(reportInfo?.quoteToOrderRatio || 0)}
						</div>
						<div className="px-4 py-2 rounded-xl text-2xl border border-dashed border-buddy-red ml-auto my-auto">
							{`${reportInfo.orders}/${reportInfo.quotes}`}
						</div>
					</div>
				)
				: 'N/A',
			// eslint-disable-next-line no-restricted-globals
			disableButtons: !isFinite(reportInfo?.quoteToOrderRatio),
		},
		{
			id: 'leadVenueBySales',
			title: 'Top Venues by Sales',
			icon: faAward,
			reportType: REPORTS.LEAD_VENUE_BY_SALES,
			display: reportInfo?.sortedOrdersByVenue?.length
				? (
					<div className="flex flex-row justify-between w-full mt-0 mb-1">
						<div className="mr-auto my-auto text-2xl overflow-hidden flex flex-col w-full">
							{
								reportInfo?.sortedOrdersByVenue.slice(0, 5).map((venue, i) => (
									<div>
										<div className="w-full flex justify-between pr-5 items-center mb-1 ">
											<p>{venue}</p>
											<p className="text-2xl  ml-auto ">
												{helpers.getTrueCount(reportInfo.ordersByVenue[reportInfo?.sortedOrdersByVenue[i]], 'order')}
												{' '}
										orders
												{'\n'}
											</p>
										</div>
										<hr className="divide-black" />
									</div>

								))
							}
						</div>
					</div>
				)
				:	'N/A',
			disableButtons: reportInfo?.sortedOrdersByVenue?.length === 0,
		},
		{
			id: 'leadPartnerBySales',
			title: 'Top Partners by Sales',
			icon: faAward,
			reportType: REPORTS.LEAD_PARTNER_BY_SALES,
			display: reportInfo?.sortedOrdersByPartner?.length
				? (
					<div className="flex flex-row justify-between w-full mt-1.5 mb-1">
						<div className="mr-auto my-auto text-2xl overflow-y-auto flex flex-col w-full">
							{
								reportInfo?.sortedOrdersByPartner.slice(0, 5).map((partner, i) => (
									<div>
										<div className="w-full flex justify-between pr-5 items-start text-md">
											<p>{partner}</p>
											<p className=" text-2xl ml-auto">
												{helpers.getTrueCount(reportInfo.ordersByPartner[reportInfo?.sortedOrdersByPartner[i]], 'order')}
												{' '}
										orders
												{'\n'}
											</p>
										</div>
										<hr />
									</div>
								))
							}
						</div>
					</div>
				)
				:	'N/A',
			disableButtons: reportInfo?.sortedOrdersByPartner?.length === 0,
		},
	];

	const cardsToShow = () => {
		const defaultReports = [
			'ordersPlaced',
			'salesDollars',
			'quoteToOrderRatio',
		];
		const reportsToShow = [...defaultReports];

		if (!partnerData) {
			reportsToShow.push('leadPartnerBySales');
		} else {
			reportsToShow.push('leadVenueBySales');
		}

		return cards.filter(({ id }) => reportsToShow.includes(id));
	};

	return (
		<ErrorBoundary>
			<div className="flex flex-col pt-5">
				<SectionHeader
					className="mb-5 pl-3"
					title="Reports"
					subtitle={(
						<div className="text-sm">
							Viewing
							{' '}
							<strong>{dateToDisplay}</strong>
							.
						</div>
					)}
					actions={(
						<div className="flex flex-row">
							<ButtonIcon
								assistiveText="refresh data button"
								title="Refresh the data"
								type="button"
								icon={<FontAwesomeIcon icon={faRedoAlt} />}
								variant="border"
								size="medium"
								disabled={isLoading}
								onClick={handleRefresh}
							/>
							<Button
								variant="neutral"
								title="filter api calls"
								className="ml-3"
								onClick={() => setFilterIsOpen(true)}
								disabled={isLoading}
							>
								<FontAwesomeIcon
									icon={faFilter}
									className="rainbow-m-right_medium mr-1"
								/>
								Filter Reports
							</Button>
						</div>
					)}
				/>
				<div className="flex flex-row flex-wrap w-full justify-between">
					{cardsToShow().map((card) => (
						<div key={`card-${card.id}`} className="px-2 sm:pr-0 w-full lg:w-1/2 mb-5">
							<Card
								id={`card-${card.id}`}
								title={card.title}
								isLoading={isLoading}
								icon={(
									<FontAwesomeIcon
										icon={card.icon || faAward}
										className="rainbow-m-right_medium"
									/>
								)}
								className="w-full"
								footer={(
									<div className="flex justify-between w-full">
										<Button
											variant="neutral"
											title="filter api calls"
											onClick={() => openDetails({
												title: (
													<ModalReportHeader
														title={card.title}
														icon={card.icon}
													/>
												),
												type: card.reportType,
											})}
											disabled={isLoading || card.disableButtons}
										>
											<FontAwesomeIcon
												icon={faEye}
												className="rainbow-m-right_medium mr-1"
											/>
											View Details
										</Button>
										<Button
											variant="neutral"
											title="filter api calls"
											onClick={() => saveCSV(card.reportType)}
											disabled={isLoading || card.disableButtons}
										>
											<FontAwesomeIcon
												icon={faFileDownload}
												className="rainbow-m-right_medium mr-1"
											/>
											Download CSV
										</Button>
									</div>
								)}
							>
								<div className="text-6xl pb-8 pr-8 pl-8 pt-5 -mt-5 max-h-28 overflow-y-scroll show-scroll	">{card.display}</div>
							</Card>
						</div>
					))}
				</div>

				<Modal
					isOpen={!!modalInfo}
					onRequestClose={() => setModalInfo(null)}
					title={modalInfo?.title || ''}
					size="large"
					className="with-table"
					footer={(
						<div className="flex justify-between">
							<Button
								variant="neutral"
								title="download csv file"
								className="rainbow-m-around_medium"
								onClick={() => saveCSV(modalInfo.report)}
							>
								<FontAwesomeIcon icon={faFileDownload} className="mr-2" />
								Download CSV
							</Button>
							{shouldShowPicDownload && (
								<Button
									variant="neutral"
									title="download csv file"
									className="rainbow-m-around_medium"
									onClick={() => screenshot(modalInfo.report)}
								>
									<FontAwesomeIcon icon={faFileDownload} className="mr-2" />
									Download Screenshot
								</Button>
							)}
						</div>
					)}
				>
					<DataDisplay {...modalInfo} canTakePic={setShouldShowPicDownload} />
				</Modal>
				<DateFilterModal
					isOpen={filterIsOpen}
					onRequestClose={() => setFilterIsOpen(false)}
					timeframe={dateType}
					venue={selectedVenue}
					startDate={dateRange[0]}
					endDate={dateRange[1] || null}
					onSubmit={handleFilterUpdate}
					partnerData={partnerData}
					// showVenue={!brand?.value?.isVenue}
				/>
			</div>
		</ErrorBoundary>
	);
}

export default PartnersDashboard;
