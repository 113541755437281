import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinner,
	faCheck,
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Chip } from '@/lib/ReactRainbow';

function StatusBadge({ status }) {
	const badgeDetails = {
		message: 'Not Saved',
		variant: 'neutral',
		icon: null,
	};
	if (status === 'in-progress') {
		badgeDetails.icon = faSpinner;
		badgeDetails.message = 'Saving';
		badgeDetails.variant = 'warning';
	}
	if (status === 'success') {
		badgeDetails.icon = faCheck;
		badgeDetails.message = 'Saved';
		badgeDetails.variant = 'success';
	}
	if (status === 'error') {
		badgeDetails.icon = faExclamationTriangle;
		badgeDetails.message = 'Save Error';
		badgeDetails.variant = 'error';
	}
	return (
		<Chip
			className="mr-5"
			variant={badgeDetails.variant}
			label={(
				<div className="flex items-center justify-between">
					{badgeDetails?.icon
				&& (
					<FontAwesomeIcon
						icon={badgeDetails.icon}
						className={`mr-3${status === 'in-progress' ? ' spin' : ''}`}
					/>
				)}
					<div>{badgeDetails.message}</div>
				</div>
			)}
		/>
	);
}

export default StatusBadge;
