import React, { useContext } from 'react';
import { navigate } from '@reach/router';
// import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faPowerOff,
	faPencilAlt,
	faBars,
	// faSwatchbook,
} from '@fortawesome/free-solid-svg-icons';
import { properCase } from '@buddy-technology/buddy_helpers/';
import { AppContext } from '@/providers';
import { AvatarMenu, MenuDivider, MenuItem /* Modal */ } from '@/lib/ReactRainbow';
import { useWindowSize } from '@/hooks/hooks';
import { IF } from '@/components/utils';
// import ErrorBoundary from './ErrorBoundary';

// const Profile = loadable(() => import('./Profile'));

const Header = ({ toggleMenu }) => {
	const {
		appState: { authData },
		setAppState,
	} = useContext(AppContext);
	const { email } = authData?.attributes;
	// const [modalOpen, setModal] = useState(false);
	// const [demoSettingsOpen, setDemoSettingsOpen] = useState(false);
	const { width } = useWindowSize();
	const signOut = () => {
		try {
			authData.signOut();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		setAppState({ authData: null, intendedRoute: null });
		navigate('/login');
	};

	let env = 'dev';
	if (window.location.hostname === 'bump.buddy-testing.insure') {
		env = 'testing';
	}
	if (window.location.hostname.includes('staging')) {
		env = 'staging';
	}
	if (window.location.hostname === 'bump.buddyinsurance.com') {
		env = 'production';
	}

	return (
		<header className="header">
			{/* <Modal
				title="Profile Settings"
				isOpen={modalOpen}
				onRequestClose={() => setModal(false)}
				size="large"
				style={{ maxHeight: 'none' }}
			>
				<ErrorBoundary>
					<Profile />
				</ErrorBoundary>
			</Modal> */}

			<div className="flex w-full items-center">
				{width <= 768 ? (
					<FontAwesomeIcon
						icon={faBars}
						onClick={toggleMenu}
						size="2x"
						color="#999b9e"
					/>
				) : (
					<div className="flex items-center">
						<img
							src="/buddy_lettermark_R.svg"
							alt="buddy"
							style={{ width: '3.2rem' }}
						/>
						<h2 className="ml-5 mb-0">
      				BUMP
						</h2>
						<IF condition={env !== 'production'}>
							<h4 className="ml-3 mb-0">{`(${properCase(env)})`}</h4>
						</IF>
					</div>
				)}
			</div>
			<div className="flex justify-end w-full items-center">
				{/* <BadgeOverlay value={2}>
					<AvatarMenu
						id="notification-menu"
						icon={<FontAwesomeIcon icon={faBell} />}
						assistiveText="Notifications"
						menuAlignment="right"
						menuSize="small"
						avatarSize="large"
						title="Account Settings"
					>
						<h4 className="text-center">Notifications</h4>
						<MenuDivider variant="space" />
						<MenuItem
							label="New Report for Sales."
							icon={<FontAwesomeIcon icon={faBell} />}
							iconPosition="left"
						/>
						<MenuItem
							label="Updated documentation."
							icon={<FontAwesomeIcon icon={faBell} />}
							iconPosition="left"
						/>
					</AvatarMenu>
				</BadgeOverlay> */}
				<AvatarMenu
					className="ml-4"
					id="account-menu"
					icon={<FontAwesomeIcon icon={faUser} />}
					style={{ width: 52, height: 52 }}
					assistiveText="Account Settings"
					menuAlignment="right"
					menuSize="small"
					avatarSize="large"
					title="Profile Settings"
				>
					<div className="pl-3">
						<div className="">{email}</div>
					</div>
					<MenuDivider variant="space" />
					<MenuItem
						label="Edit Profile"
						icon={<FontAwesomeIcon icon={faPencilAlt} />}
						iconPosition="left"
					/>
					<MenuItem
						label="Logout"
						icon={<FontAwesomeIcon icon={faPowerOff} />}
						iconPosition="left"
						onClick={signOut}
					/>
				</AvatarMenu>
			</div>
		</header>
	);
};

export default Header;
