import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	// faClipboardList,
	faUserFriends,
	faHandshake,
	// faChartLine,
	// faCogs,
	faTerminal,
	faTools,
} from '@fortawesome/free-solid-svg-icons';
import {
	VerticalNavigation,
	VerticalItem,
	VerticalSection,
} from '@/lib/ReactRainbow';
import ErrorBoundary from '@/components/ErrorBoundary';
import Header from '@/components/Header';

function DashboardSideNav({ children, navigate, location }) {
	const [showMenu, setMenu] = useState(false);
	const navSelected = (event, val) => {
		setMenu(false);
		navigate(val);
	};
	const topLevelSelected = `/${location.pathname.split('/')[1]}`;
	return (
		<>
			<ErrorBoundary>
				<Header toggleMenu={() => setMenu(!showMenu)} />
			</ErrorBoundary>
			<div
				className={`react-rainbow-admin-app_sidebar-container${
					showMenu ? '' : ' sidebar-hidden'
				}`}
			>
				<ErrorBoundary>
					<VerticalNavigation
						id="vertical-navigation-11"
						className="h-full flex flex-col justify-self-start"
						style={{ width: '200px' }}
						selectedItem={topLevelSelected}
						onSelect={navSelected}
					>
						<VerticalSection>
							<VerticalItem
								name="/customers"
								icon={<FontAwesomeIcon icon={faUserFriends} />}
								label={<span className="ml-2 text-base block">Customers</span>}
							/>
							<VerticalItem
								name="/partners"
								icon={<FontAwesomeIcon icon={faHandshake} />}
								label={<span className="ml-2 text-base block">Partners</span>}
							/>
							{/* <VerticalItem
								name="/reports"
								icon={<FontAwesomeIcon icon={faChartLine} />}
								label={<span className="ml-2 text-base block">Reports</span>}
							/> */}
							<VerticalItem
								name="/developers"
								icon={<FontAwesomeIcon icon={faTerminal} />}
								label={<span className="ml-2 text-base block">Developers</span>}
							/>
							{/* <VerticalItem
								name="/products"
								disabled
								icon={<FontAwesomeIcon icon={faClipboardList} />}
								label={<span className="ml-2 text-base block">Products</span>}
							/> */}
							<VerticalItem
								name="/tools"
								icon={<FontAwesomeIcon icon={faTools} />}
								label={<span className="ml-2 text-base block">Tools</span>}
							/>
							{/* <VerticalItem
								name="/settings"
								icon={<FontAwesomeIcon icon={faCogs} />}
								label={<span className="ml-2 text-base block">Settings</span>}
							/> */}
						</VerticalSection>
					</VerticalNavigation>
				</ErrorBoundary>
			</div>
			<div className="react-rainbow-admin-app_router-container">
				<div className="px-3 bg-gray-100">
					<ErrorBoundary>{children}</ErrorBoundary>
				</div>
			</div>
		</>
	);
}

export default DashboardSideNav;
