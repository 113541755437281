/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

export const getGenericMask = (rawValue) => {
	const numbers = rawValue.match(/\d/g);
	if (!numbers) {
		return [];
	}
	const numberString = numbers.join('');
	return [/[1-9]/, ...Array(numberString.length - 1).fill(/\d/)];
};

export const getPercentageMask = (rawValue) => {
	const numbers = rawValue.match(/\d/g);
	if (!numbers) {
		return [];
	}
	const numberString = numbers.join('');
	switch (numberString.length) {
	case 1:
		return [/[1-9]/, '%'];
	case 2:
		return [/[1-9]/, /\d/, '%'];
	default:
		return [/[1-9]/, /\d/, /\d/, '%'];
	}
};

export const getMoneyMask = (rawValue) => {
	const numbers = rawValue.match(/\d/g);
	if (!numbers) {
		return [];
	}
	const numberString = numbers.join('');
	const digitsArray = [/[1-9]/, ...Array(numberString.length - 1).fill(/\d/)].reverse();
	let cleanArray = [];
	const chunkSize = 3;
	for (let i = 0; i < digitsArray.length; i += chunkSize) {
		cleanArray = cleanArray.concat(...digitsArray.slice(i, i + chunkSize));
		cleanArray.push(',');
	}
	cleanArray.pop();

	return ['$', ...cleanArray.reverse()];
};

const DraggableNumberInput = ({
	direction = 'vertical',
	onChange = () => {},
	className = '',
	style = {},
	value: defaultValue = 0,
	min = 0,
	max = 100,
	mask = [],
	placeholder = '95%',
	readOnly = false,
	disabled = false,
	speed = 1,
}) => {
	const [isDragging, setIsDragging] = useState(false);
	const [startPosition, setStartPosition] = useState(null);
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (defaultValue !== value) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	const manualChange = (event) => {
		let numToSend = Number(String(event.target.value).replace(/\D/g, ''));
		if (numToSend > max) {
			numToSend = max;
		}
		const finalValue = numToSend > min ? numToSend : min;
		setValue(finalValue);
		onChange(finalValue);
	};

	const onDragHandler = ({ yChange, xChange }) => {
		const change = direction === 'vertical' ? yChange : xChange;
		let numToSend = Math.floor(value + ((change / 2) * speed));
		if (numToSend > max) {
			numToSend = max;
		}
		const finalValue = numToSend > min ? numToSend : min;
		setValue(finalValue);
		onChange(finalValue);
	};

	const mouseMoveHandler = (event) => {
		const { screenX, screenY } = startPosition;
		const {
			screenX: newX, screenY: newY, movementX, movementY,
		} = event;
		onDragHandler({
			xChange: newX - screenX,
			yChange: screenY - newY,
			movementX,
			movementY,
		});
	};

	const mouseUpHandler = () => {
		setIsDragging(false);
		document.removeEventListener('mouseup', mouseUpHandler);
		document.removeEventListener('mousemove', mouseMoveHandler);
		document.body.style.cursor = 'default';
	};

	const mouseDownHandler = (event) => {
		if (disabled) {
			return;
		}
		const { screenX, screenY } = event;
		setStartPosition({ screenX, screenY });
		setIsDragging(true);
		document.body.style.cursor = direction === 'vertical' ? 'ns-resize' : 'ew-resize';
	};

	useEffect(() => {
		if (isDragging) {
			// the mouseup event
			document.addEventListener(
				'mouseup',
				mouseUpHandler,
				{ once: true },
			);

			// the mousemove event
			document.addEventListener(
				'mousemove',
				mouseMoveHandler,
			);
		} else {
			onChange(value);
		}

		// clean up
		return () => {
			document.removeEventListener('mouseup', mouseUpHandler);
			document.removeEventListener('mousemove', mouseMoveHandler);
		};
	}, [isDragging]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			onMouseDown={mouseDownHandler}
			className="flex flex-col"
		>
			{isDragging
			&& (
				<div
					style={{
						cursor: direction === 'vertical' ? 'ns-resize' : 'ew-resize',
						zIndex: 99999,
						position: 'fixed',
						top: 0,
						left: 0,
						height: '100%',
						width: '100%',
					}}
				/>
			)}
			<MaskedInput
				mask={mask}
				className={className}
				placeholder={placeholder}
				type="text"
				value={value}
				onChange={manualChange}
				style={({ ...style, minWidth: 70 })}
				readOnly={isDragging || readOnly}
				disabled={disabled}
			/>
		</div>
	);
};

export default DraggableNumberInput;
