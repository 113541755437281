import { useState } from 'react';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlus,
	faThumbsDown,
	faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { getInitials } from '@/utils';
import { usePartnerData } from '@/providers';
import {
	Avatar,
	Table,
	Column,
	Card,
	Button,
	Input,
} from '@/lib/ReactRainbow';
import NewUserModal from '@/components/NewPartnerUserModal';
import { SectionHeader } from '@/components/UI';

function PartnersUsers() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { partnerData } = usePartnerData();
	const users = partnerData?.users;

	const avatarSmall = { minWidth: 40 };

	return (
		<>
			<div className="w-full pt-5">
				<SectionHeader
					className="mb-5 pl-3"
					title="Users"
					actions={(
						<Button
							variant="neutral"
							size="small"
							onClick={() => setIsModalOpen(true)}
							label={(
								<>
									<FontAwesomeIcon icon={faPlus} className="mr-3" />
									Add User
								</>
							)}
						/>
					)}
				/>
			</div>
			<div className="w-full">
				<Card className="mx-auto w-full mt-3">
					<Table
						data={users}
						keyField="email"
						emptyDescription={(
							<div>
								Try
								<Button
									variant="base"
									className="px-1"
									onClick={() => setIsModalOpen(true)}
									style={{ boxShadow: 'none' }}
									label="adding a new user."
								/>
							</div>
						)}
					>
						<Column
							header="Name"
							field="name"
							defaultWidth={250}
							component={(data) => (
								<div className="flex items-center py-2">
									<Avatar
										assistiveText={data?.value}
										initials={getInitials(data?.value)}
										title={data?.value}
										style={avatarSmall}
									/>
									<Button
										variant="base"
										label={data?.value}
										onClick={() => navigate(`users/${data?.row?.email}`)}
										className="hover:underline capitalize"
									/>
								</div>
							)}
						/>
						<Column
							header="Title"
							field="role"
							component={(data) => (
								<div className="flex items-center py-2">
									<Input
										readOnly
										value={data?.value}
									/>
								</div>
							)}
						/>
						<Column
							header="Email"
							field="email"
							component={(data) => (
								<div className="flex items-center py-2">
									<Button
										variant="base"
										className="px-0 hover:underline"
										label={data?.value}
										onClick={() => navigate(`users/${data?.row?.email}`)}
									/>
								</div>
							)}
						/>
						<Column
							header="Portal Enabled"
							field="hasPortalAccount"
							cellAlignment="center"
							width={140}
							component={(data) => (
								(data?.value
									? (<FontAwesomeIcon icon={faThumbsUp} className="text-buddy-green text-xl mt-5" />)
									: (<FontAwesomeIcon icon={faThumbsDown} className="text-buddy-red text-xl mt-5" />)
								)
							)}
						/>
					</Table>
				</Card>
			</div>
			<NewUserModal isOpen={isModalOpen} handleCloseRequest={() => setIsModalOpen(false)} />
		</>
	);
}

export default PartnersUsers;
