import React, {
	useState, useEffect,
} from 'react';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase';
import {
	Button,
	DatePicker,
	Modal,
	Picklist,
	Option,
} from '@/lib/ReactRainbow';

import { Label } from '@/components/UI';
import {
	DATE_OPTIONS,
	INITIAL_VENUE,
} from '@/utils';

const DateFilterModal = ({
	onSubmit,
	endPoint,
	timeframe,
	startDate = Date.now(),
	endDate,
	isOpen,
	onRequestClose,
	venue,
	showVenue = true,
	partnerData = {},
}) => {
	const venues = [INITIAL_VENUE];

	if (partnerData?.venues?.length) {
		venues.push(
			...partnerData?.venues.map((el) => ({
				name: el?.name,
				label: startCase(el?.name),
				value: el?.name,
			})),
		);
	}

	const starterRange = [startDate];
	if (endDate) {
		starterRange.push(endDate);
	}

	const [selectedEndpoint, setSelectedEndpoint] = useState(endPoint);
	const [selectedTimeframe, setSelectedTimeframe] = useState(timeframe);
	const [selectedSpecificDate, setSelectedSpecificDate] = useState(startDate);
	const [selectedRange, setSelectedRange] = useState(starterRange);
	const [selectedVenue, setSelectedVenue] = useState(venue);

	const handleOnOpen = () => {
		setSelectedEndpoint(endPoint);
		setSelectedTimeframe(timeframe);
		setSelectedSpecificDate(startDate || new Date());
		setSelectedVenue(venue);
		const range = [startDate || new Date()];
		if (endDate) {
			range.push(endDate);
		}
		setSelectedRange(range);
	};

	useEffect(() => {
		switch (selectedTimeframe.name) {
		case 'today':
			setSelectedSpecificDate(new Date());
			break;
		case '48hours':
			setSelectedRange([moment().subtract(1, 'day').toDate(), new Date()]);
			break;
		case 'lastweek':
			setSelectedRange([moment().subtract(6, 'day').toDate(), new Date()]);
			break;
		case 'lasthour':
			setSelectedSpecificDate(moment().subtract(1, 'hour').toDate());
			break;
		case 'last30':
			setSelectedRange([moment().subtract(30, 'day').toDate(), new Date()]);
			break;
		default:
			// nothing
		}
	}, [selectedTimeframe]);

	const handleSubmit = () => {
		onRequestClose(); // this needs to be first for speed
		const submittedObject = {
			endPoint: selectedEndpoint,
			timeframe: selectedTimeframe,
			venue: selectedVenue,
		};

		if (['specificdate', 'today'].includes(selectedTimeframe.name)) {
			submittedObject.startDate = moment(selectedSpecificDate).toDate();
		} else {
			submittedObject.startDate = moment(selectedRange[0]).toDate();
			submittedObject.endDate = moment(selectedRange[1]).toDate();
		}

		// send it back up
		onSubmit(submittedObject);
	};

	const handleDates = (value) => {
		if (Array.isArray(value)) {
			setSelectedRange(value);
		} else {
			setSelectedSpecificDate(value);
		}
	};

	const handleRestoreDefaults = () => {
		setSelectedTimeframe(DATE_OPTIONS[0]);
		setSelectedVenue(INITIAL_VENUE);
	};

	return (
		<Modal
			onRequestClose={onRequestClose}
			onOpened={handleOnOpen}
			isOpen={isOpen}
			title="Filter Reports"
			footer={(
				<div className="flex justify-between">
					<Button label="Cancel" variant="base" onClick={onRequestClose} />
					<Button
						label="Restore Defaults"
						variant="border-filled"
						size="small"
						onClick={handleRestoreDefaults}
					/>
					<Button label="Submit" variant="success" onClick={handleSubmit} />
				</div>
			)}
		>
			<>
				<Label label="Timeframe" className="ml-4 text-md text-sm" htmlFor="timeframe-selection" />
				<Picklist
					id="timeframe-selection"
					onChange={(value) => setSelectedTimeframe(value)}
					value={selectedTimeframe}
					label="Endpoint Selection"
					className="mb-8"
					hideLabel
				>
					<Option name="header" label="Endpoint Options" variant="header" />
					{DATE_OPTIONS.map((el) => (
						<Option
							key={`datetype-${el.name}`}
							name={el.name}
							label={el.label}
						/>
					))}
				</Picklist>

				<Label htmlFor="datePicker-1" label={`Specific Date ${selectedTimeframe.name === 'range' ? 'range' : ''}`} className="ml-4 text-md text-sm" />
				<DatePicker
					className="mb-8"
					hideLabel
					id="datePicker-1"
					value={
						['specificdate', 'today', 'lasthour'].includes(
							selectedTimeframe.name,
						)
							? selectedSpecificDate
							: selectedRange
					}
					maxDate={new Date()}
					selectionType={
						['specificdate', 'today', 'lasthour'].includes(
							selectedTimeframe.name,
						)
							? 'single'
							: 'range'
					}
					onChange={(value) => handleDates(value)}
					label="Date"
					formatStyle="large"
					disabled={!['specificdate', 'range'].includes(selectedTimeframe.name)}
				/>
				{showVenue && venues.length > 1 && (
					<>
						<label className="mt-5 pl-5" htmlFor="timeframe-selection">
							Venue
						</label>
						<Picklist
							id="venue-selection"
							onChange={(value) => setSelectedVenue(value)}
							value={selectedVenue}
							label="Venue Selection"
							className="mb-8"
							hideLabel
						>
							<Option name="header" label="Venue Options" variant="header" />
							{venues.map((el) => (
								<Option key={`venue-${el.name}`} {...el} />
							))}
						</Picklist>
					</>
				)}
			</>
		</Modal>
	);
};

export default DateFilterModal;
