import { Numbers, formatToStandardDate } from '@buddy-technology/buddy_helpers';

const productToMarkdown = (details) => {
	const linebreak = `***

`;
	const TBD = '*TBD*';
	let md = `# **Event Name**: ${details.event}  
### **Dates**: ${formatToStandardDate(details?.eventStartDate)} - ${formatToStandardDate(details?.eventEndDate)}\n
#### Buddy offering ID: \`${details.id}\`
`;

	if (details?.organizer?.organization?.length) {
		md += `## Organizer
#### ${details.organizer?.organization}`;
		if (details.organizer?.name) {
			md += `#### Contact
${details?.organizer?.name}`;
			md += details?.organizer?.phone ? `${details?.organizer?.phone}\n` : '';
			md += details?.organizer?.email ? `${details?.organizer?.email}\n` : '';
			md += details?.organizer?.city ? `${details?.organizer?.city}, ${details?.organizer?.state}\n` : '';
			md += details?.organizer?.country ? `${details?.organizer?.country}\n` : '';
		}
		md += linebreak;
	}

	md += `#### Event Type: ${details?.eventType?.toUpperCase()}
#### Location:`;
	md += `${details?.venue}\n`;
	md += details?.city ? `${details?.city}, ${details?.state}\n` : '';
	md += details?.country ? `${details?.country}\n` : '';
	md += linebreak;
	md += `## Registration Details

#### **Registration Dates**: ${formatToStandardDate(details?.registrationStartDate)} - ${formatToStandardDate(details?.registrationEndDate)}\n`;
	md += `#### **Attendance**: ${(details?.attendance || []).join('-')}\n`;
	md += `#### **Average # of tickets per booking**: ${details?.avgTicketsPerBooking || TBD}\n`;
	md += `#### **Maximum # of tickets per booking**: ${details?.maxTicketsPerBooking || TBD}\n`;
	md += linebreak;
	md += `## Ticket Pricing

| Band | Percentage |
| ---- | ---- |\n`;
	details.ticketPricing.forEach((band) => {
		md += `| ${(band?.priceRange || []).map((val) => Numbers.toUSD(val, 2)).join('-')} | ${band?.percentage}%\n`;
	});

	md += linebreak;
	if (details?.ageDemographics?.length) {
		md += `## Demographic Breakdown

| Age Range | Percentage |
| ---- | ---- |\n`;
		details.ageDemographics.forEach((band) => {
			md += `| ${band.ages.join('-')} | ${band.percentage}%\n`;
		});
		md += linebreak;
	}

	md += `## Refund Policy
(TBD)Copy of text, or "see attached PDF" if one is uploaded.\n`;

	md += `#### % of registration cost refunded: ${details?.refundPolicy?.percentage}\n`;
	md += linebreak;
	md += `## Previous Cancellation Insurance Experience
### Has offered cancellation insurance in the past: ${details?.didHavePreviousCancellationInsurance ? 'YES' : 'NO'}
### Take rate: ${details?.didHavePreviousCancellationInsurance
		? `${details?.previousCancellationInsuranceTakeRate}%`
		: 'N/A'}
${linebreak}
## Underwriting Info
#### Loss Ratio: ${details?.underwriting?.lossRatio
		? `${details?.underwriting?.lossRatio}%`
		: TBD}
#### Per Ticket Cap: ${details?.underwriting?.perTicketCap
		? Numbers.toUSD(details?.underwriting?.perTicketCap)
		: TBD}
#### Max Loss For Event: ${details?.underwriting?.maxLossForEvent
		? Numbers.toUSD(details?.underwriting?.maxLossForEvent)
		: TBD}\n
${linebreak}`;

	md += `## Draft Rate Table
| State | Rate | Min Premium | Commission |
| ---- | ---- | ---- | ---- |\n`;
	Object.keys(details?.rates || {}).forEach((state) => {
		const rate = Numbers.toPercentage(details?.rates[state]);
		const minPremium = Numbers.toUSD(details?.minimumPremiums[state]);
		const commission = Numbers.toPercentage(details.commissions[state]);
		md += `| ${state} | ${rate} | ${minPremium} | ${commission} |\n`;
	});

	md += `
## Projected Written Premium Estimate (FPO for now)
#### Policies Sold: 103
#### Written Premium: $12,508.92\n
***
\n`;

	md += `#### Generated By [casey@iambuddy.com](mailto:casey@iambuddy.com?subject=Regarding%20Offering%20TEST_123) on ${formatToStandardDate(new Date())}.
### `;

	md += '[![Powered by Buddy](https://buddy-img.s3.amazonaws.com/powered-by/horizontal_black_medium.png)](https://buddy.insure)';

	return md;
};

export default productToMarkdown;
