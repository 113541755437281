import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon, Button, ColorPicker } from '@/lib/ReactRainbow';
import { HelperLabel } from '@/components/UI';
import { IF } from '@/components/utils';
import { PALETTES } from '@/utils';

const ColorEditor = ({
	helperText, label, value, className, setColor, name, palette,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const enforceColorStructure = (val) => {
		if (typeof val === 'string') {
			return { hex: val };
		}
		return val;
	};
	const changeColor = (color) => {
		setColor(name, color);
	};
	const handleEditClick = () => {
		setIsEditing(true);
	};
	const handleAccept = () => {
		setIsEditing(false);
	};
	const handleCancel = () => {
		setColor(name, { hex: PALETTES[palette][name] });
		setIsEditing(false);
	};

	const fallback = PALETTES[palette]?.[name];

	const getButtonDisplay = (val) => {
		if (val?.rgba && Array.isArray(val.rgba)) {
			return `rgba(${val.rgba.join(',')})`;
		}
		if (val?.hex) {
			return val.hex;
		}
		return val || fallback;
	};

	return (
		<div className={className}>
			<HelperLabel label={label} helperText={helperText} />
			<IF condition={!isEditing}>
				<Button variant="base" onClick={handleEditClick}>
					{getButtonDisplay(value)}
					<FontAwesomeIcon icon={faPen} className="ml-2" size="xs" />
				</Button>
			</IF>
			<IF condition={isEditing}>
				<ColorPicker value={enforceColorStructure(value)} onChange={changeColor} defaultColors={[]} />
				<div className="my-3 flex justify-end">
					<ButtonIcon
						icon={<FontAwesomeIcon icon={faTimes} />}
						className="color-primary"
						onClick={handleCancel}
					/>
					<ButtonIcon
						icon={<FontAwesomeIcon icon={faCheck} />}
						className="color-success"
						onClick={handleAccept}
					/>
				</div>
			</IF>
		</div>
	);
};

export default ColorEditor;
