import { Input, DatePicker } from '@/lib/ReactRainbow';
import { RangeSlider } from '@/components/UI';

function Registration({ productDetails, updateProduct }) {
	const {
		attendance = [500, 1000],
		registrationStartDate,
		registrationEndDate,
		avgTicketsPerBooking,
		maxTicketsPerBooking,
	} = productDetails;

	const handleChange = ({ target: { name, value } }) => {
		updateProduct({ [name]: value });
	};

	const handleAttendanceChange = (value) => {
		updateProduct({ attendance: value });
	};

	return (
		<>
			<h4>Attendance</h4>
			<div className="mt-5">
				<label className="rainbow-label">Expected Attendance (min - max)</label>
				<RangeSlider
					max={90000}
					min={500}
					name="attendance"
					values={attendance}
					onChange={handleAttendanceChange}
					step={500}
					className="px-3"
				/>
				<label className="rainbow-label mt-5 block">
        Average # of Tickets Per Booking (optional)
				</label>
				<Input
					labelAlignment="left"
					className="w-1/3 md:w-1/6 lg:w-1/12"
					placeholder="3"
					type="number"
					min={0}
					name="avgTicketsPerBooking"
					value={avgTicketsPerBooking}
					onChange={handleChange}
				/>
				<label className="rainbow-label mt-5 block">
        Maximum Tickets Per Booking (optional)
				</label>
				<Input
					labelAlignment="left"
					className="w-1/3 md:w-1/6 lg:w-1/12"
					placeholder="10"
					type="number"
					min={0}
					name="maxTicketsPerBooking"
					value={maxTicketsPerBooking}
					onChange={handleChange}
				/>
			</div>

			<h4 className="mt-12 mb-5">Registration Window</h4>
			<div className="flex flex-col md:flex-row">
				<DatePicker
					label="Registration Start Date"
					labelAlignment="left"
					className="w-3/4 md:w-1/3 lg:w-1/4 mt-5 md:mt-0"
					value={registrationStartDate}
					onChange={(date) => updateProduct({ registrationStartDate: date })}
				/>
				<DatePicker
					label="Registration End Date"
					labelAlignment="left"
					className="w-3/4 md:w-1/3 lg:w-1/4 md:ml-5 mt-5 md:mt-0"
					minDate={registrationStartDate}
					value={registrationEndDate}
					onChange={(date) => updateProduct({ registrationEndDate: date })}
				/>
			</div>
		</>
	);
}

export default Registration;
