import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@/lib/ReactRainbow';
import { SectionHeader } from '@/components/UI';

function ComingSoon({ location: { pathname } }) {
	return (
		<div className="pt-5">
			<SectionHeader title={pathname.slice(1)} className="ml-5 mb-5" />
			<div className="flex justify-center items-center w-full">
				<Card className="p-5 w-full">
					<h4 className="text-center">
          Sorry, we're still working on this one.
						{' '}
						<br />
          Come back soon!
					</h4>
					<FontAwesomeIcon
						icon={faTools}
						className="color-blue my-16 mx-auto block"
						size="6x"
					/>
				</Card>
			</div>
		</div>
	);
}

export default ComingSoon;
