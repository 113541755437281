import {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';
import { CheckboxToggle } from '@/lib/ReactRainbow';
import { RangeSlider, ProgressIndicator } from '@/components/UI';
import DraggableNumberInput, { getPercentageMask } from '@/components/DraggableNumberInput';

import { IF } from '../../utils';

const defaultAgeDemographics = [
	{ ages: [18, 34], percentage: 25 },
	{ ages: [35, 50], percentage: 25 },
	{ ages: [50, 64], percentage: 25 },
	{ ages: [65, 79], percentage: 25 },
];

const AgeRange = ({
	range = [0, 18],
	percentage = 0,
	maxPercentage = 100,
	onRangeChange,
	onInputChange,
	removeRange,
}) => (
	<div className="mb-5">
		<label className="rainbow-label">Age Range</label>
		<RangeSlider
			max={100}
			min={0}
			values={range}
			onChange={onRangeChange}
			step={1}
		/>
		<label className="rainbow-label mt-5 block">Percentage</label>
		<DraggableNumberInput
			className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl px-2 w-1/3 md:w-1/6 lg:w-1/12"
			mask={getPercentageMask}
			max={maxPercentage}
			min={0}
			value={percentage}
			onChange={onInputChange}
		/>
		<button
			className="block ml-auto color-primary"
			type="button"
			onClick={removeRange}
		>
			<FontAwesomeIcon icon={faTrash} className="block ml-auto color-primary" />
		</button>
		<hr className="border-t border-solid border-gray-300 my-5" />
	</div>
);

const Demographics = forwardRef((props, ref) => {
	const { productDetails, updateProduct } = props;
	const { ageDemographics } = productDetails;
	const [ageBreakdowns, setAgeBreakdowns] = useState(ageDemographics || []);

	const [hasAgeData, setHasAgeData] = useState(!!ageDemographics?.length);

	useEffect(() => {
		if (!hasAgeData) {
			setAgeBreakdowns([]);
		} else if (!ageBreakdowns?.length) {
			setAgeBreakdowns(cloneDeep(defaultAgeDemographics));
		}
	}, [hasAgeData]);

	// exposes the checkForErrors function
	useImperativeHandle(ref, () => ({
		checkForErrors: () => {
			if (!ageBreakdowns.length) {
				return { hasError: false };
			}

			const percentageCoverage = ageBreakdowns.reduce(
				(value, currentBand) => value + Number(currentBand.percentage),
				0,
			);

			if (percentageCoverage === 100) {
				return { hasError: false };
			}

			return { hasError: true, error: 'Age bands percentage must add up to 100%.' };
		},
	}));

	const handleInputChange = (value, i) => {
		const newBreakdowns = [...ageBreakdowns];
		newBreakdowns[i].percentage = value;
		setAgeBreakdowns(newBreakdowns);
	};

	const handleRangeChange = (values, i) => {
		const newRanges = [...ageBreakdowns];
		newRanges[i].ages = values;
		const isLast = i === ageBreakdowns.length - 1;
		const maxOverlapsNextRange =
			!isLast && ageBreakdowns[i].ages[1] >= ageBreakdowns[i + 1].ages[0];
		const isFirst = i === 0;
		const minOverlapsPrevRange =
			!isFirst && ageBreakdowns[i].ages[0] <= ageBreakdowns[i - 1].ages[1];
		if (!isLast && maxOverlapsNextRange) {
			newRanges[i + 1].ages[0] = values[1] + 1;
		}
		if (!isFirst && minOverlapsPrevRange) {
			newRanges[i - 1].ages[1] = values[0] - 1;
		}
		// fix any issues where min is greater than max.
		const cleanedRanges = newRanges.map((el) => {
			const [min, max] = el.ages;
			if (min >= max) {
				const newMax = min + 4 >= 100 ? 100 : min + 4;
				const newAges = [min, newMax];
				return { ...el, ages: newAges };
			}
			return el;
		});
		setAgeBreakdowns(cleanedRanges);
	};

	const addRange = () => {
		// we're assuming there's room at the end of th ranges here.
		const latest = ageBreakdowns[ageBreakdowns.length - 1].ages[1];
		const newAges = [latest >= 99 ? 99 : latest + 1, 100];
		const newBreakdown = { ages: newAges, percentage: 0 };
		setAgeBreakdowns((curr) => [...curr, newBreakdown]);
	};

	const removeRange = (i) => {
		if (ageBreakdowns.length <= 1) {
			return;
		}
		const newRanges = [...ageBreakdowns];
		newRanges.splice(i, 1);
		setAgeBreakdowns(newRanges);
	};

	// when local state updates, update the context to keep them in sync
	useEffect(() => {
		updateProduct({ ageDemographics: ageBreakdowns });
	}, [ageBreakdowns]);

	const percentageCoverage = ageBreakdowns.reduce(
		(value, currentBand) => value + Number(currentBand.percentage),
		0,
	);

	return (
		<>
    Providing an estimated breakdown of the age ranges of your attendees may
    help us better determine pricing for cancellation insurance. Click the
    toggle below to add age data.
			<div className="my-8">
				<CheckboxToggle
					label="Use age data"
					value={hasAgeData}
					onChange={() => setHasAgeData(!hasAgeData)}
				/>
			</div>
			{hasAgeData ? (
				<>
        Use the sliders below to adjust the age ranges and add the percentages
        below each. Delete ranges by clicking the 'trash' icon and add ranges
        by clicking 'Add a range' below.
					<IF condition={ageBreakdowns.length > 1}>
						<ProgressIndicator
							className="mt-8 mb-8"
							label="Total Age-Range Coverage:"
							percent={percentageCoverage}
						/>
					</IF>
					{ageBreakdowns.map((el, i) => {
						// the following function looks over ALL bands EXCEPT the current one to figure out the max available percentage for each band
						const availablePercentage = 100 - ageBreakdowns.filter((_, j) => j !== i).reduce(
							(value, currentBand) => value + Number(currentBand.percentage),
							0,
						);
						return (
							<AgeRange
							// it's ok to disable the no-index rule here, as the array won't reorder and we don't have stable IDs.
							// eslint-disable-next-line react/no-array-index-key
								key={`bdf-${i}`}
								range={el.ages}
								percentage={el.percentage}
								maxPercentage={availablePercentage}
								onRangeChange={(values) => handleRangeChange(values, i)}
								onInputChange={(value) => handleInputChange(value, i)}
								removeRange={() => removeRange(i)}
							/>
						);
					})}
					<button
						onClick={addRange}
						type="button"
						className="mt-5 block ml-auto color-blue"
					>
						<FontAwesomeIcon icon={faPlusCircle} />
						{' '}
          Add a range
					</button>
				</>
			) : null}
		</>
	);
});

export default Demographics;
