import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCopy, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@/lib/ReactRainbow';
import copyToClipboard from '@/hooks/copyToClipboard';

function CopyToClipboard({
	label,
	whatToCopy = null,
	display = null,
	className = '',
	style = {},
	hideIcon = false,
}) {
	return (
		<button
			type="button"
			className={`${className}`}
			style={style}
			onClick={() => copyToClipboard(whatToCopy || label)}
		>
			{label}
			{!hideIcon && <FontAwesomeIcon icon={faCopy} className="ml-2" />}
		</button>
	);
}

export const CopyButton = ({
	label = 'Copy',
	whatToCopy = null,
	className,
	style,
	variant,
	disabled,
}) => {
	const [didCopy, setDidCopy] = useState(false);

	const handleCopy = () => {
		copyToClipboard(whatToCopy);
		setDidCopy(true);
		setTimeout(() => setDidCopy(false), 3000);
	};

	return (
		<Button
			label={didCopy ? (
				<span>
					<FontAwesomeIcon icon={faCheck} className="mr-2" />
					Copied!
				</span>
			)
				: (
					<span>
						<FontAwesomeIcon icon={faCopy} className="mr-2" />
						{label}
					</span>
				)}
			disabled={disabled}
			onClick={handleCopy}
			className={className}
			style={style}
			variant={variant}
		/>
	);
};

export default CopyToClipboard;
