import { useState, useRef, useEffect } from 'react';
import difference from 'lodash/difference';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faKey,
	faTimes, faTrash, faUser,
	faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useAppContext, useApiContext, usePartnerData } from '@/providers';
import {
	Accordion,
	AccordionSection,
	Avatar,
	MultiSelect,
	Option,
	Chip,
	CheckboxToggle,
	ButtonIcon,
} from '@/lib/ReactRainbow';
import { SubmitRequestModal } from '@/components/UI';
import { IF } from '@/components/utils';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const convertNameValueArrayToObject = (arr) => (
	arr.reduce((runningValue, currentElement) => ({ ...runningValue, [currentElement.Name]: currentElement.Value }), {})
);

const ControlSection = ({ hideDivider, children }) => (
	<div className="my-3">
		<div className="flex w-full items-center">
			{children}
		</div>
		<IF condition={!hideDivider}>
			<hr className="my-3" />
		</IF>
	</div>
);

const disabledButtonIconClass = 'text-gray-300 border-gray-300 hover:text-gray-300 hover:border-gray-300 focus:text-gray-300 focus:border-gray-300 cursor-not-allowed';

const CognitoDetails = ({ data }) => {
	if (!data) {
		return null;
	}
	let formattedData;
	const { UserAttributes } = data;
	if (UserAttributes) {
		const attributes = convertNameValueArrayToObject(UserAttributes);
		formattedData = {
			...data,
			...attributes,
		};
		delete formattedData.UserAttributes;
	}
	return (
		<>
			{Object.keys(formattedData).map((key, i) => {
				let val = Object.values(formattedData)[i];
				if (Array.isArray(val)) {
					val = JSON.stringify(val, null, 2);
				} else if (typeof val === 'boolean') {
					val = val.toString();
				} else if (typeof val === 'object') {
					val = JSON.stringify(val);
				}
				return (
					<ControlSection key={`${key}-${val}`}>
						<span className="mr-auto">{key}</span>
						<span>{val}</span>
					</ControlSection>
				);
			})}
		</>
	);
};

const CognitoControl = ({
	type,
	handleCloseRequest,
	isOpen,
	partnerID,
	user,
	updateUserInCore,
}) => {
	if (!['customer', 'partner'].includes(type)) {
		// let the devs know, but don't freak out.
		// eslint-disable-next-line no-console
		console.error(new Error('type must be either \'customer\' or \'partner\'.'));
	}
	const [isLoading, setIsLoading] = useState(false);
	const [isUserLoading, setUserLoading] = useState(false);
	const [userData, setUserData] = useState();
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [errorMsg, setErrorMsg] = useState();
	const [isEditingGroups, setEditingGroups] = useState(false);
	const [isEnabledStatusLoading, setIsEnabledStatusLoading] = useState(false);
	const [isMFAStatusLoading, setIsMFAStatusLoading] = useState(false);
	const [isGroupsLoading, setIsGroupsLoading] = useState(false);
	const [isResetLoading, setIsResetLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(false);

	const networkCallRef = useRef();
	const getGroupsRef = useRef();

	const { cognitoGroups: groups } = usePartnerData();
	const { displayConfirmation } = useAppContext();
	const { authAPI } = useApiContext();

	const isCustomer = type === 'customer';
	const isPartner = type === 'partner';
	const canEnableSMS = (!!(userData?.UserAttributes?.find((el) => el.Name === 'phone_number'))
		&& !!(userData?.UserAttributes?.find((el) => el.Name === 'phone_number_verified')));
	// cognito is inconsistent with how they store mfa details >:[
	const isSMSMfaEnabled = !!(userData?.UserMFASettingList?.includes('SMS_MFA')
	|| userData?.MFAOptions?.find((el) => el.DeliveryMedium === 'SMS'));

	const { name, phone, email } = user;

	const addUserToGroup = async (group) => {
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
		try {
			const addGroupFetch = await authAPI.addToGroup({ id: email, group, type });
			networkCallRef.current = addGroupFetch;

			const res = await addGroupFetch.send();
			networkCallRef.current = null;
			return res;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
			}
			return Promise.reject(error);
		}
	};

	const removeGroupsFromUser = async (group) => {
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
		try {
			const addGroupFetch = await authAPI.removeFromGroup({ id: email, group, type });
			networkCallRef.current = addGroupFetch;

			const res = await addGroupFetch.send();
			networkCallRef.current = null;
			return res;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
			}
			return Promise.reject(error);
		}
	};

	// get user
	const getUser = async (isRefresh) => {
		const setLoading = isRefresh ? setIsRefreshing : setUserLoading;
		setLoading(true);
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
		try {
			const getUserData = await authAPI.get(user?.email, type);
			networkCallRef.current = getUserData;
			if (isCustomer) {
				const data = await getUserData.send();
				setUserData(data);
			} else {
				const getGroups = await authAPI.listGroupsForUser(user?.email, type);
				getGroupsRef.current = getGroups;
				const [data, userGroups] = await Promise.all([
					getUserData.send(), getGroups.send(),
				]);
				data.groups = userGroups.map((el) => el.GroupName);
				setUserData(data);
				setSelectedGroups(data.groups);
				getGroupsRef.current = null;
			}
			networkCallRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setErrorMsg(error?.message || error);
			}
			networkCallRef.current = null;
		}
		setLoading(false);
	};

	const createUser = async (options) => {
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
		try {
			const createUserFetch = await authAPI.create({ type, ...options });
			networkCallRef.current = createUserFetch;

			const res = await createUserFetch.send();
			networkCallRef.current = null;
			await addUserToGroup(selectedGroups);
			return res;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
			}
			return Promise.reject(error);
		}
	};

	const abortNetworkCalls = () => {
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
	};

	const handleCreateUser = async () => {
		if (!selectedGroups.length) {
			setErrorMsg('You must select at least one group.');
			return;
		}
		setIsLoading(true);
		setErrorMsg(null);
		try {
			const body = {
				email,
				partnerID,
				name,
				phone,
				email_verified: true,
			};
			await createUser(body);
			await updateUserInCore({ id: email, updates: { hasPortalAccount: true } });
			setIsLoading(false);
			handleCloseRequest();
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
				networkCallRef.current = null;
				setIsLoading(false);
				setErrorMsg(error?.message || error);
			}
		}
	};

	const enableOrDisableUser = async () => {
		setIsEnabledStatusLoading(true);
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}

		try {
			const toggleUserFetch = userData.Enabled
				? await authAPI.disableUser(user?.email, type)
				: await authAPI.enableUser(user?.email, type);
			networkCallRef.current = toggleUserFetch;

			await toggleUserFetch.send();
			setUserData((oldState) => ({ ...oldState, Enabled: !oldState.Enabled }));
			networkCallRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
				setErrorMsg(error?.message || error);
			}
		}
		setIsEnabledStatusLoading(false);
	};

	const toggleSMSMfa = async () => {
		setIsMFAStatusLoading(true);
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}

		try {
			const toggleUserFetch = isSMSMfaEnabled
				? await authAPI.disableSMSMfa(user?.email, type)
				: await authAPI.enableSMSMfa(user?.email, type);
			networkCallRef.current = toggleUserFetch;

			await toggleUserFetch.send();
			const updatedData = { ...userData };
			let MFAOptions = userData?.MFAOptions || [];
			if (!isSMSMfaEnabled) {
				MFAOptions.push({
					DeliveryMedium: 'SMS',
					AttributeName: 'phone_number',
				});
			} else {
				MFAOptions = MFAOptions.filter((el) => !el.DeliveryMedium === 'SMS');
				delete updatedData.PreferredMfaSetting;
				delete updatedData.UserMFASettingList;
			}
			updatedData.MFAOptions = MFAOptions;
			setUserData(updatedData);
			networkCallRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				networkCallRef.current = null;
				setErrorMsg(error?.message || error);
			}
		}
		setIsMFAStatusLoading(false);
	};

	const deleteUser = async () => {
		if (networkCallRef.current) {
			networkCallRef.current.abort();
		}
		try {
			const deleteUserFetch = await authAPI.deleteUser(user?.email, type);
			networkCallRef.current = deleteUserFetch;
			const res = await deleteUserFetch.send();
			return res;
		} catch (error) {
			return	Promise.reject(error);
		}
	};

	const handleDeleteUser = async (shouldProceed) => {
		if (shouldProceed) {
			setIsDeleteLoading(true);
			try {
				await deleteUser();
				await updateUserInCore({ id: user?.email, updates: { hasPortalAccount: false } });
				handleCloseRequest();
			} catch (error) {
				if (error?.name !== 'AbortError') {
					setErrorMsg(error?.message || error);
				}
			}
			setIsDeleteLoading(false);
		}
	};

	const handleResetPassword = async (shouldProceed) => {
		if (shouldProceed) {
			setIsResetLoading(true);
			if (networkCallRef.current) {
				networkCallRef.current.abort();
			}
			try {
				const reset = await authAPI.resetPassword(user?.email, type);
				networkCallRef.current = reset;
				await reset.send();
				setUserData((oldState) => ({ ...oldState, UserStatus: 'RESET_REQUIRED' }));
			} catch (error) {
				if (error?.name !== 'AbortError') {
					setErrorMsg(error?.message || error);
				}
			}
			setIsResetLoading(false);
		}
	};

	const handleDeleteUserClick = () => {
		if (userData?.Enabled || isDeleteLoading) {
			return;
		}
		const message = 'Are you sure you want to delete this user\'s account?';
		displayConfirmation(handleDeleteUser, { message });
	};

	const handleResetClick = () => {
		if ((userData?.UserStatus !== 'CONFIRMED') || isResetLoading) {
			return;
		}
		const message = 'Are you sure you want to reset this user\'s password?';
		displayConfirmation(handleResetPassword, { message });
	};

	const handleEditGroups = async () => {
		setIsGroupsLoading(true);
		const removedGroups = difference(userData?.groups, selectedGroups);
		const addedGroups = difference(selectedGroups, userData?.groups);
		try {
			if (addedGroups.length) {
				await addUserToGroup(addedGroups);
			}
			if (removedGroups.length) {
				await removeGroupsFromUser(removedGroups);
			}
			setUserData((oldData) => ({ ...oldData, groups: selectedGroups }));
			setEditingGroups(false);
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setErrorMsg(error?.message || error);
			}
		}
		setIsGroupsLoading(false);
	};

	const closeModal = () => {
		// prevents accidental closing while waiting for submit
		if (isLoading) {
			return;
		}
		handleCloseRequest();
	};

	// if a user clicks cancel, cancel for real.
	const handleCancel = () => {
		abortNetworkCalls();
		handleCloseRequest();
	};

	useEffect(() => {
		if (isOpen && !userData && user?.hasPortalAccount) {
			getUser();
		}
		return abortNetworkCalls;
	}, [isOpen]);

	const cancelEditGroups = () => {
		setSelectedGroups(userData?.groups || []);
		setEditingGroups(false);
	};

	const getStatusChipVariant = (status) => {
		switch (status?.toUpperCase()) {
		case 'CONFIRMED':
			return 'success';
		case 'UNCONFIRMED':
			return 'warning';
		case 'RESET_REQUIRED':
			return 'outline-brand';
		case 'FORCE_CHANGE_PASSWORD':
			return 'error';
		case 'COMPROMISED':
			return 'error';
		case 'ARCHIVED':
			return 'neutral';
		default:
			return 'base';
		}
	};

	return (
		<SubmitRequestModal
			isOpen={!!isOpen}
			size="medium"
			title={user?.hasPortalAccount ? 'Manage Portal Account' : 'Create Portal Account'}
			onRequestClose={closeModal}
			onCancel={handleCancel}
			onSubmit={handleCreateUser}
			isLoading={isLoading}
			isViewLoading={isUserLoading}
			errorMsg={errorMsg}
			submitLabel="Create"
			noFooter={user?.hasPortalAccount}
		>
			<>
				<IF condition={!user?.hasPortalAccount}>
					<>
						<IF condition={isPartner}>
							<MultiSelect
								label="Select User Groups"
								labelAlignment="left"
								value={selectedGroups?.map((el) => ({ label: el, value: el, name: el }))}
								onChange={(values) => setSelectedGroups(values.map((el) => el.value))}
								variant="chip"
								className="w-full sm:w-9/12 md:w-8/12 lg:w-1/2"
								showCheckbox
							>
								{groups?.map((el) => <Option name={el} label={el} value={el} key={el} />)}
							</MultiSelect>
						</IF>
						<p className="mt-8">
          A Partner Portal invitation will be sent to
							{' '}
							{user.email}
							{' '}
          with their temporary password.
						</p>
					</>
				</IF>
				<IF condition={user?.hasPortalAccount}>
					<>
						<div className="flex items-center mb-3">
							<Avatar icon={<FontAwesomeIcon icon={faUser} />} className="mr-3" />
							<h4>{user?.name || user?.email}</h4>
							<div className="ml-auto">
								{/* if we disable the button, we lose the tooltip, so we're faking here. */}
								<ButtonIcon
									assistiveText="refresh data button"
									title="Refresh data"
									type="button"
									icon={<FontAwesomeIcon icon={faRedoAlt} className={isRefreshing ? 'animate-spin' : ''} />}
									variant="border"
									disabled={isLoading}
									tooltip="Refresh"
									onClick={() => getUser(true)}
								/>
								<ButtonIcon
									className={`mx-3 ${((userData?.UserStatus !== 'CONFIRMED') || isResetLoading)
										? disabledButtonIconClass : ''
									}`}
									tooltip={userData?.UserStatus !== 'CONFIRMED'
										? 'Only CONFIRMED accounts can have their passwords reset.'
										: 'Reset Password'}
									icon={<FontAwesomeIcon icon={faKey} />}
									variant="outline-brand"
									onClick={handleResetClick}
								/>
								<ButtonIcon
									className={(userData?.Enabled || isDeleteLoading) ? disabledButtonIconClass : null}
									tooltip={userData?.Enabled ? 'User must be disabled before deleting.' : 'Delete Portal Account'}
									assistiveText="Delete"
									icon={<FontAwesomeIcon icon={faTrash} />}
									onClick={handleDeleteUserClick}
									variant="outline-brand"
								/>
							</div>
						</div>
						<div>
							<ControlSection>
								<span className="mr-auto">Account Status:</span>
								<Chip
									variant={getStatusChipVariant(userData?.UserStatus)}
									label={userData?.UserStatus}
								/>
							</ControlSection>
							<ControlSection>
								<span className="mr-auto">Account Enabled:</span>
								<CheckboxToggle
									value={userData?.Enabled}
									onChange={enableOrDisableUser}
									disabled={isEnabledStatusLoading}
								/>
							</ControlSection>
							<IF condition={canEnableSMS}>
								<ControlSection>
									<span className="mr-auto">SMS MFA:</span>
									<IF condition={!(isCustomer && isSMSMfaEnabled)}>
										<CheckboxToggle
											value={isSMSMfaEnabled}
											onChange={toggleSMSMfa}
											disabled={isMFAStatusLoading}
										/>
									</IF>
									<IF condition={isCustomer && isSMSMfaEnabled}>
										<span className="text-2xl">👍</span>
									</IF>
								</ControlSection>
							</IF>
							<IF condition={isPartner}>
								<ControlSection>
									<span className="mr-auto w-1/3">User Groups:</span>
									<div className="flex-grow w-full">
										<MultiSelect
											value={selectedGroups?.map((el) => ({ label: el, value: el, name: el }))}
											onChange={(values) => setSelectedGroups(values.map((el) => el.value))}
											variant="chip"
											onFocus={() => setEditingGroups(true)}
											// onBlur={cancelEditGroups}
											showCheckbox
											className="w-full"
											readOnly={isGroupsLoading}
										>
											{groups?.map((el) => <Option name={el} label={el} value={el} key={el} />)}
										</MultiSelect>
										<IF condition={isEditingGroups}>
											<div className="flex mt-3 justify-end">
												<ButtonIcon
													icon={<FontAwesomeIcon icon={faTimes} />}
													tooltip="Cancel"
													variant="destructive"
													size="small"
													onClick={cancelEditGroups}
													className="mr-3"
												/>
												<ButtonIcon
													icon={<FontAwesomeIcon icon={faCheck} />}
													tooltip="Update"
													size="small"
													variant="success"
													onClick={handleEditGroups}
													isLoading={isGroupsLoading}
												/>
											</div>
										</IF>
									</div>
								</ControlSection>
							</IF>
						</div>
						{isUserLoading ? null : (
							<Accordion>
								<AccordionSection label="More Info">
									<CognitoDetails data={userData} />
								</AccordionSection>
							</Accordion>
						)}
					</>
				</IF>
			</>
		</SubmitRequestModal>
	);
};

export default CognitoControl;
