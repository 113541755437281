function Home() {
	return (
		<>
			<p>
      Use this Builder to create a new ticket cancellation product. While not
      all information is absolutely necessary, the more details you provide,
      the more accurately we can price the event.
				<br />
				<br />
      Once you begin, your information will be auto-saved between each view,
      so feel free to leave and come back as needed. You'll have a chance to
      review everything before submitting the product for review. Once
      submitted, someone will reach out in 48 hours with an approval or
      additional questions. Enjoy!
			</p>
		</>
	);
}

export default Home;
