import React, {
	useEffect, useState, useRef,
} from 'react';

// lodash
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';

import { diff } from 'deep-object-diff';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPen,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

import { useApiContext, useAppContext, usePartnerData } from '@/providers';
import { PARTNER_REPORTS, DEMO_BUDDIES } from '@/utils';
import {
	Option,
	Button,
	Card,
	ButtonIcon,
	Input,
	MultiSelect,
} from '@/lib/ReactRainbow';

import { IF } from '@/components/utils';
import {
	Label,
	SectionHeader,
} from '@/components/UI';
import CopyToClipboard from '@/components/CopyToClipboard';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

const UI_STATE = {
	READ_ONLY: 'readonly',
	EDIT: 'edit',
	LOADING: 'loading',
};

const fromReact = (data) => {
	const returnData = cloneDeep(data);
	returnData.partnerReports = returnData?.partnerReports?.map((report) => report.value) || [];
	returnData.buddies = returnData?.buddies?.map((buddy) => buddy.value) || [];
	return returnData;
};

const toReact = (data) => {
	if (!data || !Object.keys(data).length) {
		return {};
	}
	const returnData = cloneDeep(data);
	returnData.partnerReports = PARTNER_REPORTS.filter((report) => returnData?.partnerReports?.includes(report.id))
		.map((report) => ({
			icon: null,
			label: report.title,
			name: `option-${report.id}`,
			value: report.id,
		}));

	returnData.buddies = DEMO_BUDDIES.filter((buddy) => returnData?.buddies?.includes(buddy.id))
		.map((buddy) => ({
			icon: null,
			label: buddy.title,
			name: `option-${buddy.id}`,
			value: buddy.id,
		}));

	return returnData;
};

function PartnerPortalInfo({ onUpdate = () => {} }) {
	// CONTEXTS
	const { displayError } = useAppContext();
	const { partnerAPI } = useApiContext();
	const partnerData = toReact(usePartnerData().partnerData);

	// STATES
	const [uiState, setUIState] = useState(UI_STATE.READ_ONLY);
	const [tempState, setTempState] = useState(null);

	// REFS
	const updatePartnerRef = useRef();

	// EFFECTS
	useEffect(() => {
		// if we jump to edit mode, make a quick copy of what's up
		if (uiState === UI_STATE.EDIT) {
			const partnerCopy = cloneDeep(partnerData);
			setTempState(partnerCopy);
		} else if (uiState === UI_STATE.READ_ONLY) {
			setTempState(null);
		}
	}, [uiState]);

	const handleChange = ({ target: { name, value } }) => {
		const newInfo = cloneDeep(tempState);
		set(newInfo, name, value);
		setTempState(newInfo);
	};

	const handleInfoUpdate = async () => {
		setUIState(UI_STATE.LOADING);

		try {
			if (updatePartnerRef.current) {
				updatePartnerRef.current.abort();
			}

			const dataToUse = fromReact(tempState);

			// check what was updated
			const diffData = diff(fromReact(partnerData), dataToUse);

			const dataAllowedToBeSent = ['name', 'slug', 'address', 'hubspot', 'slack', 'partnerReports', 'buddies', 'isAPIIntegration', 'offerings', 'website'];
			const intersectionData = intersection(Object.keys(diffData), dataAllowedToBeSent);

			// if there isn't any update, kill this
			if (!intersectionData?.length) {
				setUIState(UI_STATE.READ_ONLY);
				return;
			}

			// otherwise, grab the data we want to send AND that is updated
			const cleanedPartnerData = pick(dataToUse, intersectionData);

			// make sure the http(s) protocol is on the website url
			if (cleanedPartnerData?.website?.length && cleanedPartnerData.website.search(/^http[s]?:\/\//) === -1) {
				cleanedPartnerData.website = `http://${cleanedPartnerData.website}`;
			}

			const updatePartner = await partnerAPI.update(partnerData.id, cleanedPartnerData);
			updatePartnerRef.current = updatePartner;

			const partnerFromServer = await updatePartner.send();
			onUpdate(partnerFromServer);
			setUIState(UI_STATE.READ_ONLY);
		} catch (error) {
			if (error?.name !== 'AbortError') {
				setUIState(UI_STATE.EDIT);
				displayError(error);
				updatePartnerRef.current = null;
			}
		}
	};

	const {
		slack = '',
		id = '',
		stagingID = '',
		partnerReports = [],
	} = (uiState === UI_STATE.READ_ONLY ? partnerData : tempState) || {};

	const infoRow = 'flex flex-column sm:flex-row w-full justify-between flex-wrap mb-5';
	const infoField = uiState === UI_STATE.READ_ONLY ? 'w-full sm:w-6/12 px-7' : 'w-full sm:w-6/12 px-3';

	return (
		<div className="mt-4">
			<SectionHeader
				title="Partner Portal Info"
				className="mt-5 ml-4 border-b-2"
				size="md"
				actions={(
					<>
						<IF condition={uiState === UI_STATE.READ_ONLY}>
							<ButtonIcon
								assistiveText="refresh data button"
								title="Refresh the data"
								type="button"
								icon={<FontAwesomeIcon icon={faPen} />}
								variant="base"
								size="medium"
								onClick={() => setUIState(uiState === UI_STATE.READ_ONLY ? UI_STATE.EDIT : UI_STATE.READ_ONLY)}
							/>
						</IF>
						<IF condition={uiState !== UI_STATE.READ_ONLY}>
							<div className="flex flex-row mb-2 pt-0.5">
								<Button
									label="Cancel"
									variant="destructive"
									size="small"
									className="mr-2"
									onClick={() => setUIState(UI_STATE.READ_ONLY)}
									disabled={uiState === UI_STATE.LOADING}
								/>
								<Button
									label="Save"
									variant="success"
									size="small"
									onClick={handleInfoUpdate}
									disabled={uiState === UI_STATE.LOADING}
								/>
							</div>
						</IF>
					</>
				)}
			/>
			<div className="w-full pl-4 mt-4">
				<Card>
					<div className="flex flex-col justify-between w-full mt-4">
						<div className={infoRow}>
							<IF condition={uiState !== UI_STATE.READ_ONLY}>
								<MultiSelect
									id="multiselect-partner-reports"
									name="partnerReports"
									label={<Label className="m-0" label="Partner Reports to Display" />}
									labelAlignment="left"
									placeholder="Add Reports"
									className={infoField}
									value={partnerReports}
									onChange={(val) => handleChange({ target: { name: 'partnerReports', value: val } })}
									variant="chip"
									type="text"
									showCheckbox
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
								>
									{PARTNER_REPORTS.map((report) => (<Option name={`option-${report.id}`} label={report.title} value={report.id} key={report.id} />))}
								</MultiSelect>
								<Input
									label={<Label className="m-0" label="Slack Channel" />}
									labelAlignment="left"
									className={infoField}
									placeholder="wayne-slack"
									type="text"
									name="slack"
									value={slack}
									onChange={handleChange}
									readOnly={uiState === UI_STATE.READ_ONLY}
									disabled={uiState === UI_STATE.LOADING}
								/>
							</IF>
							<IF condition={uiState === UI_STATE.READ_ONLY}>
								<div
									className={infoField}
								>
									<Label className="m-0" label="Partner Reports to Display" />
									<br />
									<IF condition={!partnerReports.length}>
										<p className="text-sm pt-2 text-gray-400">(not set)</p>
									</IF>
									<p className="pt-2">
										{partnerReports.map((report) => report.label).join(', ')}
									</p>
								</div>
								<div
									className={infoField}
								>
									<Label className="m-0" label="Slack Channel" />
									<br />
									<IF condition={!!slack}>
										<a
											className="font-normal text-buddy-red no-underline active:text-buddy-red hover:text-buddy-red visited:text-buddy-red hover:no-underline active:no-underline focus:text-buddy-red focus:no-underline"
											href="https://iambuddy.slack.com/archives/C8V7L9092"
											target="_blank"
											rel="noreferrer"
										>
											{slack}
											<FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
										</a>
									</IF>
									<IF condition={!slack}>
										<p className="text-sm pt-2 text-gray-400">(not set)</p>
									</IF>
								</div>
							</IF>
						</div>
						<div className={`${infoRow} ${uiState !== UI_STATE.READ_ONLY ? 'ml-4' : ''}`}>
							<div
								className={infoField}
							>
								<Label className="m-0" label="Production Partner ID" />
								<br />
								<CopyToClipboard className="text-buddy-red" label={id} />
							</div>
							<div
								className={infoField}
							>
								<Label className="m-0" label="Staging Partner ID" />
								<br />
								<CopyToClipboard className="text-buddy-red" label={stagingID} />
							</div>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}

export default PartnerPortalInfo;
