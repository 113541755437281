import React from 'react';
import {
	ProgressBar,
} from '@/lib/ReactRainbow';

const ProgressIndicator = ({ label = 'Percentage', percent, className = '' }) => {
	const percentageIsFull = percent === 100;

	return (
		<div className={className}>
			<label className="block w-full pb-4">
				{label}
				{' '}
				<span className="font-bold">
					{` ${percent}%`}
				</span>
				<span className={`float-right text-4xl mb-4 ${percentageIsFull ? 'animate-wiggle' : ''}`}>
					{percentageIsFull && '👍'}
				</span>
			</label>
			<ProgressBar
				value={percent}
				variant={percentageIsFull ? 'success' : 'brand'}
			/>
		</div>
	);
};

export default ProgressIndicator;
