import { useState, useEffect, useRef } from 'react';
import { navigate, useParams, Redirect } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrash, faEdit, faHandsHelping, faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { faSlack, faSlackHash } from '@fortawesome/free-brands-svg-icons';
import MaskedInput from 'react-text-mask';
import { useAppContext, useApiContext, usePartnerData } from '@/providers';
import { getInitials, isRequired } from '@/utils';
import {
	Avatar, Breadcrumbs, Breadcrumb, Card, Button, Input, ButtonMenu, MenuItem,
} from '@/lib/ReactRainbow';
import LoginHistoryTable from '@/components/LoginHistoryTable';
import { IF } from '@/components/utils';
import CognitoControls from '@/components/CognitoControls';

function PartnersUsersDetail() {
	const [isLoading, setIsLoading] = useState(false);
	const [isEditing, setIsEdit] = useState(false);
	const [isCognitoControlOpen, setIsCognitoControlOpen] = useState(false);
	const { userID } = useParams();
	const { partnerData } = usePartnerData();
	const { displayError, displayConfirmation } = useAppContext();
	const { authAPI, partnerAPI } = useApiContext();

	const user = partnerData?.users?.find((el) => el.email === userID);

	const defaultInfo = {
		name: user?.name || '',
		email: user?.email || '',
		phone: user?.phone || '',
		role: user?.role || '',
		assignee: user?.assignee || '',
	};

	const [info, setInfo] = useState(defaultInfo);
	const saveChangesRef = useRef();
	const updateCognitoRef = useRef();
	const updateCoreRef = useRef();

	const handleChange = ({ target: { name, value } }) => {
		setInfo((oldState) => ({ ...oldState, [name]: value }));
	};

	const cancelEdit = () => {
		setInfo(defaultInfo);
		setIsEdit(false);
	};

	const abortNetworkCalls = () => {
		if (saveChangesRef.current) {
			saveChangesRef.current.abort();
		}
		if (updateCognitoRef.current) {
			updateCognitoRef.current.abort();
		}
		if (updateCoreRef.current) {
			updateCoreRef.current.abort();
		}
	};

	const updateCognitoUser = async () => {
		if (updateCognitoRef.current) {
			updateCognitoRef.current.abort();
		}
		const id = user.email; // id must be the original email.

		try {
			const updateCognitoFetch = await authAPI.update({ id, type: 'partner', ...info });
			updateCognitoRef.current = updateCognitoFetch;

			const res = await updateCognitoFetch.send();
			updateCognitoRef.current = null;
			return res;
		} catch (error) {
			updateCognitoRef.current = null;
			return Promise.reject(error);
		}
	};

	const updatePartnerUserInCore = async ({
		id = isRequired('id'),
		updates = isRequired('updates'),
	}) => {
		if (updateCoreRef.current) {
			saveChangesRef.current.abort();
		}
		const { users, id: partnerID } = partnerData;
		const userIndex = users.findIndex((el) => id === el.email);
		if (userIndex === -1) {
			return Promise.reject(new Error('Update failed: User not found.'));
		}
		users[userIndex] = { ...user, ...updates }; // spreading here to preserve unaltered values.
		try {
			const savePartnerFetch = await partnerAPI.update(partnerID, { users });
			saveChangesRef.current = savePartnerFetch;
			const res = savePartnerFetch.send();
			saveChangesRef.current = null;
			return res;
		} catch (error) {
			saveChangesRef.current = null;
			return Promise.reject(error);
		}
	};

	const deleteUserFromCore = async (id) => {
		displayConfirmation();
		const { users, id: partnerID } = partnerData;
		const userIndex = users.indexOf(user);
		users.splice(userIndex, 1);
		try {
			const savePartnerFetch = await partnerAPI.update(partnerID, { users });
			saveChangesRef.current = savePartnerFetch;
			const res = savePartnerFetch.send();
			saveChangesRef.current = null;
			return res;
		} catch (error) {
			saveChangesRef.current = null;
			return Promise.reject(error);
		}
	};

	const confirmUserDeletion = () => {
		abortNetworkCalls();
		displayConfirmation(
			(didConfirm) => {
				if (didConfirm) {
					deleteUserFromCore({ id: user.email });
				}
			},
			{
				message: (
					<div>
						Are you sure you want to delete
						{' '}
						<span className="font-extrabold underline">{user.name}</span>
						?
					</div>),
			},
		);
	};

	const handleSave = async () => {
		setIsLoading(true);
		abortNetworkCalls();
		try {
			await updatePartnerUserInCore({ id: user.email, updates: info });
			if (user.hasPortalAccount) {
				await updateCognitoUser();
			}
			setIsEdit(false);
			setIsLoading(false);
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error?.message || error);
			}
		}
	};

	useEffect(() => abortNetworkCalls, []);

	if (!partnerData || !user) {
		return (
			<Redirect
				noThrow
				to="../"
			/>
		);
	}

	return (
		<>
			<div className="my-3">
				<Breadcrumbs>
					<Breadcrumb label="Users" onClick={() => navigate('../users')} />
					<Breadcrumb label="User details" />
				</Breadcrumbs>
			</div>
			<Card
				icon={<Avatar initials={getInitials(user?.name)} />}
				title={isEditing
					? null
					: <span className="capitalize">{user?.name}</span>}
				actions={(
					<>
						<IF condition={isEditing}>
							<div className="flex flex-row mb-2 pt-0.5">
								<Button
									label="Cancel"
									variant="destructive"
									size="small"
									className="mr-2"
									onClick={cancelEdit}
								/>
								<Button
									label="Save"
									variant="success"
									size="small"
									isLoading={isLoading}
									onClick={handleSave}
								/>
							</div>
						</IF>
						<IF condition={!isEditing}>
							<ButtonMenu
								buttonVariant="outline-brand"
								menuAlignment="right"
								menuSize="x-small"
								label={(
									<>
										Options
										<FontAwesomeIcon icon={faAngleDown} className="ml-2" />
									</>
								)}
							>
								<MenuItem label="Options" variant="header" />
								<MenuItem
									onClick={() => setIsCognitoControlOpen(true)}
									label={(
										<>
											<FontAwesomeIcon icon={faHandsHelping} className="mr-2" />
											{user?.hasPortalAccount ? 'Manage Portal Account' : 'Create Portal Account'}
										</>
									)}
								/>
								<MenuItem
									onClick={() => setIsEdit((bool) => !bool)}
									label={(
										<>
											<FontAwesomeIcon icon={faEdit} className="mr-2" />
												Edit User Details
										</>
									)}
								/>
								<MenuItem
									value={user || ''}
									onClick={() => confirmUserDeletion()}
									label={(
										<>
											<FontAwesomeIcon
												icon={faTrash}
												className="mr-3"
											/>
												Delete User
										</>
									)}
								/>
								<MenuItem
									label={(
										<>
											<FontAwesomeIcon icon={user?.slackChannel ? faSlackHash : faSlack} className="mr-3" />
											{user?.slackChannel || 'Add to Slack'}
										</>
									)}
								/>
							</ButtonMenu>
						</IF>
					</>
				)}
			>
				<div className="p-5">
					<div className="flex flex-col md:flex-row">
						<div className="w-full">
							<IF condition={isEditing}>
								<Input
									type="text"
									label="Name"
									labelAlignment="left"
									value={info.name}
									onChange={handleChange}
									name="name"
									className="w-full sm:w-9/12 md:w-8/12 lg:w-1/2 mb-3"
									placeholder="Bruce Wayne"
								/>
							</IF>
							<Input
								label="Title/Role"
								labelAlignment="left"
								className="w-full sm:w-9/12 md:w-8/12 lg:w-1/2"
								placeholder="CEO"
								type="text"
								name="role"
								value={info.role}
								onChange={handleChange}
								readOnly={!isEditing}
							/>
							<Input
								label="Email"
								labelAlignment="left"
								className="w-full sm:w-9/12 md:w-8/12 lg:w-1/2 mt-4"
								placeholder="notbatman@gmail.com"
								type="text"
								name="email"
								value={info.email}
								onChange={handleChange}
								readOnly={!isEditing}
							/>
						</div>
						<div className="w-full mt-3 md:mt-0">
							<label className="rainbow-label block pl-4">Phone</label>
							<MaskedInput
								className={`${isEditing ? 'rainbow-input' : 'rainbow-input-disabled'} w-full sm:w-9/12 md:w-8/12 lg:w-1/2`}
								placeholder="804-867-5309"
								type="tel"
								mask={[
									'+',
									'1',
									' ',
									'(',
									/[2-9]/,
									/\d/,
									/\d/,
									')',
									' ',
									/\d/,
									/\d/,
									/\d/,
									'-',
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								name="phone"
								value={info.phone}
								onChange={handleChange}
								disabled={!isEditing}
							/>
						</div>
					</div>
				</div>
				<div />
			</Card>
			<IF condition={user.hasPortalAccount}>
				<LoginHistoryTable type="partner" id={userID} />
			</IF>
			<CognitoControls
				isOpen={isCognitoControlOpen}
				type="partner"
				user={user}
				handleCloseRequest={() => setIsCognitoControlOpen(false)}
				partnerID={partnerData.id}
				updateUserInCore={updatePartnerUserInCore}
			/>
		</>
	);
}

export default PartnersUsersDetail;
