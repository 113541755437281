import cloneDeep from 'lodash/cloneDeep';

const createEmbedScript = (payload) => {
	const clonedPayload = cloneDeep(payload);
	const overrideData = payload.data;
	const options = JSON.stringify(clonedPayload, null, 5).replace(/}(?![\s\S]*})/, '\t};');
	const data = JSON.stringify(overrideData, null, 5).replace(/}(?![\s\S]*})/, '\t};');
	const noCheckoutCallbacks = !payload?.includeCheckout ? `
	/*
		addToCart callback will be called when a user completes the widget process
		see our partner API docs on how to use the returned application object to
		purchase a policy.
		https://buddyinsurance.stoplight.io/docs/partner-api-documentation/
	*/

	function addBuddyToCart(application) {
		const { customer, policy } = application;
		console.log(customer, policy);
	}

	function removeBuddyFromCart(application) {
		const { customer, policy } = application;
		console.log(customer, policy);
	}

	options.onAddToCart = addBuddyToCart;
	options.onRemoveFromCart = removeBuddyFromCart;
	` : '';
	return (`
<!-- Paste this code directly above the closing </body> tag -->

<script src="https://js.buddy.insure/v2/index.js"></script>
<script type="application/javascript">

	var options = ${options}

	${noCheckoutCallbacks}

	var data = ${data}
	
	options.data = data;

	/* stage options are "STAGING" or "PRODUCTION". Be sure to switch to "PRODUCTION" before going live. */
	options.stage = 'STAGING'; 
	window.Buddy.createOffer(options);
</script>
<!-- END BUDDY SCRIPT -->`);
};

export default createEmbedScript;
