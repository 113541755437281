import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from '@reach/router';
import { AppContext } from '@/providers';
import ErrorBoundary from '@/components/ErrorBoundary';
import {
	Input,
	Button,
	StrongPasswordInput,
	Card,
} from '@/lib/ReactRainbow';

const validateEmail = (value) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value);

const isPasswordValid = (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
	password,
);
const STATUS = {
	EMAIL: 'email',
	CODE: 'code',
	SUCCESS: 'success',
};

const ForgotPassword = () => {
	const { displayError } = useContext(AppContext);
	const [status, setStatus] = useState(STATUS.EMAIL);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		username: '',
		code: '',
		password: '',
	});
	const { username, code, password } = form;
	const handleChange = ({ target: { value, name } }) => setForm((current) => ({ ...current, [name]: value }));

	const getCode = async (event) => {
		event.preventDefault();
		setLoading(true);
		if (!validateEmail(username)) {
			setLoading(false);
			return;
		}
		try {
			await Auth.forgotPassword(username.toLowerCase());
			setStatus(STATUS.CODE);
			setLoading(false);
		} catch (error) {
			displayError(error);
			setLoading(false);
		}
	};

	const submit = async (event) => {
		event.preventDefault();
		try {
			await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
			setStatus(STATUS.SUCCESS);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			displayError(error);
		}
	};

	const getPasswordState = () => {
		if (!password.length) {
			return null;
		}
		if (isPasswordValid(password)) {
			return 'strong';
		}
		return 'weak';
	};

	return (
		<ErrorBoundary>
			<div className="container flex justify-center items-center h-screen mx-auto">
				<div className="w-full p-3 sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2">
					<Card
						icon={
							<img className="w-8" src="/buddy_lettermark_R.svg" alt="logo" />
						}
						title="Forgot Password | BUMP"
					>
						{status === STATUS.EMAIL && (
							<div className="text-center mt-4 sm:mt-8">
            Enter the email associated with your Buddy Account.
							</div>
						)}
						{status === STATUS.CODE && (
							<div className="text-center mt-4 sm:mt-8">
            A recovery code was sent to your email address.
							</div>
						)}
						<form className="p-3 sm:p-8 w-full md:w-4/5 lg:w-3/4 xl:w-3/5 mx-auto">
							{status === STATUS.EMAIL && (
								<>
									<Input
										label="Email"
										placeholder="username@example.com"
										type="text"
										labelAlignment="left"
										name="username"
										onChange={handleChange}
										value={username}
										autoComplete="email"
									/>
									<Button
										type="submit"
										variant="brand"
										label="Next"
										style={{ display: 'block' }}
										className="mt-5 ml-auto"
										disabled={!username.length}
										isLoading={loading}
										onClick={getCode}
									/>
								</>
							)}
							{status === STATUS.CODE && (
								<>
									<label htmlFor="code">Code:</label>
									<Input
										placeholder="123456"
										type="tel"
										labelAlignment="left"
										name="code"
										value={code}
										onChange={handleChange}
										autoComplete="one-time-code"
									/>
									<label htmlFor="password" className="mt-4 block">
                New Password:
									</label>
									<StrongPasswordInput
										placeholder="********"
										minLength={8}
										id="password"
										type="password"
										autoComplete="new-password"
										name="password"
										value={password}
										hideLabel
										required
										passwordState={getPasswordState()}
										bottomHelpText="Your password must be at least 8 characters long, include at least one number, one uppercase letter and one lowercase letter"
										onChange={handleChange}
									/>
									<Button
										type="submit"
										variant="brand"
										label="Submit"
										style={{ display: 'block' }}
										className="mt-5 ml-auto"
										disabled={!code.length || !password.length}
										isLoading={loading}
										onClick={submit}
									/>
								</>
							)}
							{status === STATUS.SUCCESS && (
								<p>Your password has been successfully reset!</p>
							)}

							<Link to="/login" className="link mt-8 text-center block">
              Back to sign in
							</Link>
						</form>
					</Card>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default ForgotPassword;
