import { useEffect, useState } from 'react';
import {
	// differenceInDays,
	Numbers,
} from '@buddy-technology/buddy_helpers';
import FileSaver from 'file-saver';
import { STATES_ARRAY, arrayToCSV } from '@/utils';
// import {
// 	DEFAULT_RATES,
// 	DEFAULT_MIN_PREMIUMS,
// 	DEFAULT_COMMISSIONS,
// } from '../../models/SimpleModels';
import {
	Select,
	Table,
	Column,
	Card,
	HelpText,
	Button,
} from '@/lib/ReactRainbow';

import DraggableNumberInput, { getMoneyMask } from '@/components/DraggableNumberInput';

const defaultRateTable = {
	default: {
		state: 'Default',
		rate: 0.15,
		minPremium: 5,
		commission: 0.4,
	},
	alaska: {
		state: 'Alaska',
		rate: 0.1436,
		minPremium: 5,
		commission: 0.4,
	},
	florida: {
		state: 'Florida',
		rate: 0.0171,
		minPremium: 5,
		commission: 0.4,
	},
	nevada: {
		state: 'Nevada',
		rate: 0.1586,
		minPremium: 5,
		commission: 0.4,
	},
	washington: {
		state: 'Washington',
		rate: 0.2143,
		minPremium: 5,
		commission: 0.4,
	},
};

const STATES = {
	DEFAULT: 'Default',
	AK: 'Alaska',
	FL: 'Florida',
	NV: 'Nevada',
	WA: 'Washington',
};

function Pricing({ productDetails, updateProduct }) {
	const {
		// minAttendees,
		// maxAttendees,
		// registrationStartDate,
		// registrationEndDate,
		minPrice,
		// maxPrice,
		// hasRefundPolicy,
		// perTicketCap,
		rates,
		commissions,
		minimumPremiums,
		// lossRatio,
		// maxLossForEvent,
	} = productDetails;

	const [rateInfo, setRateInfo] = useState(rates);
	const [minPremiumInfo, setMinPremiumInfo] = useState(minimumPremiums);
	const [commissionInfo, setCommissionInfo] = useState(commissions);
	const originalInfo = { rates, minimumPremiums, commissions };

	useEffect(() => {
		updateProduct({ rates: rateInfo, minimumPremiums: minPremiumInfo, commissions: commissionInfo });
	}, [rateInfo, minPremiumInfo, commissionInfo]);

	const [form, setForm] = useState({
		state: STATES_ARRAY[0].value,
		ticketPrice: minPrice || 1000,
		numOfTickets: 1,
	});

	const { state, ticketPrice, numOfTickets } = form;

	// const calculateRate = () => {
	// 	const baseRate = 0.05;
	// 	const priceHigh = parseInt(maxPrice, 10);
	// 	const priceLow = parseInt(minPrice, 10);
	// 	const volumeHigh = parseInt(maxAttendees, 10);
	// 	const volumeLow = parseInt(minAttendees, 10);
	// 	const cancellationPolicyAddOn = hasRefundPolicy ? 0 : 0.01;
	// 	const registrationDaysAddOn =
	// 		(280 - differenceInDays(registrationEndDate, registrationStartDate)) /
	// 		2800;
	// 	const averagePriceAddOn =
	// 		perTicketCap - (priceHigh - priceLow) / 2 < 0
	// 			? 0.01 * (priceHigh / 1000)
	// 			: 0;
	// 	const avgVolume = (volumeHigh + volumeLow) / 2;
	// 	const averageVolumeAddOn =
	// 		10000 - avgVolume > 0 ? 0.01 * ((10000 - avgVolume) / 5000) : 0;
	// 	const rate =
	// 		baseRate +
	// 		cancellationPolicyAddOn +
	// 		registrationDaysAddOn +
	// 		averagePriceAddOn +
	// 		averageVolumeAddOn;
	// 	return rate;
	// };

	// const getRate = (rateType) => {
	// 	let rate = calculateRate();
	// 	if (rateType === 'AK') {
	// 		rate *= 0.067 / 0.07;
	// 	}
	// 	if (rateType === 'FL') {
	// 		rate *= 0.008 / 0.07;
	// 	}
	// 	if (rateType === 'NV') {
	// 		rate *= 0.074 / 0.07;
	// 	}
	// 	if (rateType === 'WA') {
	// 		rate *= 0.1 / 0.07;
	// 	}
	// 	return Numbers.roundTo(rate, 4);
	// };

	const handleChange = ({ target: { name, value } }) => {
		setForm((curr) => ({ ...curr, [name]: value }));
	};

	const handleRateTableChange = ({ value, row, column }) => {
		// eslint-disable-next-line radix
		const toIntFromUSD = (str) => (Number.isNaN(parseInt(str)) ? parseInt(str.slice(1), 10) : parseInt(str));
		const toDecimalFromPercentage = (str) => parseFloat(str.slice(0, str.length - 1)) / 100;
		const newValue = ['rate', 'commission'].includes(column)
			? toDecimalFromPercentage(value)
			: toIntFromUSD(value);

		const { key } = row;
		const DATA_TO_UPDATE = {
			rate: { ...rateInfo },
			minPremium: { ...minPremiumInfo },
			commission: { ...commissionInfo },
		};
		DATA_TO_UPDATE[column][key] = newValue;
		const UPDATE_METHODS = {
			rate: setRateInfo,
			minPremium: setMinPremiumInfo,
			commission: setCommissionInfo,
		};
		const updateMethodToCall = UPDATE_METHODS[column];
		updateMethodToCall(DATA_TO_UPDATE[column]);
	};

	const rateTableData = Object.keys(rates).map((key) => ({
		state: STATES[key],
		rate: Numbers.toPercentage(rates[key]),
		minPremium: Numbers.toUSD(minimumPremiums[key]),
		commission: Numbers.toPercentage(commissions[key]),
		key,
	}));

	const saveCSV = () => {
		const csv = arrayToCSV(rateTableData);
		const filename = 'rates.csv';
		const file = new File([csv], filename, {
			type: 'text/csv;charset=utf-8',
		});
		FileSaver.saveAs(file);
	};

	const getTotalPrice = () => {
		const rateToUse = rateInfo[state] || rateInfo.DEFAULT;
		const minimum = minPremiumInfo[state] || minPremiumInfo.DEFAULT;
		const premium = rateToUse * numOfTickets * ticketPrice;
		return premium < minimum ? minimum : premium;
	};

	const resetTable = () => {
		setRateInfo(originalInfo.rates);
		setMinPremiumInfo(originalInfo.minimumPremiums);
		setCommissionInfo(originalInfo.commissions);
	};

	return (
		<>
			<div className="flex flex-col lg:flex-row w-full">
				<div className="w-full lg:w-7/12">
					<h4 className="pl-3">Rate Table</h4>
					<Card
						className="h-full"
						footer={(
							<div className="flex justify-between p-3">
								<Button
									label="Reset"
									onClick={() => resetTable(defaultRateTable)}
								/>
								<Button label="Download CSV" onClick={saveCSV} />
							</div>
						)}
					>
						<Table
							data={rateTableData}
							keyField="state"
							className="w-full h-auto"
							minColumnWidth={25}
						>
							<Column header="State" field="state" />
							<Column
								header="Rate"
								field="rate"
								isEditable
								onChange={(data) => handleRateTableChange({ ...data, column: 'rate' })}
							/>
							<Column
								header="Min. Premium"
								field="minPremium"
								isEditable
								onChange={(data) => handleRateTableChange({ ...data, column: 'minPremium' })}
							/>
							<Column
								header="Commission"
								field="commission"
								isEditable
								onChange={(data) => handleRateTableChange({ ...data, column: 'commission' })}
							/>
						</Table>
					</Card>
				</div>
				<div className="w-full mt-5 lg:mt-0 lg:w-5/12 lg:ml-5">
					<h4 className="pl-3">Ticket Details</h4>
					<Card className="p-5 h-full">
						<Select
							label={(
								<div className="flex">
									<HelpText
										className="mr-2"
										title="Note"
										text="Only AK, FL, NV and WA have non-default pricing."
									/>
                State
								</div>
							)}
							labelAlignment="left"
							className="w-full md:w-1/2 lg:w-1/2"
							name="state"
							options={STATES}
							value={state}
							onChange={handleChange}
						/>
						<label className="rainbow-label mt-5 block">Ticket Price</label>
						{/* <Input
							className="w-3/4 md:w-1/3"
							name="ticketPrice"
							value={ticketPrice}
							onChange={handleChange}
						/> */}
						<DraggableNumberInput
							className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl w-1/3 md:w-1/6 lg:w-1/12 px-1"
							style={{ width: 100 }}
							min={10}
							max={10000}
							mask={getMoneyMask}
							value={ticketPrice}
							speed={11}
							onChange={(value) => handleChange({
								target: {
									name: 'ticketPrice',
									value,
								},
							})}
						/>
						<label className="rainbow-label mt-5 block">
            Number of Tickets
						</label>
						<DraggableNumberInput
							className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl w-1/3 md:w-1/6 lg:w-1/12 px-1"
							min={1}
							max={9}
							mask={[/[1-9]/]}
							value={numOfTickets}
							speed={0.2}
							onChange={(value) => handleChange({
								target: {
									name: 'numOfTickets',
									value,
								},
							})}
						/>
						<hr className="border-t border-solid border-gray-300 my-5" />
						<p className="mt-5">
            Total Premium:
							{' '}
							<strong>{Numbers.toUSD(getTotalPrice())}</strong>
							{' '}
						</p>
					</Card>
				</div>
			</div>
		</>
	);
}

export default Pricing;
