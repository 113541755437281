import {
	useEffect, useState, useRef,
} from 'react';
import {
	Router, navigate, useMatch,
} from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTicketAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useAppContext, useApiContext, useProductContext } from '@/providers';
import { Card } from '@/lib/ReactRainbow';
import {
	Organizer,
	Details,
	Registration,
	Pricing,
	Age,
	RefundPolicy,
	TicketPricing,
	Home,
	Submit,
	Success,
	Summary,
	Underwriting,
} from '@/components/builder/TC';
import 	BuilderFooter from '@/components/builder/BuilderFooter';
import { StatusBadge } from '@/components/UI';
import {
	DEFAULT_RATES,
	DEFAULT_MIN_PREMIUMS,
	DEFAULT_COMMISSIONS,
} from '@/utils';

// keep this object in order!
const STATUS = {
	HOME: {
		path: '/',
		title: (
			<span>
				<FontAwesomeIcon icon={faTicketAlt} className="mr-2" />
			Product Builder for Ticket Cancellation
			</span>
		),
	},
	DETAILS: { path: 'event-details', title: 'Event Details' },
	ORGANIZER: { path: 'organizer-details', title: 'Organizer Details' },
	REGISTRATION: { path: 'registration', title: 'Registration Information' },
	AGE: { path: 'age-data', title: 'Attendance Demographics' },
	REFUND_POLICY: { path: 'refund-policy', title: 'Refund Policy' },
	TICKET_PRICING: { path: 'ticket-pricing', title: 'Ticket Pricing' },
	UNDERWRITING: { path: 'underwriting', title: 'Underwriting (Internal)' },
	PRICING: { path: 'insurance-pricing', title: 'Insurance Pricing Preview' },
	SUMMARY: { path: 'review', title: 'Review' },
	SUBMIT: { path: 'submit', title: 'Submit For Review' },
	SUCCESS: { path: 'success', title: null },
};

const STATUS_TITLE = Object.values(STATUS).map((el) => el.title);
const STATUS_ORDER = Object.values(STATUS).map((el) => el.path);

const BUILDER_DEFAULTS = {
	ageDemographics: [],
	attendance: [2500, 5000],
	name: '', // will serve as offering ID
	event: '', // event name
	eventType: 'conference',
	eventStartDate: new Date(),
	eventEndDate: new Date(),
	registrationStartDate: new Date(),
	registrationEndDate: new Date(),
	minAttendees: '',
	maxAttendees: '',
	avgTicketsPerBooking: '',
	maxTicketsPerBooking: '',
	hasOfferedCancellation: false,
	previousTakeRate: '',
	hasRefundPolicy: true,
	refundPolicy: { text: '', file: null, percentage: '' },
	didHavePreviousCancellationInsurance: false,
	previousCancellationInsuranceTakeRate: 0,
	rates: DEFAULT_RATES,
	commissions: DEFAULT_COMMISSIONS,
	minimumPremiums: DEFAULT_MIN_PREMIUMS,
	forms: 'TICKET_CANCELLATION',
	prefix: 'IET',
	venue: '',
	organizer: {
		name: '',
		organization: '',
		country: 'United States',
		city: '',
		state: '',
		email: '',
		phone: '',
	},
	underwriting: {
		lossRatio: 30,
		perTicketCap: 5000,
		maxLossForEvent: 1000000,
	},
	country: 'United States',
	city: '',
	state: '',
	lossRatio: 0.3,
	perTicketCap: 5000,
	maxLossForEvent: 1000000,
	ticketPricing: [{ priceRange: [1000, 1500], percentage: 100 }],
};

function Builder({ location }) {
	const { displayError } = useAppContext();
	const { offeringAPI } = useApiContext();
	const [productDetailsContext] = useProductContext();

	const [stepNumber, setStepNumber] = useState(0);
	const [productDetails, setProductDetails] = useState(productDetailsContext || BUILDER_DEFAULTS);
	const [saveStatus, setSaveStatus] = useState('Not Saved');

	const pathPrefix = '/products/builder/ticket-cancellation/';

	const saveRef = useRef();
	const submitRef = useRef();
	const viewRef = useRef();

	const checkForErrorsRef = useRef(() => {});

	// console.log(productDetails)

	const updateCheckForErrorsRef = (func) => {
		checkForErrorsRef.current = func;
	};

	const match = useMatch(`${STATUS_ORDER[stepNumber]}/:offeringID`);

	const handleNext = () => {
		const errorFound = viewRef.current?.checkForErrors();
		if (errorFound?.hasError) {
			displayError(errorFound.error || 'Unknown error occurred.');
			return;
		}
		const suffix = match?.offeringID ? `/${match.offeringID}` : '';
		navigate(`${pathPrefix}${STATUS_ORDER[stepNumber + 1]}${suffix}`);
	};

	const handleBack = () => {
		const suffix = match?.offeringID ? `/${match.offeringID}` : '';
		if (stepNumber === 0) {
			navigate('/products');
		} else if (stepNumber === 1) {
			// fixes faulty behavior with starting '/' path.
			navigate(pathPrefix);
		} else {
			navigate(`${pathPrefix}${STATUS_ORDER[stepNumber - 1]}${suffix}`);
		}
	};

	const handleSave = async () => {
		if (!productDetails?.event.length) {
			return;
		}
		setSaveStatus('in-progress');
		let offering;
		try {
			if (saveRef.current) {
				saveRef.current.abort();
			}
			if (productDetails.id) {
				// then update it
				const updateCall = await offeringAPI.update(productDetails.id, productDetails);
				saveRef.current = updateCall;

				offering = await updateCall.send();
			} else {
				// create it
				const createCall = await offeringAPI.create(productDetails);
				saveRef.current = createCall;

				offering = await createCall.send();
			}
			setProductDetails(offering);
			setSaveStatus('success');
			saveRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error.message);
				setSaveStatus('error');
			}
			saveRef.current = null;
		}
	};

	const handleSubmit = async () => {
		setSaveStatus('in-progress');
		try {
			if (submitRef.current) {
				submitRef.current.abort();
			}
			const submitCall = await offeringAPI.submit(productDetails.id);
			submitRef.current = submitCall;

			const didSubmit = await submitCall.send();
			if (didSubmit) {
				handleNext();
				setSaveStatus('success');
			} else {
				displayError('An unknown error occurred during the submit process.');
				setSaveStatus('error');
			}
			submitRef.current = null;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				displayError(error.message);
				setSaveStatus('error');
			}
			submitRef.current = null;
		}
	};

	// this useEffect puts us back in at the 'beginning' if someone clicks 'Create' in the sidebar when already deep in the flow.
	useEffect(() => {
		if (stepNumber > 0 && stepNumber < STATUS_ORDER.length - 2) {
			handleSave();
		}
		const { pathname } = location;
		// the below works regardless of trailing slash.
		if ([pathPrefix, pathPrefix.slice(0, -1)].includes(pathname)) {
			setStepNumber(0);
			return;
		}
		const currentStep = pathname.slice(pathPrefix.length);
		const newStepNumber = STATUS_ORDER.findIndex((path) => currentStep.indexOf(path) === 0);
		setStepNumber(newStepNumber);

		// eslint-disable-next-line consistent-return
		return () => {
			if (saveRef.current) {
				saveRef.current.abort();
			}
		};
	}, [location]);

	const updateProduct = (value) => setProductDetails((currentStateValue) => ({
		...currentStateValue,
		...value,
	}));

	const standardProps = { productDetails, updateProduct, updateCheckForErrorsRef };

	return (
		<div className="py-4">
			<Card
				className="w-full"
				footer={
					stepNumber === STATUS_ORDER.length - 1
						? null
						: (
							<BuilderFooter
								handleNext={handleNext}
								handleBack={handleBack}
								hideProgress={stepNumber === 0}
								progress={(stepNumber / (STATUS_ORDER.length - 1)) * 100}
								hideNext={stepNumber === STATUS_ORDER.indexOf(STATUS.SUBMIT.path)}
								backButtonVariant={stepNumber === 0 ? 'base' : 'outline-brand'}
								backButtonLabel={stepNumber === 0 ? 'Cancel' : 'Back'}
								nextButtonLabel={stepNumber === 0 ? 'Get Started' : 'Next'}
							/>
						)
				}
			>
				<div className="flex items-center justify-between">
					<h3 className="p-5">{STATUS_TITLE[stepNumber]}</h3>
					{[0, STATUS_ORDER.length - 1].includes(stepNumber) ? null : (
						<StatusBadge status={saveStatus} />
					)}
				</div>
				<Router className="p-5 pb-12 md:w-11/12 mx-auto">
					<Home path={STATUS.HOME.path} ref={viewRef} />
					<Organizer {...standardProps} path={`${STATUS.ORGANIZER.path}/*`} />
					<Details {...standardProps} path={`${STATUS.DETAILS.path}/*`} ref={viewRef} />
					<Registration {...standardProps} path={`${STATUS.REGISTRATION.path}/*`} ref={viewRef} />
					<Age {...standardProps} path={`${STATUS.AGE.path}/*`} ref={viewRef} />
					<RefundPolicy {...standardProps} path={`${STATUS.REFUND_POLICY.path}/*`} />
					<TicketPricing {...standardProps} path={`${STATUS.TICKET_PRICING.path}/*`} ref={viewRef} />
					<Underwriting {...standardProps} path={`${STATUS.UNDERWRITING.path}/*`} />
					<Pricing {...standardProps} path={`${STATUS.PRICING.path}/*`} />
					<Summary {...standardProps} path={`${STATUS.SUMMARY.path}/*`} />
					<Submit handleSubmit={handleSubmit} path={`${STATUS.SUBMIT.path}/*`} />
					<Success {...standardProps} path={`${STATUS.SUCCESS.path}/*`} />
				</Router>
			</Card>
		</div>
	);
}

export default Builder;
