import { useState, useRef, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faCode, faSync } from '@fortawesome/free-solid-svg-icons';
import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
import { PALETTES, BASE_THEME_OPTIONS, createEmbedScript } from '@/utils';
import { useAppContext, useApiContext } from '@/providers';
import {
	Card, Select, CheckboxToggle,
	Button, Accordion, AccordionSection,
	Tab, Tabset, VisualPicker, VisualPickerOption, HelpText,
	Modal, TextArea,
} from '@/lib/ReactRainbow';
import { JsonEditor, ColorSwatch, ColorEditor } from '@/components';
import { SWITCH, IF } from '@/components/utils';
import { CopyButton } from '@/components/CopyToClipboard';

SyntaxHighlighter.registerLanguage('xml', xml);

const VIEWS = {
	GENERAL: 'general',
	STYLE: 'style',
};

const embedUrl = process.env.REACT_APP_embed_url || 'http://localhost:3000';

const offerSelections = ['paginated', 'single-form', 'offer-only'];

const nullArray = [{ label: null, value: null }];

const DEFAULT_SETTINGS = {
	partnerID: '',
	theme: {
		baseTheme: 'base',
		palette: 'base',
		overrides: {
			style: {},
			colors: {},
			webFonts: [],
		},
	},
	data: {
		policy: {},
		customer: {},
	},
	viewType: 'paginated',
	includeCheckout: true,
};

function OfferEditor() {
	const [currentView, setCurrentView] = useState(VIEWS.GENERAL);
	const [activeSettingsSections, setActiveSettingsSections] = useState(['general-settings']);
	const [activeStyleSections, setActiveStyleSections] = useState([]);
	const [settings, setSettings] = useState(DEFAULT_SETTINGS);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [partners, setPartners] = useState([]);
	const [ions, setIons] = useState([]);

	const getPartnersRef = useRef();
	const getIonsRef = useRef();
	const iframeRef = useRef();

	const { partnerAPI, ionAPI } = useApiContext();
	const { displayError } = useAppContext();

	const {
		ion,
		theme,
		partnerID,
		viewType,
		includeCheckout,
		webFonts,
	} = settings;

	const {
		primary,
		secondary,
		tertiary,
		negative,
		positive,
		textPrimary,
		textSecondary,
		backgroundPrimary,
		backgroundSecondary,
	} = theme?.overrides?.colors || {};

	const updateSettings = (updates) => {
		const currentSettings = cloneDeep(settings);
		const updatedSettings = Object.assign(currentSettings, updates);
		setSettings(updatedSettings);
	};

	const getPartners = async () => {
		let list;
		try {
			if (getPartnersRef.current) {
				getPartnersRef.current.abort();
			}
			const getList = await partnerAPI.list();
			getPartnersRef.current = getList;

			const partnersFromServer = await getList.send();
			list = partnersFromServer.map((el) => (
				{
					label: el.name,
					value: el.id,
					name: el.slug,
				}));
		} catch (error) {
			getPartnersRef.current = null;
			if (error?.name !== 'AbortError') {
				return Promise.reject(error);
			}
		}
		getPartnersRef.current = null;
		return list;
	};

	const getIons = async () => {
		let list = [];
		if (getIonsRef.current) {
			getIonsRef.current.abort();
		}
		try {
			const call = await ionAPI.list();
			getIonsRef.current = call;
			list = await call.send();
		} catch (error) {
			getIonsRef.current = null;
			if (error?.name !== 'AbortError') {
				return Promise.reject(error);
			}
		}
		return list;
	};

	const getData = async () => {
		try {
			const promises = [getPartners(), getIons()];
			const res = await Promise.all(promises);
			const errors = res.filter((response) => response?.message);
			if (errors.length) {
				displayError(errors.map((err) => err?.message || err));
				return;
			}
			const [partnersList, ionsList] = res;
			setIons(ionsList);
			setPartners(partnersList);
		} catch (error) {
			displayError(error);
		}
	};

	const postToIFrame = (payload) => {
		const contentWindow = iframeRef?.current?.contentWindow || null;
		if (!contentWindow) {
			return;
		}
		const message = {
			action: 'SETUP',
			payload,
			timestamp: Date.now(),
		};
		contentWindow.postMessage(message, '*');
	};

	const reloadIframe = () => {
		// this is dumb, but accessing contentWindow.location.reload throws a security error.
		// this doesn't ¯\_(ツ)_/¯
		iframeRef.current.src = embedUrl;
	};

	useEffect(() => {
		postToIFrame(settings);
	}, [settings]);

	useEffect(() => {
		getData();
		return () => {
			getPartnersRef.current?.abort();
		};
	}, []);

	// this is the generic change function that takes a synthetic event
	const handleChange = ({ target: { name: keyName, value } }) => {
		if (keyName === 'webFonts') {
			updateSettings({ theme: { ...theme, overrides: { ...theme.overrides, webFonts: value.split(', ' || ',') } } });
			return;
		}
		updateSettings({ [keyName]: value });
	};

	const handleBaseChangeTheme = (value) => {
		updateSettings({ theme: { ...theme, baseTheme: value }, colors: PALETTES[value] });
	};

	const handleColorPalette = (value) => {
		updateSettings({ theme: { ...theme, palette: value }, colors: PALETTES[value] });
	};

	// function to be able to individual change colors based on key
	const handleChangeColor = (key, value) => {
		const prevColors = theme?.overrides?.colors || {};
		const updatedColors = ({ ...prevColors, [key]: value });
		const addToColor = ((theme || {}).overrides || {}).colors;
		const updatedTheme = {
			...theme,
			overrides: { ...theme.overrides, colors: { ...addToColor, [key]: updatedColors[key].hex } },
		};
		updateSettings(
			{
				theme: updatedTheme,
				colors: updatedColors,
			},
		);
	};

	const handleSelectSection = (event, activeNames) => setActiveSettingsSections(activeNames);

	const handleSelectStyleSection = (event, activeNames) => setActiveStyleSections(activeNames);

	const handleCss = (value) => updateSettings({
		theme: {
			...theme,
			overrides:
			{ ...theme.overrides, styles: (JSON.parse(value)) },
		},
	});

	const ionOptions = ions.map((el) => ({ value: el?.id, label: el?.id }));

	return (
		<>
			<Card
				title="Offer Editor"
				icon={<FontAwesomeIcon icon={faSlidersH} className="color-primary" />}
				className="py-8 px-3"
			>
				<div className="flex mt-4">
					<div className="w-full px-3">
						<Tabset
							activeTabName={currentView}
							onSelect={(event, value) => setCurrentView(value)}
						>
							<Tab label="General" name={VIEWS.GENERAL} />
							<Tab label="Style" name={VIEWS.STYLE} />
						</Tabset>
						<SWITCH match={currentView}>
							<Accordion
								className="mt-2"
								case={VIEWS.STYLE}
								activeSectionNames={activeStyleSections}
								onToggleSection={handleSelectStyleSection}
								multiple
							>
								<AccordionSection label="Choose a Base Theme">
									<VisualPicker onChange={handleBaseChangeTheme} value={theme.baseTheme} size="small" className="flex justify-center">
										{BASE_THEME_OPTIONS.map(({ label, value }) => (
											<VisualPickerOption key={value} name={value} value={theme.baseTheme}>
												<h4>{label}</h4>
											</VisualPickerOption>
										))}
									</VisualPicker>
								</AccordionSection>
								<AccordionSection label="Customize Colors" className="mt-4">
									<label className="block rainbow-label mt-4">
											Select a pre-set color palette
									</label>

									<VisualPicker onChange={handleColorPalette} value={theme.palette} size="small" className="flex justify-center mt-4">
										{BASE_THEME_OPTIONS.map(({ label, value }) => (
											<VisualPickerOption key={value} name={value} value={theme.palette}>
												<h4 style={{ color: PALETTES[value]?.primary || '#0d0e0f' }}>{label}</h4>
												<ColorSwatch theme={PALETTES[value]} />
											</VisualPickerOption>
										))}
									</VisualPicker>
									<div className="w-full ">
										<label className="block rainbow-label mt-4">
											Customize colors
										</label>
										<ColorEditor
											label="Primary"
											type="text"
											palette={theme?.palette}
											value={primary}
											setColor={handleChangeColor}
											name="primary"
											helperText="Primary brand color (eg. h1's, primary buttons, CTAs)."
											className="mt-4"
										/>
										<ColorEditor
											label="Secondary"
											type="text"
											value={secondary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="secondary"
											helperText="Secondary brand color (eg. links, highlights, accents)."
											className="mt-4"
										/>
										<ColorEditor
											label="Tertiary"
											type="text"
											value={tertiary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="tertiary"
											helperText="Tertiary brand color (eg. inactive accents)."
											className="mt-4"
										/>
										<ColorEditor
											label="Negative"
											type="text"
											value={negative}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="negative"
											helperText="Negative color (eg. error messages)."
											className="mt-4"
										/>
										<ColorEditor
											label="Positive"
											type="text"
											value={positive}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="positive"
											helperText="Positive color (eg. success messages)."
											className="mt-4"
										/>
										<ColorEditor
											label="Primary Text"
											type="text"
											value={textPrimary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="textPrimary"
											helperText="Primary Text color."
											className="mt-4"
										/>
										<ColorEditor
											label="Secondary Text"
											type="text"
											value={textSecondary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="textSecondary"
											helperText="Secondary Text color and accents."
											className="mt-4"
										/>
										<ColorEditor
											label="Primary Background"
											type="text"
											value={backgroundPrimary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="backgroundPrimary"
											helperText="Main background color."
											className="mt-4"
										/>
										<ColorEditor
											label="Secondary Background"
											type="text"
											value={backgroundSecondary}
											palette={theme?.palette}
											setColor={handleChangeColor}
											name="backgroundSecondary"
											helperText="Secondary background color (eg. big select button backgrounds)."
											className="mt-4"
										/>
									</div>
								</AccordionSection>
								<AccordionSection
									label="Customize Styling"
									className="mt-4"
								>
									<JsonEditor
										onSubmit={handleCss}
									/>
								</AccordionSection>
								<AccordionSection
									label="Customize Web Fonts"
									className="mt-4"
								>
									<HelpText
										title="Web Font URL"
										text={(
											<p>
											Copy and paste URL below.
											In the case of multiple web font URLs, use a comma between each. (eg: https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap)
											</p>
										)}
									/>
									<TextArea
										id="webFont"
										className="mt-4"
										value={webFonts}
										name="webFonts"
										onChange={handleChange}
										rows={6}
									/>
								</AccordionSection>
							</Accordion>
							<Accordion
								className="mt-2"
								case={VIEWS.GENERAL}
								activeSectionNames={activeSettingsSections}
								onToggleSection={handleSelectSection}
								multiple
							>
								<AccordionSection label="General Settings" name="general-settings">
									<Select
										label="Partner"
										labelAlignment="left"
										className="w-full md:w-1/2"
										options={nullArray.concat(partners)}
										value={partnerID}
										name="partnerID"
										onChange={handleChange}
										required
									/>
									<Select
										label="ION"
										labelAlignment="left"
										className="w-full md:w-1/2"
										options={nullArray.concat(ionOptions)}
										value={ion}
										name="ion"
										onChange={handleChange}
										required
									/>
								</AccordionSection>
								<AccordionSection label="Customize Views" className="mt-4">
									<label className="block rainbow-label mt-4">
											Include Checkout?
									</label>
									<CheckboxToggle
										value={includeCheckout}
										onChange={() => updateSettings({ includeCheckout: !includeCheckout })}
										disabled={viewType === 'offer-only'}
									/>
									<label className="block rainbow-label mt-4">
										Select View Type
									</label>
									<Select
										className="w-full md:w-1/2"
										options={offerSelections.map((el) => ({ label: el, value: el }))}
										value={viewType}
										name="viewType"
										onChange={handleChange}
									/>
								</AccordionSection>
								<AccordionSection label="Advanced" name="customer-info" className="mt-4">
									<p>
										<HelpText
											variant="warning"
											title="Warning"
											className="mx-4 align-bottom"
											text={(
												<p>
												Overriding policy and customer information is discouraged.
												</p>
											)}
										/>
										Pass in data overrides here (as JSON) according to the ION.
									</p>
									<JsonEditor
										defaultValue={JSON.stringify(DEFAULT_SETTINGS.data, null, 2)}
										onSubmit={(value) => updateSettings({ data: JSON.parse(value) })}
									/>
								</AccordionSection>
							</Accordion>
						</SWITCH>
					</div>
					<div className="w-full border border-solid border-gray-400 rounded p-3 bg-gray-50">
						<div className="flex justify-between items-center my-3 ">
							<h4 className="mb-4">Preview</h4>
							<div>
								<Button onClick={reloadIframe}>
									<FontAwesomeIcon icon={faSync} className="mr-2" />
                  Refresh
								</Button>
								<Button onClick={() => setIsModalOpen(true)} className="ml-4">
									<FontAwesomeIcon icon={faCode} className="mr-2" />
                  Get Code
								</Button>
							</div>
						</div>
						<IF condition={ion && partnerID}>
							<iframe
								ref={iframeRef}
								title="iframe"
								style={{ border: 'none', width: '100%', height: 800 }}
								src={embedUrl}
								width="100%"
								onLoad={() => postToIFrame(settings)}
							/>
						</IF>
						<IF condition={!ion || !partnerID}>
							<div className="text-center">
								Please select an ION and a partner.
							</div>
						</IF>
					</div>
				</div>
			</Card>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				size="large"
				footer={<div className="flex justify-end"><CopyButton whatToCopy={createEmbedScript(settings)} /></div>}
			>
				<div className="p-4">
					<SyntaxHighlighter
						style={vs2015}
						className="text-xs mt-4"
					>
						{createEmbedScript(settings)}
					</SyntaxHighlighter>
				</div>
			</Modal>
		</>
	);
}

export default OfferEditor;
