import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import moment from 'moment-timezone';
import {
	Numbers, formatToStandardDate,
} from '@buddy-technology/buddy_helpers';
import { calls, helpers } from '@/providers/ApiContext';
import { Data } from '@/utils/dataHelpers';

const getTime = (dateObj) => {
	const hours = `${(dateObj.getHours() < 10) ? `0${dateObj.getHours()}` : dateObj.getHours() - 12}`;
	const minutes = `${(dateObj.getMinutes() < 10) ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()}`;
	const period = dateObj.getHours() > 12 ? 'PM' : 'AM';
	return `${hours}:${minutes} ${period}`;
};

const getSalesSummary = (runningValue, currentElement) => (
	runningValue + (currentElement?.orderAmount || currentElement?.writtenPremium || 0)
);

const getTimeIncrement = (daysBetween) => {
	const increment = {
		unit: 'hours',
		interval: 24,
	};
	if (daysBetween <= 0.5) {
		increment.unit = 'minutes';
		increment.interval = 5;
	} else if (daysBetween <= 1) {
		increment.unit = 'hours';
		increment.interval = 1;
	} else if (daysBetween <= 2) {
		increment.unit = 'hours';
		increment.interval = 2;
	} else if (daysBetween <= 5) {
		increment.unit = 'days';
		increment.interval = 1;
	} else if (daysBetween <= 30) {
		increment.unit = 'days';
		increment.interval = 1;
	} else if (daysBetween >= 31) {
		increment.unit = 'weeks';
		increment.interval = 2;
	}
	return increment;
};

const REPORTS = {
	ORDERS_PLACED: 'ordersPlaced',
	SALES_DOLLARS: 'salesDollars',
	MARKETING_FEE_EARNED: 'marketingFeeEarned',
	QUOTE_TO_ORDER_RATIO: 'quoteToOrderRatio',
	LEAD_VENUE_BY_SALES: 'leadVenueBySales',
	LEAD_PARTNER_BY_SALES: 'leadPartnerBySales',
	AVERAGE_TIME_OF_SERVER_RESPONSE: 'averageTimeOfServerResponse',
	ERROR_RATIO: 'errorRatio',
};

const getOrdersPlaced = (data) => {
	const rawData = calls.ofEndpoint(data, ['order', 'order/batch']).map((order) => {
		const dateObj = new Date(order.created_millisecond);
		const premium = helpers.bestGuessAtPremiumOfOrder(order);
		const obj = {
			id: order.id,
			orderDate: formatToStandardDate(dateObj),
			orderTime: getTime(dateObj),
			writtenPremium: Numbers.toUSD(premium),
		};
		if (order.venue) {
			obj.venue = order?.venueName || order.venue;
		}
		return obj;
	});

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => ({ orderDate: order.created_millisecond }));

	// how much time is between the first and last date?
	const daysBetween = moment(chartData[chartData.length - 1].orderDate).diff(
		moment(chartData[0].orderDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(chartData, increment);

	const labels = Object.keys(grouped);

	const values = labels.map((label) => grouped[label].length);

	const charts = [
		{
			id: 'ordersPlacedChart',
			chartType: 'line',
			tabTitle: 'CHART',
			labels,
			datasets: {
				orders: values,
			},
		},
	];

	return { rawData, charts, increment };
};

const getSalesDollars = (data) => {
	const rawData = calls.ofEndpoint(data, ['order', 'order/batch']).map((order) => {
		const dateObj = new Date(order.created_millisecond);
		const premium = helpers.bestGuessAtPremiumOfOrder(order);
		const obj = {
			id: order.id,
			orderDate: formatToStandardDate(dateObj),
			orderTime: getTime(dateObj),
			writtenPremium: Numbers.toUSD(premium),
		};
		if (order.venue) {
			obj.venue = order?.venueName || order.venue;
		}
		return obj;
	});

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => ({ orderDate: order.created_millisecond }));

	// how much time is between the first and last date?
	const daysBetween = moment(chartData[chartData.length - 1].orderDate).diff(
		moment(chartData[0].orderDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(chartData, increment);

	const labels = Object.keys(grouped);

	const values = labels.map((label) => grouped[label].reduce(getSalesSummary, 0));

	const charts = [
		{
			id: 'salesDollarChart',
			chartType: 'line',
			tabTitle: 'CHART',
			labels,
			datasets: {
				writtenPremium: values,
			},
		},
	];
	return { rawData, charts, increment };
};

const getMarketingFeeEarned = (data) => {
	const rawData = calls.ofEndpoint(data, ['order', 'order/batch']).map((order) => {
		const dateObj = new Date(order.created_millisecond);
		const premium = helpers.bestGuessAtPremiumOfOrder(order);
		const obj = {
			id: order.id,
			orderDate: formatToStandardDate(dateObj),
			orderTime: getTime(dateObj),
			writtenPremium: Numbers.toUSD(premium),
		};
		// for marketing fee
		obj.marketingFee = Numbers.centsToDollars(
			Numbers.dollarsToCents(obj.writtenPremium) * 0.125,
		);
		if (order.venue) {
			obj.venue = order?.venueName || order.venue;
		}
		return obj;
	});

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => ({ orderDate: order.created_millisecond }));

	const daysBetween = moment(chartData[chartData.length - 1].orderDate).diff(
		moment(chartData[0].orderDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);
	const grouped = Data.groupDataByTimeIncrement(chartData, increment);

	const labels = Object.keys(grouped);

	// todo marketing fee is different for each partner
	const values = labels.map(
		(label) => grouped[label].reduce(getSalesSummary, 0) * 0.125,
	);

	const charts = [
		{
			id: 'marketingFeeEarned',
			chartType: 'line',
			tabTitle: 'CHART',
			labels,
			datasets: {
				orderAmount: values,
			},
		},
	];
	return { rawData, charts, increment };
};

const getQuoteToOrderRatio = (data) => {
	const rawData = data.map((call) => {
		const dateObj = new Date(call.created_millisecond);
		// todo: make this work across different pricing things
		const premium = call.end_point === 'quote'
			? call?.response?.pricing || 0
			: call?.payload?.policy?.premiumTotal || 0;
		const obj = {
			id: call.id,
			type: call.end_point,
			callDate: formatToStandardDate(dateObj),
			price: Numbers.toUSD(premium),
		};
		if (call.venue) {
			obj.venue = call?.venueName || call.venue;
		}
		return obj;
	});

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => ({ orderDate: order.created_millisecond }));

	const daysBetween = moment(chartData[chartData.length - 1].callDate).diff(
		moment(chartData[0].callDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(chartData, increment, 'callDate');

	const labels = Object.keys(grouped);

	// now split em up
	const orders = [];
	const quotes = [];
	labels.forEach((label) => {
		orders.push(grouped[label].filter((el) => el.type === 'order').length);
		quotes.push(grouped[label].filter((el) => el.type === 'quote').length);
	});
	const charts = [
		{
			id: 'lineChart',
			labels,
			datasets: {
				orders,
				quotes,
			},
		},
	];
	return { rawData, charts, increment };
};

const getLeadVenueBySales = (data) => {
	const rawData = calls.ofEndpoint(data, ['order', 'order/batch']).map((order) => {
		const dateObj = new Date(order.created_millisecond);
		const premium = helpers.bestGuessAtPremiumOfOrder(order);
		const obj = {
			id: order.id,
			orderDate: formatToStandardDate(dateObj),
			orderTime: getTime(dateObj),
			writtenPremium: Numbers.toUSD(premium),
		};
		if (order.venue) {
			obj.venue = order?.venueName || order.venue;
		}
		return obj;
	});

	// used for pie chart below, but needed for labels here
	const pieCopy = cloneDeep(rawData);
	const groupedByVenue = groupBy(pieCopy, 'venue');
	const pieLabels = Object.keys(groupedByVenue);

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => {
			const obj = { orderDate: order.created_millisecond };
			if (order.venue) {
				obj.venue = order?.venueName || order.venue;
			}
			return obj;
		});

	const daysBetween = moment(chartData[chartData.length - 1].orderDate).diff(
		moment(data[0].orderDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(chartData, increment);

	const labels = Object.keys(grouped);
	// now split them up
	const datasetForLineGraph = {};
	pieLabels.forEach((pieLabel) => {
		datasetForLineGraph[pieLabel] = [];
	});
	// values = labels.map((label) => grouped[label].length);
	labels.forEach((label) => {
		pieLabels.forEach((pieLabel) => {
			datasetForLineGraph[pieLabel].push(
				grouped[label].filter((el) => el.venue === pieLabel).length,
			);
		});
	});

	const lineChart = {
		id: 'lineGraph',
		chartType: 'line',
		tabTitle: 'Over Time',
		labels,
		datasets: datasetForLineGraph,
	};

	// let's break it down
	const pieValues = pieLabels.map((label) => groupedByVenue[label].length);

	const pieChart = {
		id: 'pieGraph',
		chartType: 'pie',
		tabTitle: 'Breakdown',
		labels: pieLabels,
		datasets: {
			breakdown: pieValues,
		},
	};

	const barChart = {
		id: 'barGraph',
		chartType: 'bar',
		tabTitle: 'Volume',
		labels: pieLabels,
		datasets: { orders: [...pieValues, 0] },
	};

	const charts = [lineChart, pieChart, barChart];

	// this creates the dataset used in the rank table
	const rankedData = [];
	const groupedVenue = groupBy(rawData, 'venue');
	const tableData = Object.entries(groupedVenue);

	tableData.forEach((array) => {
		const newObj = {
			venue: array[0],
			orders: array[1].length,
		};
		rankedData.push(newObj);
	});

	rankedData.sort((a, b) => (b.orders - a.orders));

	return {
		rawData, charts, increment, rankedData,
	};
};

const getLeadPartnerBySales = (data) => {
	const rawData = calls.ofEndpoint(data, ['order', 'order/batch']).map((order) => {
		const dateObj = new Date(order.created_millisecond);
		const premium = helpers.bestGuessAtPremiumOfOrder(order);
		const obj = {
			id: order.id,
			orderDate: formatToStandardDate(dateObj),
			orderTime: getTime(dateObj),
			writtenPremium: Numbers.toUSD(premium),
		};
		if (order.partner_slug) {
			obj.partner_slug = order.partner_slug;
		}
		return obj;
	});

	// used for pie chart below, but needed for labels here
	const pieCopy = cloneDeep(rawData);
	const groupedByPartner = groupBy(pieCopy, 'partner_slug');
	const pieLabels = Object.keys(groupedByPartner);

	const chartData = calls.ofEndpoint(data, ['order', 'order/batch'])
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((order) => {
			const obj = { orderDate: order.created_millisecond };
			if (order.partner_slug) {
				obj.partner_slug = order.partner_slug;
			}
			return obj;
		});

	const daysBetween = moment(chartData[chartData.length - 1].orderDate).diff(
		moment(data[0].orderDate),
		'days',
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(chartData, increment);

	const labels = Object.keys(grouped);
	// now split them up
	const datasetForLineGraph = {};
	pieLabels.forEach((pieLabel) => {
		datasetForLineGraph[pieLabel] = [];
	});
	// values = labels.map((label) => grouped[label].length);
	labels.forEach((label) => {
		pieLabels.forEach((pieLabel) => {
			datasetForLineGraph[pieLabel].push(
				grouped[label].filter((el) => el.partner_slug === pieLabel).length,
			);
		});
	});

	const lineChart = {
		id: 'lineGraph',
		chartType: 'line',
		tabTitle: 'Over Time',
		labels,
		datasets: datasetForLineGraph,
	};

	// let's break it down
	const pieValues = pieLabels.map((label) => groupedByPartner[label].length);

	const pieChart = {
		id: 'pieGraph',
		chartType: 'pie',
		tabTitle: 'Breakdown',
		labels: pieLabels,
		datasets: {
			breakdown: pieValues,
		},
	};

	const barChart = {
		id: 'barGraph',
		chartType: 'bar',
		tabTitle: 'Volume',
		labels: pieLabels,
		datasets: { orders: [...pieValues, 0] },
	};
	const charts = [lineChart, pieChart, barChart];

	// this creates the dataset used in the rank table
	const rankedData = [];
	const groupedPartner = groupBy(rawData, 'partner_slug');
	const tableData = Object.entries(groupedPartner);

	tableData.forEach((array) => {
		let totalPremium = 0;
		array[1].forEach((object) => {
			const premium = Number(object.writtenPremium.replace(/[^0-9.-]+/g, ''));
			totalPremium += premium;
		});
		array.push(totalPremium);
	});

	tableData.sort((a, b) => (b[2] - a[2]));

	tableData.forEach((array) => {
		const newObj = {
			partner: array[0],
			orders: array[1].length,
			totalPremium: `$${array[2].toLocaleString('en-us', { minimumFractionDigits: 2 })}`,
		};
		rankedData.push(newObj);
	});

	return {
		rawData, charts, increment, rankedData,
	};
};

const getCallSuccess = (data) => {
	const rawData = data.map((call) => {
		const dateObj = new Date(call.created_millisecond);
		const obj = {
			id: call.id,
			callDate: formatToStandardDate(dateObj),
			callTime: getTime(dateObj),
			success: !call.error,
		};
		return obj;
	});

	// now for the chart data
	const copy = cloneDeep(data)
		.sort((a, b) => (a.created_millisecond > b.created_millisecond ? 1 : -1))
		.map((call) => ({ callDate: call.created_millisecond }));

	const daysBetween = moment(copy[copy.length - 1].callDate).diff(
		moment(data[0].callDate),
		'days',
		true,
	);

	const increment = getTimeIncrement(daysBetween);

	const grouped = Data.groupDataByTimeIncrement(copy, increment, 'callDate');

	let labels = Object.keys(grouped);

	const success = [];
	const errors = [];

	labels.forEach((label) => {
		const groupedBySuccess = groupBy(grouped[label], 'success');
		success.push(groupedBySuccess?.true?.length || 0);
		errors.push(groupedBySuccess?.false?.length || 0);
	});

	if (increment.unit === 'minutes') {
		labels = labels.map((label) => moment(label).format('hh:mma'));
	}

	const charts = [
		{
			id: 'callSuccessChart',
			chartType: 'line',
			tabTitle: 'CHART',
			labels,
			datasets: {
				success,
				errors,
			},
		},
	];

	return { rawData, charts, increment };
};

const getReportData = (report, data) => {
	switch (report) {
	case REPORTS.ORDERS_PLACED:
		return getOrdersPlaced(data);
	case REPORTS.SALES_DOLLARS:
		return getSalesDollars(data);
	case REPORTS.MARKETING_FEE_EARNED:
		return getMarketingFeeEarned(data);
	case REPORTS.QUOTE_TO_ORDER_RATIO:
		return getQuoteToOrderRatio(data);
	case REPORTS.LEAD_VENUE_BY_SALES:
		return getLeadVenueBySales(data);
	case REPORTS.LEAD_PARTNER_BY_SALES:
		return getLeadPartnerBySales(data);
	case REPORTS.ERROR_RATIO:
		return getCallSuccess(data);
	default:
		return null;
	}
};

export {
	getOrdersPlaced,
	getSalesDollars,
	getMarketingFeeEarned,
	getQuoteToOrderRatio,
	getLeadVenueBySales,
	getLeadPartnerBySales,
	getReportData,
	getCallSuccess,
	getTimeIncrement,
	REPORTS,
};
