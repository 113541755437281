/* eslint-disable react/jsx-indent */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	// faEye,
	faPen,
	faClone,
	faPaperPlane,
	faAward,
	faReply,
	faFilePdf,
	faFileCode,
	// faMinusCircle,
	faCheckSquare,
	// faEllipsisV,
	// faDownload,
	// faUserCheck,
	faCode,
	faEye,
} from '@fortawesome/free-solid-svg-icons';

import { MenuItem } from '@/lib/ReactRainbow';
import {
	OFFERING_STATUS,
	PRODUCT_ACTIONS,
} from '@/utils';

function ProductActions({ status, onActionSelected, id }) {
	switch (status) {
	case 'DOWNLOAD_ONLY':
		return (
			<>
				<MenuItem icon={<FontAwesomeIcon icon={faFilePdf} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.DOWNLOAD_SAMPLE)} label="Sample File" />
				<MenuItem icon={<FontAwesomeIcon icon={faFilePdf} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_PDF)} label="Summary PDF" />
				<MenuItem icon={<FontAwesomeIcon icon={faFileCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.DOWNLOAD_SUMMARY_MD)} label="Summary Markdown" />
			</>
		);
	case OFFERING_STATUS.CREATED:
		return (
			<>
				<MenuItem icon={<FontAwesomeIcon icon={faPen} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.EDIT)} label="Edit" />
				<MenuItem icon={<FontAwesomeIcon icon={faPaperPlane} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.SUBMIT)} label="Submit" />
				<MenuItem icon={<FontAwesomeIcon icon={faClone} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.CLONE)} label="Clone" />
				<MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_SUMMARY)} label="View Summary" />
				<MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_RAW)} label="View Raw" />
			</>
		);
	case OFFERING_STATUS.DEACTIVATED:
		return (
			<>
				<MenuItem icon={<FontAwesomeIcon icon={faCheckSquare} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.ACTIVATE)} label="Activate" />
				<MenuItem icon={<FontAwesomeIcon icon={faClone} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.CLONE)} label="Clone" />
				<MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_SUMMARY)} label="View Summary" />
				<MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_RAW)} label="View Raw" />
			</>
		);
	case OFFERING_STATUS.SUBMITTED:
		return (
			<>
				<MenuItem icon={<FontAwesomeIcon icon={faReply} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.UNSUBMIT)} label="Unsubmit" />
				<MenuItem icon={<FontAwesomeIcon icon={faAward} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.PUBLISH)} label="Publish" />
				<MenuItem icon={<FontAwesomeIcon icon={faClone} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.CLONE)} label="Clone" />
				<MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_SUMMARY)} label="View Summary" />
				<MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_RAW)} label="View Raw" />
			</>
		);
	case OFFERING_STATUS.PUBLISHED:
		return (
			<>
				<MenuItem onClick={() => onActionSelected(id, 'activate')} label="Re-activate" />
				<MenuItem icon={<FontAwesomeIcon icon={faClone} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.CLONE)} label="Clone" />
				<MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_SUMMARY)} label="View Summary" />
				<MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_RAW)} label="View Raw" />
			</>
		);
	default:
		return (
			<>
				<MenuItem icon={<FontAwesomeIcon icon={faClone} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.CLONE)} label="Clone" />
				<MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_SUMMARY)} label="View Summary" />
				<MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onActionSelected(id, PRODUCT_ACTIONS.VIEW_RAW)} label="View Raw" />
			</>
		);
	}
}

export default ProductActions;
