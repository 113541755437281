import { useState, useRef, useEffect } from 'react';
import { useApiContext, usePartnerData } from '@/providers';
import {
	Input,
	CheckboxToggle,
	MultiSelect,
	Option,
} from '@/lib/ReactRainbow';
import { SubmitRequestModal, Label } from '@/components/UI';
import { IF } from '@/components/utils';

const NewUserModal = ({ handleCloseRequest, isOpen }) => {
	const initialInfo = {
		name: '',
		email: '',
		phone: '',
		role: '',
	};
	const [info, setInfo] = useState(initialInfo);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [errorMsg, setErrorMsg] = useState(null);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const createPartnerRef = useRef();
	const createUserRef = useRef();
	const getPoolGroupsRef = useRef();
	const addToGroupRef = useRef();
	const { authAPI, partnerAPI } = useApiContext();
	const [shouldCreatePortalAccount, setShouldCreatePortalAccount] = useState(true);
	const { partnerData, cognitoGroups: groups } = usePartnerData();

	const handleChange = ({ target: { name, value } }) => {
		setInfo((oldInfo) => ({ ...oldInfo, [name]: value }));
	};

	const {
		name = '',
		email = '',
		phone = '',
		role = '',
	} = info;

	const addUserToGroup = async () => {
		if (addToGroupRef.current) {
			addToGroupRef.current.abort();
		}
		try {
			const addGroupFetch = await authAPI.addToGroup({ id: email, type: 'partner', group: selectedGroups });
			addToGroupRef.current = addGroupFetch;

			const res = await addGroupFetch.send();
			addToGroupRef.current = null;
			return res;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				addToGroupRef.current = null;
			}
			return Promise.reject(error);
		}
	};

	const createUser = async (options) => {
		if (createUserRef.current) {
			createUserRef.current.abort();
		}
		try {
			const createUserFetch = await authAPI.create({ type: 'partner', ...options });
			createUserRef.current = createUserFetch;

			const res = await createUserFetch.send();
			createUserRef.current = null;
			await addUserToGroup();
			return res;
		} catch (error) {
			if (error?.name !== 'AbortError') {
				createUserRef.current = null;
			}
			return Promise.reject(error);
		}
	};

	const checkForErrors = () => {
		const requiredProps = [
			{
				id: 'name',
				label: 'Name',
			},
			{
				id: 'email',
				label: 'Email',
			}];
		const emptyFields = requiredProps.filter((prop) => !info[prop.id]?.length);
		if (emptyFields.length) {
			const errorMessage = `The following fields are required: [${emptyFields.map((el) => el.label).join(', ')}].`;
			return errorMessage;
		}
		return null;
	};

	const reCheckErrors = () => {
		if (hasSubmitted) {
			setErrorMsg(checkForErrors());
		}
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		setErrorMsg(null);
		setHasSubmitted(true);
		if (createPartnerRef.current) {
			createPartnerRef.current.abort();
		}

		// do error check
		const errorFound = checkForErrors();
		if (errorFound) {
			setErrorMsg(errorFound);
			setIsLoading(false);
			return;
		}

		const newContact = {
			name,
			email,
			phone,
			role,
			hasPortalAccount: shouldCreatePortalAccount,
		};
		const { users, id: partnerID } = partnerData;
		users.push(newContact);

		try {
			const createPartnerFetch = await partnerAPI.update(partnerID, { users });
			createPartnerRef.current = createPartnerFetch;

			await createPartnerFetch.send();
			createPartnerRef.current = null;
			if (shouldCreatePortalAccount) {
				const body = {
					email,
					partnerID,
					name,
					phone,
				};
				await createUser(body);
			}
			setIsLoading(false);
			handleCloseRequest();
		} catch (error) {
			if (error?.name !== 'AbortError') {
				createPartnerRef.current = null;
				setIsLoading(false);
				setErrorMsg(error?.message || error);
			}
		}
	};

	const closeModal = () => {
		// prevents accidental closing while waiting for submit
		if (isLoading) {
			return;
		}
		handleCloseRequest();
	};

	const abortNetworkCalls = () => {
		if (createPartnerRef.current) {
			createPartnerRef.current.abort();
		}
		if (createUserRef.current) {
			createUserRef.current.abort();
		}
		if (getPoolGroupsRef.current) {
			getPoolGroupsRef.current.abort();
		}
		if (addToGroupRef.current) {
			addToGroupRef.current.abort();
		}
	};

	// if a user clicks cancel, cancel for real.
	const handleCancel = () => {
		abortNetworkCalls();
		handleCloseRequest();
	};

	// Clear state on close and abandon any network calls if unmounted.
	useEffect(() => {
		if (!isOpen) {
			setInfo(initialInfo);
			setHasSubmitted(false);
			setErrorMsg(null);
		}
		return abortNetworkCalls;
	}, [isOpen]);

	return (
		<SubmitRequestModal
			isOpen={!!isOpen}
			size="large"
			title="Creating a New User"
			onRequestClose={closeModal}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
			isLoading={isLoading}
			errorMsg={errorMsg}
		>
			<div className="flex flex-col w-full mb-4 lg:px-5">
				<div className="flex flex-column sm:flex-row w-full justify-between flex-wrap">
					<Input
						label={<Label label="Name" className="m-0" isRequired />}
						labelAlignment="left"
						className="w-full sm:w-5/12"
						placeholder="Bruce Wayne"
						type="text"
						name="name"
						value={name}
						onChange={handleChange}
						error={hasSubmitted && !name.length ? 'This field is required.' : null}
						onBlur={reCheckErrors}
					/>
					<Input
						label="Title/Role"
						labelAlignment="left"
						className="w-full sm:w-5/12 mt-4 sm:mt-0"
						placeholder="CEO"
						type="text"
						name="role"
						value={role}
						onChange={handleChange}
					/>
				</div>
				<div className="flex flex-column sm:flex-row w-full justify-between flex-wrap mt-4">
					<Input
						label={<Label label="Email" className="m-0" isRequired />}
						labelAlignment="left"
						className="w-full sm:w-5/12"
						placeholder="bruce@wayneenterprises.com"
						type="text"
						name="email"
						value={email}
						onChange={handleChange}
						error={hasSubmitted && !email.length ? 'This field is required.' : null}
						onBlur={reCheckErrors}
					/>
					<div className="flex flex-column w-full sm:w-5/12">
						<Input
							label="Phone"
							labelAlignment="left"
							className="w-full sm:w-8/12 mt-4 sm:mt-0"
							placeholder="804-867-5309"
							type="text"
							name="phone"
							value={phone}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="mt-8">
					<CheckboxToggle
						label="Create a Portal Account?"
						value={shouldCreatePortalAccount}
						onChange={() => setShouldCreatePortalAccount((bool) => !bool)}
					/>
				</div>
				<IF condition={shouldCreatePortalAccount && groups.length}>
					<MultiSelect
						label="Select at least one group"
						labelAlignment="left"
						className="mt-5"
						value={selectedGroups.map((el) => ({ label: el, value: el, name: el }))}
						onChange={(values) => setSelectedGroups(values.map((el) => el.value))}
						variant="chip"
						showCheckbox
					>
						{groups.map((el) => <Option name={el} label={el} value={el} key={el} />)}
					</MultiSelect>
				</IF>
			</div>
		</SubmitRequestModal>
	);
};

export default NewUserModal;
