import {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Numbers } from '@buddy-technology/buddy_helpers';
import {
	CheckboxToggle,
} from '@/lib/ReactRainbow';

import {
	ProgressIndicator,
	RangeSlider,
} from '@/components/UI';

import DraggableNumberInput, { getPercentageMask } from '@/components/DraggableNumberInput';
import { IF } from '@/components/utils';

const defaultPricing = [{ priceRange: [1000, 1500], percentage: 100 }];

const PriceSlider = ({
	range = [1000, 1500],
	percentage = 0,
	maxPercentage = 100,
	onRangeChange,
	onInputChange,
	removeRange,
	disablePercentage = false,
	disableDelete = false,
	step = 100,
}) => {
	const [isRange, setIsRange] = useState(true);
	const handleToggleRange = ({ target: { checked } }) => {
		if (checked && range?.length === 1) {
			onRangeChange([...range, range[0] + step]);
		}
		setIsRange(checked);
	};

	return (
		<div className="mb-5">
			<CheckboxToggle
				label={isRange ? 'Price Range' : 'Price'}
				labelAlignment="right"
				onChange={handleToggleRange}
				value={isRange}
			/>
			<IF condition={isRange}>
				<RangeSlider
					max={10000}
					min={100}
					values={range}
					onChange={onRangeChange}
					step={step}
					valueRender={(val) => Numbers.toUSD(val, 0)}
					isCurrency
				/>
			</IF>
			<IF condition={!isRange}>
				<RangeSlider
					max={10000}
					min={200}
					values={[range[0]]}
					onChange={(value) => onRangeChange([value[0], range[1]])}
					step={step}
					valueRender={(val) => Numbers.toUSD(val, 0)}
					isCurrency
				/>
			</IF>
			<div className="flex flex-row justify-between mb-1">
				<div className="flex flex-col">
					<label className="rainbow-label mt-5 block">% of sales</label>
					<DraggableNumberInput
						className="outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-2xl px-2 w-1/3 md:w-1/6 lg:w-1/12"
						mask={getPercentageMask}
						value={percentage}
						max={maxPercentage}
						onChange={onInputChange}
						disabled={disablePercentage}
					/>
				</div>
				<button
					className={`mt-auto ${(disableDelete && ('text-gray-400 cursor-not-allowed')) || 'text-buddy-red'}`}
					type="button"
					onClick={removeRange}
					disabled={disableDelete}
				>
					<FontAwesomeIcon icon={faTrash} className="block ml-auto" />
				</button>
			</div>
			<hr className="border-t border-solid border-gray-300 mb-5" />
		</div>
	);
};

const TicketPricing = forwardRef((props, ref) => {
	const { productDetails, updateProduct } = props;
	const { ticketPricing } = productDetails;
	const [priceBands, setPriceBands] = useState(
		ticketPricing?.length ? ticketPricing : defaultPricing,
	);

	// exposes the checkForErrors function
	useImperativeHandle(ref, () => ({
		checkForErrors: () => {
			const percentageCoverage = priceBands.reduce(
				(value, currentBand) => value + Number(currentBand.percentage),
				0,
			);
			if (percentageCoverage === 100) {
				return { hasError: false };
			}
			return { hasError: true, error: 'Pricing bands coverage must add up to 100%.' };
		},
	}));

	const handleInputChange = (value, i) => {
		const newBands = [...priceBands];
		newBands[i].percentage = value;
		setPriceBands(newBands);
	};

	const handleRangeChange = (values, i) => {
		const newPriceBands = [...priceBands];
		newPriceBands[i].priceRange = values;
		const isLast = i === priceBands.length - 1;
		if (priceBands[i].priceRange.length === 2) {
			const maxOverlapsNextRange =
				!isLast && priceBands[i].priceRange[1] >= priceBands[i + 1].priceRange[0];
			const isFirst = i === 0;
			const minOverlapsPrevRange =
				!isFirst &&
				priceBands[i].priceRange[0] <= priceBands[i - 1].priceRange[1];
			if (!isLast && maxOverlapsNextRange) {
				newPriceBands[i + 1].priceRange[0] = values[1] + 1;
			}
			if (!isFirst && minOverlapsPrevRange) {
				newPriceBands[i - 1].priceRange[1] = values[0] - 1;
			}
		}
		// fix any issues where min is greater than max.
		const cleanedRanges = newPriceBands.map((el) => {
			const [min, max] = el.priceRange;
			// we won't always have a second value "max"
			if (max && min >= max) {
				const newMax = min + 100;
				const newPrice = [min, newMax];
				return { ...el, priceRange: newPrice };
			}
			return el;
		});
		setPriceBands(cleanedRanges);
	};

	const addRange = () => {
		// we're assuming there's room at the end of th ranges here.
		const latest = priceBands[priceBands.length - 1].priceRange[1];
		const newRange =
			latest >= 98000 ? [98000, 10000] : [latest + 1, latest + 200];
		const newBreakdown = { priceRange: newRange, percentage: 0 };
		setPriceBands((curr) => [...curr, newBreakdown]);
	};

	const removeRange = (i) => {
		if (priceBands.length <= 1) {
			return;
		}
		const newRanges = [...priceBands];
		newRanges.splice(i, 1);

		// if there is just 1 band left, set the percentage to 100%
		if (newRanges.length === 1) {
			newRanges[0].percentage = 100;
		}
		setPriceBands(newRanges);
	};

	useEffect(() => {
		updateProduct({ ticketPricing: priceBands });
	}, [priceBands]);

	const percentageCoverage = priceBands.reduce(
		(value, currentBand) => value + Number(currentBand.percentage),
		0,
	);

	return (
		<>
			<p className="mb-5">
      Use the sliders below to adjust the price bands and add the percentage
      of sales below each. Delete price bands by clicking the
				{' '}
				<FontAwesomeIcon icon={faTrash} className="color-primary" />
				{' '}
      icon and
      add bands by clicking 'Add a price band' below.
			</p>
			<IF condition={priceBands.length > 1}>
				<ProgressIndicator
					className="mt-8 mb-8"
					label="Total Pricing Coverage:"
					percent={percentageCoverage}
				/>
			</IF>
			{priceBands.map((el, i) => {
				// the following function looks over ALL bands EXCEPT the current one to figure out the max available percentage for each band
				const availablePercentage = 100 - priceBands.filter((_, j) => j !== i).reduce(
					(value, currentBand) => value + Number(currentBand.percentage),
					0,
				);
				return (
					<PriceSlider
					// it's ok to disable the no-index rule here, as the array won't reorder and we don't have stable IDs.
					// eslint-disable-next-line react/no-array-index-key
						key={`bdf-${i}`}
						range={el.priceRange}
						percentage={el.percentage}
						maxPercentage={availablePercentage}
						onRangeChange={(values) => handleRangeChange(values, i)}
						onInputChange={(event) => handleInputChange(event, i)}
						removeRange={() => removeRange(i)}
						disablePercentage={priceBands.length === 1}
						disableDelete={priceBands.length === 1}
					/>
				);
			})}
			<button
				onClick={addRange}
				type="button"
				className="mt-5 block ml-auto color-blue"
			>
				<FontAwesomeIcon icon={faPlusCircle} />
				{' '}
      Add a price band
			</button>
		</>
	);
});

export default TicketPricing;
