import MaskedInput from 'react-text-mask';
import { StateSelect, CountrySelect } from '@/components/UI';
import { Input } from '@/lib/ReactRainbow';

const phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

function OrganizerDetails({ productDetails, updateProduct }) {
	const {
		organizer: {
			name,
			organization,
			country,
			city,
			state,
			email,
			phone,
		},
	} = productDetails;

	// eslint-disable-next-line no-shadow
	const handleChange = ({ target: { name, value } }) => {
		updateProduct({
			organizer: {
				[name]: value,
			},
		});
	};

	return (
		<>
			<div className="flex flex-col mx-auto">
				<Input
					label="Name"
					labelAlignment="left"
					className="w-full md:w-1/2 lg:w-5/12"
					placeholder="Bruce Wayne"
					type="text"
					name="name"
					value={name}
					onChange={handleChange}
				/>
				<Input
					label="Organization"
					labelAlignment="left"
					className="w-full md:w-1/2 lg:w-5/12 mt-5"
					placeholder="Wayne Enterprises"
					type="text"
					name="organization"
					value={organization}
					onChange={handleChange}
				/>
				<Input
					label="Email"
					labelAlignment="left"
					className="w-full md:w-1/2 lg:w-5/12 mt-5"
					placeholder="bwayne@wayneenterprises.com"
					type="text"
					name="email"
					value={email}
					onChange={handleChange}
				/>
				<label className="pl-5 text-sm mt-5 mb-0.5">
					Phone Number
				</label>
				<MaskedInput
					name="phone"
					mask={phoneMask}
					className="w-full md:w-1/2 lg:w-1/4 outline-none border border-gray-400 focus:ring-2 focus:ring-buddy-red focus:border-transparent rounded-3xl px-4 py-1.5"
					placeholder="212-228-6261"
					type="text"
					value={phone}
					onChange={handleChange}
				/>
				<CountrySelect
					className="mt-5"
					name="country"
					value={country}
					onChange={handleChange}
				/>
				<div className="flex flex-col md:flex-row">
					<Input
						label="City"
						labelAlignment="left"
						className="w-full md:w-1/2 lg:w-5/12 mt-5"
						placeholder="Gotham"
						type="text"
						name="city"
						value={city}
						onChange={handleChange}
					/>
					<StateSelect
						className="mt-5 md:ml-5"
						name="state"
						value={state}
						onChange={handleChange}
					/>
				</div>
			</div>
		</>
	);
}

export default OrganizerDetails;
