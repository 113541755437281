import {
	useContext, useEffect, useState, lazy, Suspense,
} from 'react';
import { Router, Redirect } from '@reach/router';
import { AppContext, ApiProvider } from '@/providers';
import { Spinner, Card } from '@/lib/ReactRainbow';
import Application from '@/components/BuddyApplicationWrapper';
import DashboardSideNav from '@/components/DashboardSideNav';
import { ComingSoon } from '@/pages';
import ErrorBoundary from '@/components/ErrorBoundary';

const ProductLayout = lazy(() => import('@/layouts/ProductLayout'));
const CustomersLayout = lazy(() => import('@/layouts/CustomersLayout'));
const DevelopersLayout = lazy(() => import('@/layouts/DevelopersLayout'));
const ReportsLayout = lazy(() => import('@/layouts/ReportsLayout'));
const PartnersLayout = lazy(() => import('@/layouts/PartnersLayout'));
const ToolsLayout = lazy(() => import('@/layouts/ToolsLayout'));

const Loading = () => (
	<div className="container flex justify-center items-center h-screen mx-auto">
		<div className="w-full p-3 sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2">
			<Card
				icon={
					<img className="w-8" src="/buddy_lettermark_R.svg" alt="logo" />
				}
				title="Loading..."
			>
				<div
					className="w-full flex justify-center items-center"
					style={{ minHeight: 381 }}
				>
					<Spinner variant="brand" />
				</div>
			</Card>
		</div>
	</div>
);

const scrollToTop = () => window?.scrollTo({
	top: 0,
	behavior: 'smooth',
});

const MainApp = ({ location }) => {
	const [isLoading, setIsLoading] = useState(true);
	const { pathname } = location;
	const {
		appState: { authData, isRefreshingToken },
		setAppState,
	} = useContext(AppContext);

	useEffect(() => {
		if (authData) {
			setIsLoading(false);
		} else if (pathname !== '/') {
			// this is to store a user's intended location to redirect them to if they're not authenticated.
			setAppState({ intendedRoute: pathname });
		}
	}, [authData]);

	useEffect(() => {
		scrollToTop();
	}, [pathname]);

	if (!authData?.signInUserSession?.idToken && !isRefreshingToken) {
		return <Redirect to="/login" noThrow />;
	}

	if (isLoading && !isRefreshingToken) {
		return <Loading />;
	}

	return (
		<ErrorBoundary>
			<ApiProvider>
				<Application className="w-full">
					<Router>
						<DashboardSideNav path="/">
							<Suspense path="/" fallback={<Spinner variant="brand" />}>
								<ProductLayout path="products/*" />
								<CustomersLayout path="customers/*" />
								<PartnersLayout path="partners/*" />
								<ReportsLayout path="reports/*" />
								<DevelopersLayout path="developers/*" />
								<ToolsLayout path="tools/*" />
								<ComingSoon default />
							</Suspense>
						</DashboardSideNav>
					</Router>
				</Application>
			</ApiProvider>
		</ErrorBoundary>
	);
};

export default MainApp;
