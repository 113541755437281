import { Button } from '@/lib/ReactRainbow';

function Submit({ handleSubmit }) {
	return (
		<>
			<h4>Warning:</h4>
			<p>
      Once you submit this product for review, you will not be able to make
      changes. To review or make changes, click 'Back' to see the summary
      screen again. If everything is correct, click 'Submit For Review' below.
      You should hear back from us in 48 hours. A confirmation email will also
      be sent to you and the organizer.
			</p>
			<Button
				className="my-5 block mx-auto"
				label="Submit For Review"
				onClick={handleSubmit}
			/>
		</>
	);
}

export default Submit;
