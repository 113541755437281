import { createContext, useContext } from 'react';

// eslint-disable-next-line import/prefer-default-export
const PartnerContext = createContext({
	partnerData: null,
	cognitoGroups: [],
});

const usePartnerData = () => {
	const context = useContext(PartnerContext);
	if (!context) {
		throw new Error('usePartnerData must be used within a PartnerProvider');
	}
	const { partnerData, cognitoGroups } = context;
	return { partnerData, cognitoGroups };
};

PartnerContext.displayName = 'PartnerContext';

export {
	PartnerContext,
	usePartnerData,
};
