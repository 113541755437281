import { STATES_ARRAY } from '@/utils';
import { Select } from '@/lib/ReactRainbow';

function StateSelect({
	label,
	labelAlignment,
	className,
	name,
	value,
	onChange,
	readOnly = false,
	disabled = false,
}) {
	return (
		<Select
			label={label || 'State'}
			labelAlignment={labelAlignment || 'left'}
			className={className || 'w-1/3 md:w-1/6 lg:w-1/12'}
			name={name || 'state'}
			options={[{ label: null, value: null }].concat(
				STATES_ARRAY.map((el) => ({ ...el, label: el.value })),
			)}
			value={value}
			onChange={onChange}
			disabled={disabled || readOnly}
		/>
	);
}

export default StateSelect;
