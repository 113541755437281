import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '@/lib/ReactRainbow';

const HelperLabel = ({
	label,
	helperText,
	variant = 'border',
	labelClass = 'rainbow-label pl-2 flex items-start',
	buttonClass = 'ml-2',
	buttonSize = 'xx-small',
	required,
}) => (
	<label className={labelClass}>
		{required ? <span className="color-primary">*</span> : null}
		{label}
		<ButtonIcon
			icon={<FontAwesomeIcon icon={faQuestion} />}
			tooltip={<span className="text-xs">{helperText}</span>}
			size={buttonSize}
			variant={variant}
			className={buttonClass}
		/>
	</label>
);

export default HelperLabel;
