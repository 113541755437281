import { useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import AceEditor from 'react-ace';
import { Button } from '@/lib/ReactRainbow';

SyntaxHighlighter.registerLanguage('xml', xml);

const JsonEditor = ({ defaultValue = '', onSubmit }) => {
	const [value, setValue] = useState(defaultValue);
	const shouldDisable = () => {
		try {
			JSON.parse(value);
			return false;
		} catch (error) {
			return true;
		}
	};
	return (
		<>
			<AceEditor
				mode="json"
				document="json"
				theme="github"
				className="w-full"
				width="100%"
				height="300px"
				name="style"
				wrapEnabled
				editorProps={{ $blockScrolling: true }}
				setOptions={{
					enableBasicAutocompletion: true,
					enableLiveAutocompletion: true,
					enableSnippets: true,
					showGutter: true,
					highlightSelectedWord: true,
					autoScrollEditorIntoView: true,
				}}
				value={value}
				onChange={setValue}
				style={{ width: '100%' }}
			/>
			<Button
				label="Update"
				className="block ml-auto mt-4"
				onClick={() => onSubmit(value)}
				disabled={shouldDisable()}
			/>
		</>
	);
};

export default JsonEditor;
